import React from 'react';
import { CartContext } from '../contexts/CartContext';
import { ProductContext } from '../contexts/ProductContext';
import { calculateGST, formatCurrency, getBreakPrice } from '../utils';
import _ from 'lodash';
import { CartProductObject } from '../Constants';
import { ShoppingCartline } from '../RESTAPI';

export const getTotal = (products: Array<CartProductObject>, cartItems: Array<ShoppingCartline>) => {
    let total = 0;

    if(products && cartItems) {
        if(products.length > 0 && cartItems.length > 0) {
            cartItems.forEach(c => {
                const p = _.find(products, (o) => {
                    return o.product.id === c.productid
                })
    
                if(p) {
                    const currentPrice = getBreakPrice(p.priceBreakInfo.priceBreaks, c.quantity);
                    total += currentPrice * c.quantity;
                }
            });
        }
    } 
    
    return total
}

const OrderSummaryInfo: React.FC<{shippingPrice: number, adminFee: number}> = ({shippingPrice, adminFee}) => {
    const { cartState } = React.useContext(CartContext);
    const {productState} = React.useContext(ProductContext);

    const total = getTotal(productState.products, cartState.cartItems);

    return (
        <>
            <div className='flexSpaceBetween cartDetail'>
                <strong>Items</strong>
                <p>{formatCurrency(total)}</p>
            </div>

            <div className='flexSpaceBetween cartDetail'>
                <strong>Shipping</strong>
                <p>{formatCurrency(shippingPrice)}</p>
            </div>

            <div className='flexSpaceBetween cartDetail'>
                <strong>Admin Fee</strong>
                <p>{formatCurrency(total * adminFee)}</p>
            </div>

            <div className='flexSpaceBetween cartDetail marginBottom25px'>
                <strong>GST</strong>
                <p>{formatCurrency(calculateGST(total))}</p>
            </div>

            <div className='flexSpaceBetween cartDetail marginBottom20px'>
                <p className='fontSize18px'>ORDER TOTAL</p>

                <strong className='fontSize18px'>{formatCurrency(total + (total * adminFee))}</strong>
            </div>
        </>     
    );
}

export default OrderSummaryInfo;
