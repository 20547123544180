import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Home from './pages/PrivatePages/Home';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import PrivateRoute from './elements/PrivateRoute/PrivateRoute';
import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import CategoryPage from './pages/PrivatePages/CategoryPage';
import ScrollToTop from './ScrollToTop';
import ProductPage from './pages/PrivatePages/ProductPage';
import CartModal from './components/CartModal';
import ShoppingCart from './pages/PrivatePages/ShoppingCart';
import CheckoutPage from './pages/PrivatePages/CheckoutPage';
import OrderSuccess from './pages/PrivatePages/OrderSuccess';
import AccountPage from './pages/PrivatePages/Account/AccountPage';
import YourOrder from './pages/PrivatePages/Account/YourOrder';
import YourReturn from './pages/PrivatePages/Account/YourReturns';
import SecurityPage from './pages/PrivatePages/Account/SecurityPage';
import Invoices from './pages/PrivatePages/Account/Invoices';
import Addresses from './pages/PrivatePages/Account/Addresses';
import ManageUsers from './pages/PrivatePages/Account/ManageUsers';
import ImportPriceList from './pages/PrivatePages/Account/ImportPriceList';
import Payment from './pages/PrivatePages/Account/Payment';
import ContactUsPage from './pages/PrivatePages/ContactUsPage';
import OrderByCSV from './pages/PrivatePages/OrderByCSV';
import DataFeed from './pages/PrivatePages/DataFeed';
import NewReturn from './pages/PrivatePages/Account/NewReturn';
import EditReturn from './pages/PrivatePages/Account/EditReturn';
import DownloadFiles from './pages/PrivatePages/Account/DownloadFiles';
import ApprovalOrderSuccess from './pages/PrivatePages/ApprovalOrderSuccess';
import Help from './pages/PrivatePages/Help';
import FAQs from './pages/PrivatePages/FAQs';
import DeliveryRates from './pages/PrivatePages/DeliveryRates';
import Disclaimer from './pages/PrivatePages/Disclaimer';
import PrivacyPolicy from './pages/PrivatePages/PrivacyPolicy';
import TermsConditions from './pages/PrivatePages/TermsConditions';
import CookiesPolicy from './pages/PrivatePages/CookiesPolicy';
import SearchPage from './pages/PrivatePages/SearchPage';
import SingleOrder from './pages/PrivatePages/Account/SingleOrder';
import SingleReturn from './pages/PrivatePages/Account/SingleReturn';
import SingleInvoice from './pages/PrivatePages/Account/SingleInvoice';
import BackOrder from './pages/PrivatePages/Account/BackOrder';
import SingleBackOrder from './pages/PrivatePages/Account/SingleBackOrder';

const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
      <div>
        <AnimatePresence>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute exact path="/shop" component={CategoryPage}/>
          <PrivateRoute exact path="/cart" component={ShoppingCart}/>
          <PrivateRoute exact path="/checkout" component={CheckoutPage}/>
          <PrivateRoute exact path="/checkout/:cartId" component={CheckoutPage}/>
          <PrivateRoute exact path="/order-success/:orderno" component={OrderSuccess}/>
          <PrivateRoute exact path="/cart-submitted/:orderno" component={ApprovalOrderSuccess}/>
          <PrivateRoute exact path="/your-account/new-return" component={NewReturn}/>
          <PrivateRoute exact path="/your-account/update-return/:returnId" component={EditReturn}/>
          <PrivateRoute exact path="/your-account" component={AccountPage}/>
          <PrivateRoute exact path="/your-account/your-order" component={YourOrder}/>
          <PrivateRoute exact path="/your-account/your-order/:orderno" component={SingleOrder}/>
          <PrivateRoute exact path="/your-account/back-order" component={BackOrder}/>
          <PrivateRoute exact path="/your-account/back-order/:orderno" component={SingleBackOrder}/>
          <PrivateRoute exact path="/your-account/returns/:returnno" component={SingleReturn}/>
          <PrivateRoute exact path="/your-account/returns" component={YourReturn}/>
          <PrivateRoute exact path="/your-account/login-detail" component={SecurityPage}/>
          <PrivateRoute exact path="/your-account/your-invoices" component={Invoices}/>
          <PrivateRoute exact path="/your-account/your-invoices/:invoiceno" component={SingleInvoice}/>
          <PrivateRoute exact path="/your-account/addresses" component={Addresses}/>
          <PrivateRoute exact path="/your-account/manage-users" component={ManageUsers}/>
          <PrivateRoute exact path="/your-account/pricelist" component={ImportPriceList}/>
          <PrivateRoute exact path="/your-account/files" component={DownloadFiles}/>
          <PrivateRoute exact path="/your-account/payment" component={Payment}/>
          <PrivateRoute exact path="/search/:searchParam" component={SearchPage}/>
          <PrivateRoute exact path="/search/:searchParam/:selectedCate" component={SearchPage}/>
          <PrivateRoute exact path="/shop/categories" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/categories/:cateId" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/categories/:cateId/:subId" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/brands" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/brands/:cateId" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/brands/:cateId/:subId" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/device-vendors" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/device-vendors/:cateId" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/device-vendors/:cateId/:subId" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/top-sellers" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/clearance" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/new" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/special" component={CategoryPage}/>
          <PrivateRoute exact path="/shop/:productId" component={ProductPage}/>
          <PrivateRoute exact path="/contact-us" component={ContactUsPage}/>
          <PrivateRoute exact path="/order-by-csv" component={OrderByCSV}/>
          <PrivateRoute exact path="/data-feed" component={DataFeed}/>
          <PrivateRoute exact path="/help" component={Help}/>
          <PrivateRoute exact path="/faqs" component={FAQs}/>
          <PrivateRoute exact path="/contact-us" component={ContactUsPage}/>
          <PrivateRoute exact path="/returns-policy" component={DeliveryRates}/>
          <PrivateRoute exact path="/disclaimer" component={Disclaimer}/>
          <PrivateRoute exact path="/privacy-policy" component={PrivacyPolicy}/>
          <PrivateRoute exact path="/terms-conditions" component={TermsConditions}/>
          <PrivateRoute exact path="/cookies" component={CookiesPolicy}/>
          <Route exact path="/login" component={Login} /> 
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/reset-password/:hash" component={ResetPassword} />
          <Route path="*" component={NoMatch}/>
        </Switch>
        </AnimatePresence>
      </div>
      <CartModal/>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;