import React from 'react';
import { Carousel, Image } from 'antd';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';
import { imageUrl } from '../Constants';

const ProductImageSlider: React.FC<{images: Array<string | undefined>}> = ({images = []}) => {
    const carouselRef = React.useRef<CarouselRef>(null);

    const PrevArrow: React.FC<{className: string}> = ({className}) => {
        return (
          <div className={className}>
              <ArrowLeftOutlined onClick={handlePrev} />
          </div>
        );
    }
    
    const NextArrow: React.FC<{className: string}> = ({className}) => {
        return (
            <div className={className}>
                <ArrowRightOutlined onClick={handleNext}/>
            </div>
        );
    }

    const handleNext = () => {
        if(carouselRef && carouselRef.current) {
            carouselRef.current.next();
        }
    }

    const handlePrev = () => {
        if(carouselRef && carouselRef.current) {
            carouselRef.current.prev();
        }
    }

    return (
            <Carousel ref={carouselRef} arrows={true} nextArrow={<NextArrow className='nextArrow arrow' />} prevArrow={<PrevArrow className='prevArrow arrow'/>} dotPosition='bottom' swipeToSlide={true} touchMove={true} accessibility={true} draggable={true} infinite className='productImageSlider' slidesToShow={1}>
        {
            images.map((i, k) => {
                return <Image key={k} preview={false} src={imageUrl + i}/>
            })
        }
        </Carousel>
    );
}

export default ProductImageSlider;