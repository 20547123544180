import React from 'react';
import { CartProductObject, CartState } from '../Constants';
import { ShoppingCartsMessage } from '../RESTAPI';
import { CartReducer } from './CartReducer';

type CartContextObject = {
    removeProduct: (payload:CartProductObject) => void;
    addProduct: (payload:CartProductObject) => void;
    clearCart: () => void,
    displayCartModal: () => void,
    hideCartModal: () => void,
    setShoppingCart: (payload:ShoppingCartsMessage) => void,
    loadCurrentCart: (payload:ShoppingCartsMessage) => void,
    loadApprovingCart: (payload: string) => void,
    cartState: CartState
}

const blankPayload = {} as CartProductObject;

const initialState = { displayModal: false, approvalCartId: '' } as CartState;

export const CartContext = React.createContext<CartContextObject>({
    removeProduct: () => null,
    addProduct: () => null,
    clearCart: () => null,
    displayCartModal: () => null,
    hideCartModal: () => null,
    setShoppingCart: () => null,
    loadCurrentCart: () => null,
    loadApprovingCart: () => null,
    cartState: initialState
});

const CartContextProvider: React.FC = ({children}) => {

    const [cartState, dispatch] = React.useReducer(CartReducer, initialState)

    const addProduct = (payload: CartProductObject) => {
        dispatch({type: 'ADD_ITEM', payload})
    }

    const removeProduct = (payload: CartProductObject) => {
        dispatch({type: 'REMOVE_ITEM', payload})
    }

    const clearCart = () => {
        dispatch({type: 'CLEAR', payload: blankPayload})
    }

    const displayCartModal = () => {
        dispatch({type: 'DISPLAY CART MODAL', payload: blankPayload})
    }

    const hideCartModal = () => {
        dispatch({type: 'HIDE CART MODAL', payload: blankPayload})
    }

    const setShoppingCart = (payload: ShoppingCartsMessage) => {
        dispatch({type: 'SET_SHOPPING_CART', payload})
    }

    const loadCurrentCart = (payload: ShoppingCartsMessage) => {
        dispatch({type: 'LOAD_CURRENT_CART', payload})
    }

    const loadApprovingCart = (payload: string) => {
        dispatch({type: 'LOAD_APPROVING_CART', payload})
    }

    const contextValues = {
        removeProduct,
        addProduct,
        clearCart,
        displayCartModal,
        hideCartModal,
        setShoppingCart,
        loadCurrentCart,
        loadApprovingCart,
        cartState
    } 

    return ( 
        <CartContext.Provider value={contextValues} >
            { children }
        </CartContext.Provider>
     );
}
 
export default CartContextProvider;