import React from 'react';
import {Row, Col, Typography} from 'antd';
import WizardForm from '../components/WizardForm';
import {regoSteps, Location} from '../Constants';
import {myAuth} from '../elements/PrivateRoute/PrivateRoute';
import PageLink from '../elements/PageLink/PageLink';
import LoginFooter from '../components/LoginFooter';
import CellnetLogo from '../elements/Logo/Logo';
import HomeBg from '../components/HomeBg';
import { pageVariants } from '../Constants';
import { motion } from "framer-motion";
import { Redirect, useLocation  } from 'react-router-dom';

const { Title } = Typography;

function Register() {
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [direction, setDirection] = React.useState<number>(1);

  const handleSetCurrentStep = (direction: number) => {
    setDirection(direction);
    setCurrentStep(currentStep + direction);
  }

  let prevProp = '/';
  let location = useLocation<Location>();
  if(location.state) {
    prevProp = location.state.from;
  }

  // stop user from accessing login page again
  if (myAuth.isAuthenticated) {
    return (
        <Redirect
            to={{
                pathname: prevProp
            }}
        />
    )
  }

  return (
    <Row justify='center' align='middle' className='fullHeight overflowHidden'>
        <HomeBg isRego currentStep={currentStep} steps={regoSteps}/>
        <Col xs={24} sm={24} md={8} lg={8} xl={13} className='overflowHidden padding30px fullHeight verticalCenter flexColumn whiteBg'>
          <motion.div className='marginTopAuto' initial='initial' animate="in" exit="out" variants={pageVariants}>
            <CellnetLogo to='/' className="textCenter marginBottom40px"/>
            <Title level={5} className='textCenter marginBottom25px'>
              {
                currentStep !== regoSteps.length ? (
                  <>
                  Sign up to Cellnet
                  </>
                ) : (
                  <>
                  Success!
                  </>
                )
              }
              
            </Title>
            <WizardForm currentValues={{}} className='maxWidth300px' finalButton='Login' title='Sign In' steps={regoSteps} currentStep={currentStep} direction={direction} setStep={(direction: number) => handleSetCurrentStep(direction)}/>
            <div className='textCenter marginTop25px'>
            {
              currentStep !== regoSteps.length ? (
                <>
                <strong>Already a member?</strong>
                <PageLink to="/" bold className='marginLeft10px text-secondaryColor'>Sign In</PageLink>
                </>
              ) : (
                <>
                <strong>Didn’t receive the email?</strong>
                <PageLink to="/" bold className='marginLeft10px text-secondaryColor'>Send again</PageLink>
                </>
              )
            }
            </div>
          </motion.div>

          <LoginFooter className='marginTopAuto'/>
        </Col>
    </Row>
  );
}

export default Register;
