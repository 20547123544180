import React from 'react';
import { Row, Col, Steps, Radio, Button, Modal, Input } from 'antd';
import {ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import { AnimatePresence, motion } from 'framer-motion';
import { envi, pageVariants } from '../../Constants';
import OrderSummaryInfo, { getTotal } from '../../components/OrderSummaryInfo';
import { PaypalIcon, MasterCardIcon, VisaIcon, AmexIcon } from '../../elements/CustomIcons/CustomIcons';
import CheckoutLayout from '../../layouts/CheckoutLayout';
import { RadioChangeEvent } from 'antd/lib/radio';
import { UserContext } from '../../contexts/UserContext';
import { ServiceMainRESTClient, FreightMethodBean, PaymentMethodBean, MethodCallback, CheckoutMessage, CheckCCOrderPaymentMessage, UpdatePayPalAllocationPaymentMessage, UserAccess, ClientHelper } from '../../RESTAPI';
import { CartContext } from '../../contexts/CartContext';
import { ProductContext } from '../../contexts/ProductContext';
import _ from 'lodash';
import { logout } from '../../elements/PrivateRoute/PrivateRoute';
import PayPalButton from '../../components/PaypalButton';
import {creditCheck} from '../../Constants';
import { Link, useHistory, useParams } from 'react-router-dom';

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const { Step } = Steps;

const MAXSTEP = 3;

const CREDIT_CHECK = creditCheck;

const CheckoutPage: React.FC = () => {
    const [current, setCurrent] = React.useState<number>(0);
    const [address, setAddress] = React.useState<string>('');
    const [shipping, setShipping] = React.useState<string>('');
    const [shippingPrice, setShippingPrice] = React.useState<number>(0);
    const [payment, setPayment] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [checkoutPaymentVisible, setCheckoutPaymentVisible] = React.useState<boolean>(false);
    const [iframeSrc, setIframeSrc] = React.useState<string>('');

    const [webOrderNo, setWebOrderNo] = React.useState<string>('');
    const [ccPaymentId, setCCPaymentId] = React.useState<string>('');
    const [payPalPaymentId, setPayPalPaymentId] = React.useState<string>('');

    const [adminFee, setAdminFee] = React.useState<number>(0);

    const [addressOptions, setAddressOptions] = React.useState<Array<any>>([]);
    const [shippingOptions, setShippingOptions] = React.useState<Array<any>>([]);
    const [paymentOptions, setPaymentOptions] = React.useState<Array<any>>([]);
    const [approveCartId, setApproveCartId] = React.useState<string>('');

    const {userState} = React.useContext(UserContext);
    const {cartState, loadApprovingCart} = React.useContext(CartContext);
    const {productState} = React.useContext(ProductContext);

    const [isCashCustomer, setIsCashCustomer] = React.useState<boolean>(true);

    const [customerEmail, setCustomerEmail] = React.useState<string>('');
    const [customerRef, setCustomerRef] = React.useState<string>('');

    const [displayCheckoutButton, setDisplayCheckoutButton] =  React.useState<boolean>(false);

    const {cartId} = useParams<{cartId: string}>();
    const history = useHistory();

    const handleAddressSelect = (e: RadioChangeEvent) => {
        setAddress(e.target.value);
    }

    const handleShippingSelect = (e: RadioChangeEvent) => {
        setShipping(e.target.value);

        // find shipping option
        const selectedShipping = _.find(shippingOptions, (s) => {
            return s.value === e.target.value
        })

        setShippingPrice(selectedShipping?.charge);
    }

    const handlePaymentSelect = (e: RadioChangeEvent) => {
        if(e.target.value) {
            setPayment(e.target.value);
        }
    }

    React.useEffect(() => {
        if(userState.addresses) {
            const listAddress = [] as Array<any>;

            if(userState.addresses.length === 0) {
                if(userState.account) {
                    const defaultAddress = userState.account.invoiceAddress;                    
                    listAddress.push({
                        label: (<div>
                            <p className='noMarginBottom text-grayColor'>{defaultAddress.addressName ? defaultAddress.addressName : 'Default Address'}</p>
                            <p className='noMarginBottom'><strong>{defaultAddress.contactName}</strong></p>
                            <p>{defaultAddress.lines.join(' ')}<br/> {defaultAddress.suburb} {defaultAddress.state} {defaultAddress.postcode} <br/>Ph: {defaultAddress.contactPhone}</p>
                            </div>),
                        value: defaultAddress.addressId
                    })
                }
            } else {
                userState.addresses.forEach(a => {
                    if(a.addressId) {
                        listAddress.push({
                            label: (<div>
                                <p className='noMarginBottom text-grayColor'>{a.addressName}</p>
                                <p className='noMarginBottom'><strong>{a.contactName}</strong></p>
                                <p>{a.lines.join(' ')}<br/> {a.suburb} {a.state} {a.postcode} <br/>Ph: {a.contactPhone}</p>
                                </div>),
                            value: a.addressId
                        })
                    }
                });
            }

            setAddressOptions(listAddress);
            setAddress(listAddress[0] ? listAddress[0].value : '');
        }

        if(productState.products && cartState.cartItems && userState) {
            const shippingMethods = FreightMethodBean.getFreightMethodsForOrder(userState.userProfile.businessGroup, userState.currentCustomer, getTotal(productState.products, cartState.cartItems));
            const listShipping = [] as Array<any>;

            if(shippingMethods) {
                shippingMethods.forEach(s => {
                    listShipping.push({
                        label: s.getName(),
                        value: s.getId(),
                        charge: s.getCharge(),
                        code: s.getCode()
                    })
                });
            }
            
            setShippingOptions(listShipping);
            setShipping(listShipping[0].value);
            setShippingPrice(listShipping[0].charge);

            // NZ only use DIRECT and PICKUP and AU only uses CREDIT, DIRECT and PAYPAL
            if(userState.userProfile.businessGroup) {
                const listPayment = [] as Array<any>;
                
                if(userState.currentCustomer.country.includes('NZ')) {
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.DIRECT_DEPOSIT.getDescription()}</strong></p>
                            </div>), value: PaymentMethodBean.DIRECT_DEPOSIT.getId()
                        });
                    
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.CUSTOMER_PICKUP.getDescription()}</strong></p>
                            </div>), value: PaymentMethodBean.CUSTOMER_PICKUP.getId()
                        });
                } else {
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_CREDIT.getDescription()}</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.VISA_CREDIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_DEBIT.getDescription()}</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.VISA_DEBIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_CREDIT.getDescription()}</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.MASTERCARD_CREDIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_DEBIT.getDescription()}</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.MASTERCARD_DEBIT.getId()
                        });
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.AMEX.getDescription()}</strong></p>
                            {/*<CreditCardForm/>*/}
                            </div>), value: PaymentMethodBean.AMEX.getId()
                        });
                    
                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.DIRECT_DEPOSIT.getDescription()}</strong></p>
                            </div>), value: PaymentMethodBean.DIRECT_DEPOSIT.getId()
                        });

                    listPayment.push({
                        label: (<div>
                            <p className='noMarginBottom'><strong>{PaymentMethodBean.PAYPAL.getDescription()}</strong></p>
                            </div>), value: PaymentMethodBean.PAYPAL.getId()
                        });
                }

                setPaymentOptions(listPayment);
                setPayment(listPayment[0].value);
            }

        }
    }, [userState, userState.addresses, userState.account, cartState, productState])

    React.useEffect(() => {
        const paymentObj = PaymentMethodBean.get(payment);
        if(paymentObj) {
            setAdminFee(paymentObj.getSurchargePct());
        }
    }, [payment])

    React.useEffect(() => {
        if (userState.currentCustomer) {
            setIsCashCustomer(ClientHelper.isCashCustomer(userState.currentCustomer, userState.currentPayer));
            setCustomerEmail(userState.userProfile?.email);
            setCustomerRef(userState.currentUserAccount?.accountid);
        }
    }, [userState.currentCustomer, userState.userProfile, userState.currentUserAccount, userState.currentPayer])

    React.useEffect(() => {
        if(cartId) {
            // check if cartId is in approving cart
            if(cartState.shoppingCart) {
                if(cartState.shoppingCart.shoppingCartIdToShoppingCart[cartId] && cartState.shoppingCart.shoppingCartIdToShoppingCart[cartId].status === 'WA' && cartState.shoppingCart.shoppingCartIdToShoppingCart[cartId].type === 'T') {
                    if(userState.currentUserAccount.permissions.includes(UserAccess.APPROVE_ORDERS)) {
                        loadApprovingCart(cartId);
                        setApproveCartId(cartId);

                        restClient.checkoutApprovalShoppingCart(cartId, CheckoutCurrentCartCallback)
                    }
                }
            }
        } else {
            restClient.checkoutCurrentShoppingCart(CheckoutCurrentCartCallback);
        }
    }, [cartId])

    const handleCustomerEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerEmail(e.currentTarget.value);
    }

    const handleCustomerRef = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerRef(e.currentTarget.value);
    }

    const step = (key: number) => {
        let result:React.ReactNode = '';
        switch (key) {
            case 0:
                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <Input disabled={loading} size="large" value={customerEmail} onChange={handleCustomerEmail} className="has-float-label marginBottom15px" placeholder='Customer Email' suffix={<label className="floating-label" htmlFor="name">Customer Email</label>}/>  
                        <Input disabled={loading} size="large" value={customerRef} onChange={handleCustomerRef} className="has-float-label" placeholder='Customer Ref' suffix={<label className="floating-label" htmlFor="name">Customer Ref</label>}/>  
                        <br/>
                        <br/>
                        <Radio.Group
                            disabled={loading}
                            options={addressOptions}
                            value={address}
                            className='verticalRadioGroup'
                            onChange={handleAddressSelect}
                        />
                        <Link to='/your-account/addresses'>Create New Address</Link>
                    </div>
                </motion.div>)
                break;

            case 1:
                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <Radio.Group
                            options={shippingOptions}
                            value={shipping}
                            className='verticalRadioGroup'
                            onChange={handleShippingSelect}
                        />
                    </div>
                </motion.div>)
                break;

            case 2:
                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <Radio.Group
                            options={paymentOptions}
                            value={payment}
                            className='verticalRadioGroup'
                            onChange={handlePaymentSelect}
                        />
                    </div>
                </motion.div>)
                break;
            
            case 3:
                let descAddress = _.find(addressOptions, (a: any) => {
                    return a.value === address
                })

                result = (<motion.div key={key} initial='initial' animate="in" exit="out" variants={pageVariants}>
                    <div className='checkoutStep'>
                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Customer Email</strong>
                            <p>{customerEmail}</p>
                        </div>

                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Customer Reference</strong>
                            <p>{customerRef}</p>
                        </div>

                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Shipping Address</strong>
                            {descAddress.label}
                        </div>

                        <div className='flexSpaceBetween cartDetail'>
                            <strong>Payment Type</strong>
                            <p>{PaymentMethodBean.get(payment) ? PaymentMethodBean.get(payment)?.getDescription() : ''}</p>
                        </div>
                    </div>
                </motion.div>)
                break;

            default:
                break;
        }

        return result;
    }

    // checkout current cart
    const CheckoutCurrentCartCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setWebOrderNo(message.webOrderNo);
                    //restClient.checkoutSetConfirmEmailCustRef(checkoutCart, userState.userProfile.email, customerReference, CheckoutCallback);
                } else {
                    alert(message.error);
                    setLoading(false);
                }
            } else {
                logout()
            }
        }
    }

    // 1st step
    const CheckoutCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    const selectedAddress = _.find(userState.addresses, (a) => {
                        return a.addressId === address;
                    })

                    let checkoutCart = cartState.shoppingCart? cartState.shoppingCart.currentShoppingCartId : '';
                    if(approveCartId !== '') {
                        checkoutCart = approveCartId;
                    }
                    
                    if(selectedAddress) {
                        restClient.checkoutSetDeliveryAddress(checkoutCart, selectedAddress.companyName, selectedAddress.contactName, selectedAddress.lines[0], selectedAddress.lines[1], selectedAddress.lines[2], selectedAddress.lines[3], selectedAddress.suburb, selectedAddress.city, selectedAddress.state, selectedAddress.postcode, CheckoutDeliveryAddressCallback)
                    } else {
                        // for default address / invoice address
                        if(userState?.account?.invoiceAddress) {
                            const defaultAddress = userState.account.invoiceAddress;

                            restClient.checkoutSetDeliveryAddress(checkoutCart, defaultAddress.companyName, (defaultAddress.contactName === '' ? userState?.userProfile.firstname + ' ' + userState?.userProfile.lastname : defaultAddress.contactName), defaultAddress.lines[0], defaultAddress.lines[1], defaultAddress.lines[2], defaultAddress.lines[3], defaultAddress.suburb, defaultAddress.city, defaultAddress.state, defaultAddress.postcode, CheckoutDeliveryAddressCallback)
                        } else {
                            alert('Selected address is invalid!')
                            setLoading(false);
                        }
                    }
                    
                } else {
                    alert(message.error);
                    setLoading(false);
                }
            } else {
                logout()
            }
        }
    }

    // 2nd step
    const CheckoutDeliveryAddressCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setWebOrderNo(message.webOrderNo);
                    setCurrent(current + 1);
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }

    // 3rd step
    const CheckoutFreightCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setCurrent(current + 1);
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }
    
    // 4rd step
    const CheckoutPaymentCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    setDisplayCheckoutButton(true);
                    setCurrent(current + 1);
                } else {
                    setDisplayCheckoutButton(false);
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }

    // 5th step
    const CheckoutCompleteCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    // its done, waiting for checkout
                    if(payment === PaymentMethodBean.PAYPAL.getId() || payment === PaymentMethodBean.MASTERCARD_DEBIT.getId() || payment === PaymentMethodBean.MASTERCARD_CREDIT.getId() || payment === PaymentMethodBean.VISA_CREDIT.getId() || payment === PaymentMethodBean.VISA_DEBIT.getId() || payment === PaymentMethodBean.AMEX.getId()) {
                        // waiting for successful
                        if (message.ccPaymentId != null) {
                            // setccPaymentId
                            setCCPaymentId(message.ccPaymentId);
                            setWebOrderNo(message.webOrderNo);
                            
                            // open iframe
                            setIframeSrc(CREDIT_CHECK + '?webOrderNo=' + message.webOrderNo);
                            setCheckoutPaymentVisible(true);
                        }

                        if (message.payPalPayment != null) {
                            // setccPaymentId
                            setPayPalPaymentId(message.payPalPayment);
                            setWebOrderNo(message.webOrderNo);                            

                        }
                    } else {
                        history.push('/order-success/' + message.webOrderNo);
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }

    // check if payment got through via Paypal
    const updatePayPalOrderPaymentCallback: MethodCallback<UpdatePayPalAllocationPaymentMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: UpdatePayPalAllocationPaymentMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    // success
                    history.push('/order-success/' + webOrderNo);
                    
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }

    // check if payment got throuhg
    const checkCCOrderPaymentStatusCallback: MethodCallback<CheckCCOrderPaymentMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckCCOrderPaymentMessage, context: any): void {
            if(message.authenticated) {
                if(message.error === null || message.error === '') {
                    if(message.status === 'COMPLETE' || message.status === 'ORDERED') {
                        // turn off as it is succeeded
                        setCheckoutPaymentVisible(false);
                        history.push('/order-success/' + webOrderNo);
                    } else {
                        // do it again as the user has not submitted any credit card or payment details
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
            setLoading(false);
        }
    }

    const handleCheckout = () => {
        setLoading(true);
        let checkoutCart = cartState.shoppingCart? cartState.shoppingCart.currentShoppingCartId : '';
        if(approveCartId !== '') {
            checkoutCart = approveCartId;
        }


        restClient.checkoutConfirm(checkoutCart, CheckoutCompleteCallback);

        /*if(approveCartId !== '') {
            restClient.checkoutApprovalShoppingCart(approveCartId, CheckoutCurrentCartCallback);
        } else {
            restClient.checkoutCurrentShoppingCart(CheckoutCurrentCartCallback);
        }*/
    }

    const prevStepTitle = () => {
        let result = '';

        switch (current) {
            case 0:
                result = 'Continue Shopping';
                break;
            
            case 1:
                result = 'Address';
                break;

            case 2:
                result = 'Shipping';
                break;

            case 3:
                result = 'Payment';
                break;
        
            default:
                break;
        }

        return result;
    }

    const handleGoBack = () => {
        const goBackStep = current - 1;

        if(goBackStep < 0) {
            history.push('/');
        } else {
            setCurrent(current - 1);
        }
        
    }

    const nextStepTitle = () => {
        let result = '';

        switch (current) {
            case 0:
                result = 'Shipping';
                break;

            case 1:
                result = 'Payment';
                break;

            case 2:
                result = 'Confirm Order';
                break;
        
            default:
                break;
        }

        return result;
    }

    const iFrameChange = () => {
        if(ccPaymentId !== '' && webOrderNo !== '') {
            restClient.checkCCOrderPaymentStatus(webOrderNo, ccPaymentId, checkCCOrderPaymentStatusCallback);
        }
    }

    const handleNext = (step:number) => {
        let checkoutCart = cartState.shoppingCart? cartState.shoppingCart.currentShoppingCartId : '';
        if(approveCartId !== '') {
            checkoutCart = approveCartId;
        }
        switch (step) {
            case 1:
                setLoading(true);
                restClient.checkoutSetConfirmEmailCustRef(checkoutCart, customerEmail, customerRef, CheckoutCallback);
                break;

            case 2:
                setLoading(true);
                restClient.checkoutSetFreightBackOrderOptions(checkoutCart, shipping, true, CheckoutFreightCallback);
                break;

            case 3:
                setLoading(true);
                if(payment === PaymentMethodBean.PAYPAL.getId() || payment === PaymentMethodBean.MASTERCARD_DEBIT.getId() || payment === PaymentMethodBean.MASTERCARD_CREDIT.getId() || payment === PaymentMethodBean.VISA_CREDIT.getId() || payment === PaymentMethodBean.VISA_DEBIT.getId() || payment === PaymentMethodBean.AMEX.getId() || isCashCustomer) {
                    restClient.checkoutSetPaymentMethod(checkoutCart, payment, CheckoutPaymentCallback )
                }
                break;
        
            default:
                setLoading(false);
                break;
        }
    }

    return (
        <CheckoutLayout>
            <Row className='whiteBg marginBottom40px'>
                <Col span={24}>
                    <div className='content-wrapper '>
                    <Steps
                        type="navigation"
                        size="small"
                        className="site-navigation-steps"
                        current={current}
                        onChange={(current) => setCurrent(current)}
                        >
                        <Step status={current >= 0 ? 'process': 'wait'} title="Address"/>
                        <Step status={current >= 1 ? 'process': 'wait'} title="Shipping" disabled={current < 1 ? true: false}/>
                        {
                            isCashCustomer && <Step status={current >= 2 ? 'process': 'wait'} title="Payment" disabled={current < 2 ? true: false} />
                        }
                        <Step status={current >= 2 ? 'process': 'wait'} title="Confirm Order" disabled={current < 2 ? true: false}/>
                    </Steps>
                    </div>
                </Col>
            </Row>
            <div className='content-wrapper marginBottom40px'> 
                <Row>
                    <Col span={24}>
                        <h2 className='headerTitle textCenter'>CHECKOUT</h2>
                    </Col>
                </Row>
                <Row gutter={30}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={14} >
                        <div className='checkoutSteps'>
                            <AnimatePresence exitBeforeEnter initial={false}>
                                {step(current)}  
                                <div className='navigationSteps'>
                                    {
                                        (current <= MAXSTEP) && (<motion.div initial='initial' animate="in" exit="out" variants={pageVariants}>
                                        <Button loading={loading} type="text" className='navigationStep' onClick={() => handleGoBack()}><ArrowLeftOutlined />{prevStepTitle()}</Button>
                                        </motion.div>)
                                    }
                                    {
                                        (current < (isCashCustomer ? MAXSTEP : MAXSTEP - 1) && address !== '') && (<motion.div initial='initial' animate="in" exit="out" variants={pageVariants}>
                                        <Button loading={loading} type="text" className='navigationStep' onClick={() => handleNext(current+1)}>{nextStepTitle()} <ArrowRightOutlined /></Button>
                                        </motion.div>)
                                    }
                                </div>
                            </AnimatePresence>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <div className='orderSummary'>
                            <h2 className='headerTitle'>ORDER SUMMARY</h2>
                            <OrderSummaryInfo shippingPrice={shippingPrice} adminFee={adminFee}/>
                            {
                                (current === (isCashCustomer ? MAXSTEP : MAXSTEP - 1) && displayCheckoutButton) &&
                                (payment !== PaymentMethodBean.PAYPAL.getId() ? <Button loading={loading} htmlType='submit' type='primary' block className='marginBottom20px' onClick={handleCheckout}>Place Order</Button> :
                                    <PayPalButton intent='capture' env={envi} currency='AUD' total={getTotal(productState.products, cartState.cartItems) + (getTotal(productState.products, cartState.cartItems) * adminFee) } orderNo={webOrderNo}
                                        onSuccess={(data) => {
                                            setLoading(true);
                                            restClient.updatePayPalOrderPayment(webOrderNo, data.orderID, data.payerID, updatePayPalOrderPaymentCallback);
                                        }}
                                        getWeborder={() => {
                                            let checkoutCart = cartState.shoppingCart? cartState.shoppingCart.currentShoppingCartId : '';
                                            if(approveCartId !== '') {
                                                checkoutCart = approveCartId;
                                            }
                                           
                                            restClient.checkoutConfirm(checkoutCart, CheckoutCompleteCallback);
                                        }}
                                        
                                    />
                                )
                            }
                            <p className='fontSize16px'>We accept</p>
                            
                            <div className='displayInlineBlock'>
                                <PaypalIcon/>
                            </div>

                            <div className='displayInlineBlock marginLeft20px'>
                                <MasterCardIcon/>
                            </div>

                            <div className='displayInlineBlock marginLeft20px'>
                                <VisaIcon/>
                            </div>

                            <div className='displayInlineBlock marginLeft20px'>
                                <AmexIcon />
                            </div>
                        </div>                        
                    </Col>
                </Row>
            </div>
            <Modal
                title="Payment"
                visible={checkoutPaymentVisible}
                centered
                closable={false}
                footer={null}
                width={600}
                onCancel={() => {}}
            >
                <iframe title='Credit Card Payment' src={iframeSrc} id='paymentIFrame' frameBorder='0' onLoad={() => iFrameChange()}></iframe>
            </Modal>
        </CheckoutLayout>
    );
}

export default CheckoutPage;
