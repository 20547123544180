import React from 'react';
import { Button} from 'antd';
import { CartContext } from '../contexts/CartContext';
import { MethodCallback, ProductExt, ServiceMainRESTClient, ShoppingCartsMessage } from '../RESTAPI';
import { logout } from '../elements/PrivateRoute/PrivateRoute';
import _ from 'lodash';

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const AddToCart: React.FC<{title?: string, qty: number, product: ProductExt | undefined, type?: any}> = ({type = 'primary', title = 'Add To Cart', qty, product}) => {
  const {cartState, setShoppingCart, displayCartModal } = React.useContext(CartContext);

  const updateShoppingCallback: MethodCallback<ShoppingCartsMessage> = {
    onFailure(error: string, context: any): void {
    },

    onProgress(loaded: number, total: number): void {},

    onSuccess(message: ShoppingCartsMessage, context: any): void {
        if(message.authenticated) {
            setShoppingCart(message);
            displayCartModal();
        } else {
            logout()
        }
    }
  }

  const handleAddToCart = (e: React.MouseEvent) => {
    if(product) {
      // get current qty
      let currentQty = 0;

      if(cartState && cartState.cartItems?.length > 0) {
        // get product
        let p = _.find(cartState.cartItems, (c) => {
          return c.productid === product.product.id
        })

        if(p) {
          currentQty = p.quantity;
        }
      }

      restClient.updateShoppingCartProductQuantity(product.product.id, currentQty + qty, updateShoppingCallback)
    }
  }
  
  if(product) {
      return (
          <Button block htmlType="button" style={type === 'link' ? { padding: 0, height: 'auto' } : {}} type={type} onClick={(e) => handleAddToCart(e)}>{title}</Button>
      );
  } else {
      return (
          <Button block htmlType="button" style={type === 'link' ? { padding: 0, height: 'auto' } : {}} type='ghost'>Not Available</Button>
      );
  }
  
}

export default AddToCart;