import React from 'react';
import { Row, Col, Typography, Form, Input, Button, Select, Modal} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MainLayout from '../../../layouts/MainLayout';
import { UserContext } from '../../../contexts/UserContext';
import { MethodCallback, ReturnMessage, ReturnRequestLine, ServiceMainRESTClient, ReturnsMessage } from '../../../RESTAPI';
import { pageVariants } from '../../../Constants';
import _ from 'lodash';
import { motion } from 'framer-motion';
import { logout } from '../../../elements/PrivateRoute/PrivateRoute';
import { useHistory, useParams } from 'react-router-dom';
import { ProductContext } from '../../../contexts/ProductContext';

const { Title } = Typography;

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const NewReturn: React.FC = () => {
    const {userState, setReturns} = React.useContext(UserContext);
    const {productState} = React.useContext(ProductContext);

    const [currentRequestNo, setCurrentRequestNo] = React.useState<number>(0);
    const [currentRequestLines, setCurrentRequestLines] = React.useState<Array<ReturnRequestLine>>([]);
    const [currentValues, setCurrentValues] = React.useState<any>({});
    const [productValue, setProductValue] = React.useState<any>({});

    const [productModal, setProductModal] = React.useState<boolean>(false);
    const [addLoading, setAddLoading] = React.useState<boolean>(false);

    const {returnId} = useParams<{returnId: string}>();

    const [form] = Form.useForm();
    const [mainForm] = Form.useForm();
    const history = useHistory();

    React.useEffect(() => {
        if(returnId && userState.returns) {
            const returnObj = _.find(userState.returns.returns, (r) => {
                return r.requestno.toString() === returnId;
            })

            if(returnObj) {
                setCurrentRequestNo(returnObj.requestno);
                const defaultValue = {
                    'customerRef': returnObj.customerref,
                    'firstname': returnObj.contactname.split(' ')[0],
                    'lastname': returnObj.contactname.split(' ')[1],
                    'email': returnObj.contactemail,
                    'phone': returnObj.contactphone,
                    'reason': returnObj.reason,
                    'products': []
                }
                setCurrentValues(defaultValue);
                if(userState.returns.lines[returnObj.requestno]) {
                    setCurrentRequestLines(userState.returns.lines[returnObj.requestno]);
                }
            }
        }
    }, [returnId, userState.returns])

    const CreateReturnContact:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                    setAddLoading(false);
                } else {
                    if(currentRequestNo === 0) {
                        setCurrentRequestNo(message.returnRequest.requestno);
                        setCurrentRequestLines(message.lines);
                    }
                    
                    restClient.addReturnRequestProduct(message.returnRequest.requestno, productValue.productNo.toString(), parseInt(productValue.productQty), productValue.faultDescription ? productValue.faultDescription : message.returnRequest.reason, addProductLineCallback)
                }
            } else {
                logout();
            }
        }
    }

    const ReturnCallback: MethodCallback<ReturnsMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(message: ReturnsMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if(!message.authenticated) {
                logout();
            } else {
                // save something
                context.setReturns(message);
                history.push('/your-account/returns/');
            }
        }
    }

    const DeleteLineCallback:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                    setAddLoading(false);
                } else {
                    setCurrentRequestLines(message.lines);
                }
            } else {
                logout();
            }
        }
    }

    const addProductLineCallback:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                } else {
                    setCurrentRequestLines(message.lines);

                    form.resetFields();
                    setProductModal(false);
                }
                setAddLoading(false);
            } else {
                logout();
            }
        }
    }

    const SubmitRequest:MethodCallback<ReturnMessage> = {

        onFailure(error: string, context: any): void {
            alert(error);
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ReturnMessage, context: any): void {
            if(message.authenticated) {
                if(message.error) {
                    alert(message.error);
                } else {
                    restClient.returnsFromServer(ReturnCallback, {setReturns});
                }
                setAddLoading(false);
            } else {
                logout();
            }
        }
    }

    const handleSaveField = (values: any) => {
        if(currentRequestNo) {
            setAddLoading(true);
            restClient.submitIncompleteReturnRequest(currentRequestNo, SubmitRequest);
        } else {
            alert('Please add return products!');
        }
    }

    const handleSaveProduct = (values: any) => {
        setAddLoading(true);
        
        if(currentRequestNo === 0) {
            /*
                TODO: would be good if put it into an interface
                'customerRef': returnObj.customerref,
                'firstname': returnObj.contactname.split(' ')[0],
                'lastname': returnObj.contactname.split(' ')[1],
                'email': returnObj.contactemail,
                'phone': returnObj.contactphone,
                'reason': returnObj.reason,
                'products': []
            */
            restClient.createReturnRequestContact(currentValues.reason, currentValues.customerRef, currentValues.firstname + ' ' + currentValues.lastname, currentValues.email, currentValues.phone, CreateReturnContact)
        } else {
            restClient.updateReturnRequestContact(currentRequestNo, currentValues.customerRef, currentValues.firstname + ' ' + currentValues.lastname, currentValues.email, currentValues.phone, CreateReturnContact)
        }
    }

    const optionReasons: Array<any> = [];
                
    if(userState.returns) {
        Object.keys(userState.returns.allReasonCodeToReason).forEach(key => {
            const reasonObj = userState.returns.allReasonCodeToReason[key];
            if(userState.returns.customerReasonCodes) {
                if(userState.returns.customerReasonCodes.includes(reasonObj.code)) {
                    optionReasons.push({
                        value: reasonObj.code,
                        label: reasonObj.name
                    })
                }
            }
        });
    }

    const productLine:Array<any> = [];
    if(currentRequestLines) {
        currentRequestLines.map((l, k) => {
            productLine.push({
                'productNo': l.itemno,
                'productQty': l.qty,
                'linno': l.linno,
                'requestNo': l.requestno,
                'faultDescription': l.faultdesc,
            })
            return null;
        })
    }

    const productOptions:Array<any> = []
    if(productState.products) {
        productState.products.map((p, k) => {
            productOptions.push({
                value: p.product.id,
                label: (<div><strong>{p.product.id}</strong><br/>{p.product.name}<br/>{p.product.ean}<br/>{p.product.description}</div>),
                desc: p.product.description ? p.product.description : '',
                ean: p.product.ean ? p.product.ean : ''
            })
            return null;
        })
    }

    const handleDeleteProductLine = (linno: number) => {
        if(currentRequestNo) {
            restClient.deleteReturnRequestLine(currentRequestNo, linno, DeleteLineCallback)
        }
    }

    const handleOpenProductModal = () => {
        mainForm.validateFields().then(values => {
            setProductModal(true)
        }).catch(errorInfo => {

        })
    }

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20} className='marginBottom40px'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <motion.div className='marginTopAuto' initial='initial' animate="in" exit="out" variants={pageVariants}>
                            <Title level={5} className='textCenter marginBottom25px'>
                                Lodge a Return Request                         
                            </Title>
                            <Form onFinish={(values) => {handleSaveField(values)}} onValuesChange={(_, values) => {setCurrentValues(values)}} form={mainForm}>
                                <Form.Item className="group-floating-label" hasFeedback name='customerRef' label='' rules={[{ required: true, message: 'Please input your customer reference!' }]}>
                                    <Input readOnly={addLoading} size="large" className="has-float-label" placeholder='Customer Reference' suffix={<label className="floating-label" htmlFor="name">Customer Reference</label>}/>  
                                </Form.Item>

                                <Form.Item className="group-floating-label" hasFeedback name='firstname' label='' rules={[{ required: true, message: 'Please input your first name!' }]}>
                                    <Input size="large" readOnly={addLoading} className="has-float-label" placeholder='First Name' suffix={<label className="floating-label" htmlFor="name">First Name</label>}/>  
                                </Form.Item>

                                <Form.Item className="group-floating-label" hasFeedback name='lastname' label='' rules={[{ required: true, message: 'Please input your last name!' }]}>
                                    <Input size="large" readOnly={addLoading} className="has-float-label" placeholder='Last Name' suffix={<label className="floating-label" htmlFor="name">Last Name</label>}/>  
                                </Form.Item>

                                <Form.Item className="group-floating-label" hasFeedback name='email' label='' rules={[{ type: 'email', message: 'The input is not a valid email'}, {required: true, message: 'Please input your E-mail!' }]}>
                                    <Input size="large" readOnly={addLoading} className="has-float-label" placeholder='Last Name' suffix={<label className="floating-label" htmlFor="name">Email</label>}/>  
                                </Form.Item>

                                <Form.Item className="group-floating-label" hasFeedback name='phone' label='' rules={[{required: true, message: 'Please input your phone number' }]}>
                                    <Input size="large" readOnly={addLoading} className="has-float-label" placeholder='Phone' suffix={<label className="floating-label" htmlFor="name">Phone</label>}/> 
                                </Form.Item>

                                <Form.Item className="group-floating-label" hasFeedback name='reason' label='' rules={[{required: true, message: 'Please select your reason' }]}>
                                    <Select showSearch={true} loading={addLoading} size='large' placeholder="Please select a reason" optionLabelProp="label" options={optionReasons}/>
                                </Form.Item>

                                <Title level={5} className='marginBottom25px'>Return Product(s)</Title>

                                {
                                    productLine.map((line, key) => 
                                        <Row key={key} gutter={[30, 30]}>
                                            <Col xs={24} sm={24} lg={7}>
                                                <strong>ProductNo:</strong><br/>
                                                {line.productNo}<br/>
                                                Line: {line.linno}
                                            </Col>
                                            <Col xs={24} sm={24} lg={7}>
                                                <strong>Product Qty:</strong><br/>
                                                {line.productQty}
                                            </Col>
                                            {
                                                line.faultDescription && 
                                                <Col xs={24} sm={24} lg={7}>
                                                    <strong>Fault Desc</strong><br/>
                                                    {line.faultDescription}
                                                </Col>
                                            }

                                            <Col xs={24} sm={24} lg={3}>
                                                <Button loading={addLoading} type='primary' block onClick={() => {handleDeleteProductLine(line.linno)}} icon={<MinusCircleOutlined/>}/>
                                            </Col>
                                            
                                        </Row>
                                    )
                                }

                                <Row gutter={[30,30]}>
                                    <Col span={24}>
                                        <Button loading={addLoading} type="dashed" block onClick={() => {handleOpenProductModal()}} icon={<PlusOutlined />}>
                                            Add Product
                                        </Button>
                                    </Col>
                                </Row>

                                <Button loading={addLoading} size='large' className='reverse-icon' type='primary' htmlType='submit'>Submit Request</Button>
                            </Form>
                        </motion.div>
                    </Col>
                </Row>
            </div>
            <Modal
                title="Adding return product"
                centered
                visible={productModal}
                className='cartModal'
                onCancel={() => setProductModal(false)}
            >
                <Form onFinish={(values) => {handleSaveProduct(values)}} form={form} onValuesChange={(_, values) => setProductValue(values)}>
                    <Row gutter={[30,30]}>
                        <Col xs={24} sm={24} lg={8}>
                            <Form.Item name='productNo' className="group-floating-label" hasFeedback label='' rules={[{required: true, message: 'Please input your product no' }]}>
                                <Select loading={addLoading} filterOption={(input, option) => {
                                    if(option) {
                                        if(option.label && option.desc) {
                                            if(option.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.desc.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.ean.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                                                return true
                                            } 
                                        }
                                    }
                                    return false
                                }} showSearch={true} size='large' placeholder="Please select a product" options={productOptions}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={8}>
                            <Form.Item name={'productQty'} fieldKey={'productQty'} className="group-floating-label" hasFeedback label='' rules={[{required: true, message: 'Please input your product quantity' }]}>
                                <Input readOnly={addLoading} size="large" type='number' className="has-float-label" placeholder='Product Quantity' suffix={<label className="floating-label">Product Quantity</label>}/> 
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={8}>
                            {
                                (currentValues.reason === 'R20' || currentValues.reason === 'R80') &&
                                <Form.Item name={'faultDescription'} fieldKey={'faultDescription'} className="group-floating-label" hasFeedback label='' rules={[{required: true, message: 'Please input your fault description' }]}>
                                    <Input readOnly={addLoading} size="large" type='text' className="has-float-label" placeholder='Fault Description' suffix={<label className="floating-label">Fault Description</label>}/> 
                                </Form.Item>
                            }
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col span={24}>
                            <Button loading={addLoading} htmlType='submit' block type="primary" className='marginBottom20px'>ADD PRODUCT</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </MainLayout>
    );
}

export default NewReturn;
