import { Button, Drawer, Row, Col, Radio, Select, InputNumber, Checkbox, Divider, Modal} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { UserContext } from '../contexts/UserContext';
import { AccountMessage, AllocateInvoicePaymentsMessage, AllocationPayment, CheckCCAllocationPaymentMessage, CheckCCOrderPaymentMessage, Invoice, MethodCallback, PaymentMethodBean, ServiceMainRESTClient, Transaction, UpdatePayPalAllocationPaymentMessage } from '../RESTAPI';
import { formatCurrency } from '../utils';
import _ from 'lodash';
import { logout } from '../elements/PrivateRoute/PrivateRoute';
import { creditCheck, envi, invoiceDownload } from '../Constants';
import PaypalButton from './PaypalButton';

const {Option} = Select;

const paymentOptions = [
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_CREDIT.getDescription()}</strong></p>
            {/*<CreditCardForm/>*/}
        </div>), value: PaymentMethodBean.VISA_CREDIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.VISA_DEBIT.getDescription()}</strong></p>
            {/*<CreditCardForm/>*/}
        </div>), value: PaymentMethodBean.VISA_DEBIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_CREDIT.getDescription()}</strong></p>
            {/*<CreditCardForm/>*/}
        </div>), value: PaymentMethodBean.MASTERCARD_CREDIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.MASTERCARD_DEBIT.getDescription()}</strong></p>
            {/*<CreditCardForm/>*/}
        </div>), value: PaymentMethodBean.MASTERCARD_DEBIT.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.AMEX.getDescription()}</strong></p>
            {/*<CreditCardForm/>*/}
        </div>), value: PaymentMethodBean.AMEX.getId() },
    {
        label: (<div>
            <p className='noMarginBottom'><strong>{PaymentMethodBean.PAYPAL.getDescription()}</strong></p>
        </div>), value: PaymentMethodBean.PAYPAL.getId() }
];

interface DueInvoice extends Invoice  {
    totalDue: number,
    prepareToPay: number,
    transactions: Transaction[],
    allocatedPayment: AllocationPayment[]
}

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const MakePayment: React.FC<{visible: boolean, setVisible: (visible: boolean) => void}> = ({visible = false, setVisible}) => {
    const [payment, setPayment] = React.useState<string>('');
    const [totalPay, setTotalPay] = React.useState<number>(0);
    const [checkedList, setCheckedList] = React.useState<Array<any>>([]);
    
    const [checkoutPaymentVisible, setCheckoutPaymentVisible] = React.useState<boolean>(false);
    const [iframeSrc, setIframeSrc] = React.useState<string>('');
    const [allocationToken, setAllocationToken] = React.useState<string>('');
    const [ccPaymentId, setCCPaymentId] = React.useState<string>('');
    const [goPaypal, setGoPaypal] = React.useState<boolean>(false);
    const [paypalPayment, setPaypalPayment] = React.useState<string>('');

    const [adminFee, setAdminFee] = React.useState<number>(0);

    const {userState, setAccount} = React.useContext(UserContext);

    const [dueInvoices, setDueInvoices] = React.useState<DueInvoice[]>([]);

    const handlePaymentSelect = (e: RadioChangeEvent) => {
        setPayment(e.target.value);
        setPaypalPayment('');
    }

    const onChangeCheckList = (list: CheckboxValueType[]) => {
        setCheckedList(list);
        calculateTotal(list, dueInvoices);
    }

    const onChangeTotal = (invoiceno: number) => (value: string | number | undefined) => {
        if(value) {
            const newDueInvoices = [...dueInvoices];

            newDueInvoices.forEach((invoice) => {
                if(invoice.invoiceno === invoiceno) {
                    invoice.prepareToPay = parseInt(value.toString());
                }
            })

            setDueInvoices(newDueInvoices);

            calculateTotal(checkedList, newDueInvoices);
        }
    }

    const calculateTotal = (list: CheckboxValueType[], dueInvoices: DueInvoice[]) => {
        const selected = _.filter(dueInvoices, (invoice) => {
            return list.includes(invoice.invoiceno);
        });

        if (selected.length > 0) {
            let totalPreparing = 0;
            // calculate the total payment
            selected.forEach((i) => {
                totalPreparing += i.prepareToPay;
            })

            setTotalPay(totalPreparing);
        } else {
            setTotalPay(0);
        }
    }

    const iFrameChange = () => {
        console.log(ccPaymentId);
        if (ccPaymentId !== '' && allocationToken !== '') {
            restClient.checkCCAllocationPaymentStatus(allocationToken, ccPaymentId, checkCCPaymentStatusCallback);
        }
    }

    const AccountCallback: MethodCallback<AccountMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void { },
        onSuccess(message: AccountMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if (!message.authenticated) {
                logout();
            } else {
                // save something
                context.setAccount(message);
                setCheckoutPaymentVisible(false);
                setPaypalPayment('');
                setAllocationToken('');
                setCCPaymentId('');
                setTotalPay(0);
                setCheckedList([]);
                setCheckoutPaymentVisible(false);
                setIframeSrc('');
                setVisible(false);
            }
        }
    }

    const updatePayPalAllocationPaymentCallback: MethodCallback<UpdatePayPalAllocationPaymentMessage> = {
        onFailure(error: string, context: any): void {
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: UpdatePayPalAllocationPaymentMessage, context: any): void {
            if (message.authenticated) {
                if (message.error === null || message.error === '') {
                    // updating account
                    restClient.accountFromServer(AccountCallback, { setAccount });
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
        }
    }

    const checkCCPaymentStatusCallback: MethodCallback<CheckCCAllocationPaymentMessage> = {
        onFailure(error: string, context: any): void {
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: CheckCCAllocationPaymentMessage, context: any): void {
            if (message.authenticated) {
                if (message.error === null || message.error === '') {
                    if (message.status !== 'PENDING') {
                        // updating account
                        restClient.accountFromServer(AccountCallback, { setAccount });
                        setCheckoutPaymentVisible(false);
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
        }
    }

    const allocateInvoicePaymentsCallback: MethodCallback<AllocateInvoicePaymentsMessage> = {
        onFailure(error: string, context: any): void {
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(message: AllocateInvoicePaymentsMessage, context: any): void {
            if (message.authenticated) {
                if (message.error === null || message.error === '') {
                    setAllocationToken(message.allocationToken);
                    if (message.ccPaymentId != null) {
                        setCCPaymentId(message.ccPaymentId);
                        setIframeSrc(creditCheck + '?allocationToken=' + message.allocationToken);
                        setCheckoutPaymentVisible(true);
                    } else if (message.payPalPayment != null) {
                        setGoPaypal(true);
                        setPaypalPayment(message.payPalPayment);
                    }
                } else {
                    alert(message.error);
                }
            } else {
                logout()
            }
        }
    }

    const handlePayment = () => {
        const allocatedPayment = [] as number[];
        const allocatedInvoiceIds = [] as string[];
        dueInvoices.forEach((invoice) => {
            if(checkedList.includes(invoice.invoiceno)) {
                allocatedPayment.push(invoice.prepareToPay);
                allocatedInvoiceIds.push(invoice.invoiceno.toString());
            }
        })

        restClient.allocateInvoicePayments(allocatedInvoiceIds, allocatedPayment, payment, allocateInvoicePaymentsCallback);
    }

    React.useEffect(() => {
        if(userState.account) {
            const dueInvoices = [] as DueInvoice[];
            userState.account.invoices.forEach(invoice => {
                let transactionAmount = 0;
                let allocatedPaymentAmount = 0;
                let totalDue = 0;
                const transactionsLinked = _.filter(userState.account.transactions, (transaction) => {
                    return transaction.invoiceNo === invoice.invoiceno.toString()
                })

                const allocationPaymentsLinked = _.filter(userState.account.allocationPayments, (allocatedPayment) => {
                    return allocatedPayment.invoiceNo === invoice.invoiceno.toString()
                })

                if (transactionsLinked.length > 0) {
                    transactionsLinked.forEach(transaction => {
                        transactionAmount -= transaction.paidAmount;
                    })
                }

                if(allocationPaymentsLinked.length > 0) {
                    allocationPaymentsLinked.forEach(allocatedPayment => {
                        allocatedPaymentAmount += allocatedPayment.paidAmount;
                    })
                }
                
                // check which one is higher
                // make everything positive, just in case
                totalDue = invoice.amount - Math.max(+transactionAmount, +allocatedPaymentAmount);

                if(totalDue > 0) {
                    dueInvoices.push({
                        ...invoice,
                        totalDue: totalDue,
                        transactions: transactionsLinked,
                        prepareToPay: totalDue,
                        allocatedPayment: allocationPaymentsLinked
                    })
                }
                
            });

            setDueInvoices(dueInvoices);
        }
    }, [userState.account])

    React.useEffect(() => {
        const paymentObj = PaymentMethodBean.get(payment);
        if(paymentObj) {
            setAdminFee(paymentObj.getSurchargePct());
        }
    }, [payment])

    return (
        <Drawer
          title="Make Payment"
          width='100%'
          height='100vh'
          placement='bottom'
          onClose={() => setVisible(false)}
          visible={visible}
          footer={
            <div className='paymentBtnDrawer fontSize18px'>
                <div className='textAlignLeft'>
                    <p className='text-grayColor noMarginBottom'>TOTAL</p>
                    <p className='noMarginBottom'>
                        <strong>{formatCurrency(totalPay + (totalPay * adminFee))}</strong><br/>
                        <span className='fontSize16px'>{formatCurrency(totalPay * adminFee)} admin fee</span>
                    </p>
                </div>
                {
                    (paypalPayment === '') && <Button onClick={handlePayment} type="primary">
                        Pay
                    </Button>
                }
                {
                    (goPaypal && payment === PaymentMethodBean.PAYPAL.getId() && paypalPayment !== '') && <PaypalButton intent='capture' env={envi} currency='AUD' total={paypalPayment} orderNo={allocationToken} style={{size: 'responsive', layout: 'horizontal', shape: 'rect', }} token
                        onSuccess={(data) => {
                            restClient.updatePayPalAllocationPayment(allocationToken, data.orderID, data.payerID, updatePayPalAllocationPaymentCallback);
                        }}
                        getWeborder={() => {
                            // silence
                        }}

                    />
                }
                
            </div>
          }
          className='gradientBorderBackground forPayment'>
            <Row gutter={[16, 16]} className='maxHeight380px overflowYAuto'>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Checkbox.Group className='flexColumn paymentSelect' onChange={onChangeCheckList} value={checkedList}>
                    {
                        dueInvoices.map((invoice, key) => (
                            <Row gutter={[10, 10]} key={key}>
                                <Col span={18}>
                                    <Checkbox value={invoice.invoiceno}><a target='_blank' rel="noreferrer" href={invoiceDownload + invoice.invoiceno}>{invoice.invoiceno}</a> ({formatCurrency(invoice.totalDue)})</Checkbox>
                                </Col>
                                <Col span={6}>
                                    <InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} disabled={!checkedList.includes(invoice.invoiceno)} min={0} max={invoice.totalDue} defaultValue={invoice.prepareToPay} onChange={onChangeTotal(invoice.invoiceno)} />
                                </Col>
                            </Row>
                        ))
                    }
                </Checkbox.Group>
              </Col>
            </Row>
            <Divider/>
            <Radio.Group
                options={paymentOptions}
                value={payment}
                className='verticalRadioGroup'
                onChange={handlePaymentSelect}
            />

            <Modal
                title="Payment"
                visible={checkoutPaymentVisible}
                centered
                width={600}
                onCancel={() => { }}
            >
                <iframe title='Credit Card Payment' src={iframeSrc} id='paymentIFrame' frameBorder='0' onLoad={() => iFrameChange()}></iframe>
            </Modal>
        </Drawer>
    );
}

export default MakePayment;
