import  React from  "react";
import { Route, Redirect, RouteProps } from  "react-router-dom";
import Cookies from 'universal-cookie';
import { ServiceMainRESTClient, MethodCallback, LoginMessage, InitMessage, ProductsMessage, LogoutMessage, ProductExt, RecentActivityMessage, AccountMessage, ReturnsMessage, AddressesMessage, UsersMessage, DataFeedMessage, AlertsMessage, ShoppingCartsMessage, PreferencesMessage } from '../../RESTAPI';
import { ProductContext } from '../../contexts/ProductContext';
import { AlertObj, BrandDeviceCateObject, MyUserProfile, SubObject } from "../../Constants";
import { UserContext } from "../../contexts/UserContext";
import { CartContext } from "../../contexts/CartContext";
import _ from 'lodash';

const cookies = new Cookies();
const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

export const logout = () => {
    restClient.logout(LogoutCallback);
    cookies.remove('token', {path: '/'});
    window.location.reload();
}

const ProductCallback: MethodCallback<ProductsMessage> = {
    onFailure(error: string): void {
        alert(error);
    },

    onProgress(loaded: number, total: number): void {},

    onSuccess(message: ProductsMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // state, setLoadingState, setProducts, setBrands, setCategories, setDeviceVendors, setClearance, setNew, setSpecial
            context.setProducts(message.productExts);

            let newProducts:Array<ProductExt> = [];
            let specialsProducts:Array<ProductExt> = [];
            let topSellerProducts:Array<ProductExt> = [];
            let clearanceProducts:Array<ProductExt> = [];
            let brandToProducts:Array<BrandDeviceCateObject> = [];
            let cateToProducts:Array<BrandDeviceCateObject> = [];
            let deviceVendors:Array<BrandDeviceCateObject> = [];

            const topProductIds = message.topSellerProductIds30Days;

            // loop each product to get info
            message.productExts.forEach(productExt => {
                let newDateBigDecimal: number = Date.now() - (30 * 1000 * 60 * 60 * 24);
                
                if (productExt.product.registeredDate > newDateBigDecimal) { // NEW PRODUCTS
                    newProducts.push(productExt);
                }

                if(topProductIds.includes(productExt.product.id)) {
                    topSellerProducts.push(productExt);
                }

                if (productExt.product.status === "40") { // SPECIAL
                    specialsProducts.push(productExt);
                }
                
                if (productExt.product.status === "50") { // CLEARANCE
                    clearanceProducts.push(productExt);
                }
                
                // build brand sublist
                const subObject: SubObject = {
                    name: productExt.product.itemCategory,
                    link: productExt.product.itemCategory.toLowerCase(),
                    products: [productExt]
                }

                // create new brand object
                const brandObject: BrandDeviceCateObject = {
                    name: productExt.product.vendorName,
                    link: productExt.product.vendor.toLowerCase(),
                    image: '',
                    logo: '',
                    subList: [subObject]
                }

                // build brand list
                if (brandToProducts.length === 0) {
                    brandToProducts.push(brandObject)
                } else {
                    // check if the brand is in
                    const dupBrand:Array<BrandDeviceCateObject> = brandToProducts.filter((value) => {return value.link === productExt.product.vendor.toLowerCase()});

                    if(dupBrand.length > 0) {
                        // yes, brand already in there
                        // get the brand sublist
                        const dupSubList:Array<SubObject> | undefined = dupBrand[0].subList?.filter((value) => {return value.link === productExt.product.itemCategory.toLowerCase()});

                        if(dupSubList && dupSubList.length > 0) {
                            // the sublist is already in there
                            // add the current sublist
                            dupSubList[0].products.push(productExt);
                        } else {
                            // no it is not in
                            // add it in then
                            dupBrand[0].subList?.push(subObject);
                        }
                    } else {
                        brandToProducts.push(brandObject)
                    }
                }

                // build brand sublist
                const subBrandObject: SubObject = {
                    name: productExt.product.itemCategory,
                    link: productExt.product.itemCategory.toLowerCase(),
                    products: [productExt]
                }

                // create new category object
                const cateObject: BrandDeviceCateObject = {
                    name: productExt.product.procurementGroupDesc,
                    link: productExt.product.procurementGroup.toLowerCase(),
                    image: '',
                    logo: '',
                    subList: [subBrandObject]
                }

                // build category list
                if (cateToProducts.length === 0) {
                    cateToProducts.push(cateObject)
                } else {
                    // check if the cate is in
                    const dupCate:Array<BrandDeviceCateObject> = cateToProducts.filter((value) => {return value.link === productExt.product.procurementGroup.toLowerCase()});

                    if(dupCate.length > 0) {
                        // cate already in there
                        const dupSubCateList:Array<SubObject> | undefined = dupCate[0].subList?.filter((value) => {return value.link === productExt.product.itemCategory.toLowerCase()});

                        if(dupSubCateList && dupSubCateList.length > 0) {
                            dupSubCateList[0].products.push(productExt);
                        } else {
                            dupCate[0].subList?.push(subBrandObject);
                        }
                    } else {
                        cateToProducts.push(cateObject)
                    }
                }
            });

            message.handsetItems.forEach(handsetItem => {
                let productFromIdArray = message.productExts.filter((productExt) => {return productExt.product.id === handsetItem.productid});
                let currProduct = productFromIdArray.length > 0 ? productFromIdArray[0] : null;
                
                // build device sublist
                let subObject: SubObject = {
                    name: handsetItem.handsetname,
                    link: handsetItem.handsetname.toLowerCase(),
                    products: currProduct && currProduct !== undefined ? [currProduct] : []
                }

                // create new brand object
                let handsetObject: BrandDeviceCateObject = {
                    name: handsetItem.vendorname,
                    link: handsetItem.vendorid.toLowerCase(),
                    image: '',
                    logo: '',
                    subList: [subObject]
                }
                
                // build device vendor list
                if (deviceVendors.length === 0) {
                    deviceVendors.push(handsetObject)
                } else {
                    // check if the brand is in
                    let dupVendor:Array<BrandDeviceCateObject> = deviceVendors.filter((value) => {return value.link === handsetItem.vendorid.toLowerCase()});

                    if(dupVendor.length > 0) {
                        // device already in there
                        let dupSubVendorList:Array<SubObject> | undefined = dupVendor[0].subList?.filter((value) => {return value.link === handsetItem.handsetname.toLowerCase()});

                        if(dupSubVendorList && dupSubVendorList.length > 0) {
                            if(currProduct !== null) {
                                dupSubVendorList[0].products.push(currProduct);
                            }
                        } else {
                            dupVendor[0].subList?.push(subObject);
                        }
                    } else {
                        deviceVendors.push(handsetObject)
                    }
                }
            });

            context.setClearance(clearanceProducts);
            context.setNew(newProducts);
            context.setSpecial(specialsProducts);
            context.setTopSeller(topSellerProducts);
            context.setBrands(_.sortBy(brandToProducts, [function(o) { return o.name; }]));
            context.setHighlightedBrands(message.highlightedBrands);
            context.setCategories(_.sortBy(cateToProducts, [function(o) { return o.name; }]));
            context.setDeviceVendors(_.sortBy(deviceVendors, [function(o) { return o.name; }]));
            context.setFavouriteProductId(message.favouriteProductIds);

        }
    }
}

const LoginCallback: MethodCallback<LoginMessage> = {
    onFailure(error: string, context: any): void {
        context.setFinishLoading();
        alert(error);
    },

    onProgress(loaded: number, total: number): void {},

    onSuccess(message: LoginMessage, context: any): void {
        if (message != null) {
            if (message.error == null) {
                // save token to cookie
                cookies.set('token', message.token, {path: '/', maxAge: 86400});
                myAuth.isAuthenticated = true;

                context.pushState();
            }
            else {
                context.setFinishLoading();
                if(message.error.includes('verification') || message.error.includes('staff')) {
                    context.setIsStaff(true);
                }
                alert(message.error);
            }
        }
        else {
            context.setFinishLoading();
            alert('Server not responding correctly - please try again later');
        }
    }
}

export const LogoutCallback: MethodCallback<LogoutMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: LogoutMessage): void {}
}

const InitCallback: MethodCallback<InitMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: InitMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setCurrentCustomer(message.currentCustomer); 
            context.setCurrentPayer(message.currentPayer); 
            context.setCurrentUserAccount(message.currentUserAccount);
            context.setUserAccounts(message.userAccounts);
            context.setCustomers(message.customers);
            context.setPayers(message.payers);
            context.setHomeContent(message.homeContent);

            const userProfile:MyUserProfile = {
                id: message.userId,
                login: message.login,
                alias: message.login,
                firstname: message.firstName,
                lastname: message.lastName,
                email: message.email,
                type: message.type,
                changetoken: '',
                contactphone: message.phone,
                contactfax: '',
                registered: '',
                logintokenid: '',
                activated: '',
                deactivated: '',
                skinid: 0,
                sliderid: 0,
                language: '',
                passwordhash: '',
                businessGroup: message.businessGroup
            }
            context.setUserProfile(userProfile);
        }
    }
}

const InitCallbackSwitch: MethodCallback<InitMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: InitMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setCurrentCustomer(message.currentCustomer); 
            context.setCurrentPayer(message.currentPayer); 
            context.setCurrentUserAccount(message.currentUserAccount);
            context.setUserAccounts(message.userAccounts);
            context.setCustomers(message.customers);
            context.setPayers(message.payers);
            context.setHomeContent(message.homeContent);

            const userProfile:MyUserProfile = {
                id: message.userId,
                login: message.login,
                alias: message.login,
                firstname: message.firstName,
                lastname: message.lastName,
                email: message.email,
                type: message.type,
                changetoken: '',
                contactphone: message.phone,
                contactfax: '',
                registered: '',
                logintokenid: '',
                activated: '',
                deactivated: '',
                skinid: 0,
                sliderid: 0,
                language: '',
                passwordhash: '',
                businessGroup: message.businessGroup
            }
            context.setUserProfile(userProfile);

            window.location.reload();
        }
    }
}

const RecentActivity: MethodCallback<RecentActivityMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: RecentActivityMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setRecentActivities(message.recentActivities);
        }
    }
}

const AccountCallback: MethodCallback<AccountMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: AccountMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setAccount(message);
            context.setFinishLoading();
        }
    }
}


const PreferenceCallback: MethodCallback<PreferencesMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: PreferencesMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setPreferences(message);
        }
    }
}

const ReturnCallback: MethodCallback<ReturnsMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: ReturnsMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setReturns(message);
        }
    }
}

const AddressCallback: MethodCallback<AddressesMessage> = {

    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: AddressesMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setAddresses(message.addresses);
        }
    }
}
const ManageUserCallback: MethodCallback<UsersMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: UsersMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setManagedUserAccounts(message.managedUserAccounts);
            context.setManagedUserProfiles(message.managedUserProfiles);
        }
    }
}

const DatafeedCallback: MethodCallback<DataFeedMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: DataFeedMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            context.setDatafeeds(message);
        }
    }
}

const AlertCallback: MethodCallback<AlertsMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: AlertsMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            // save something
            const alertArray:Array<AlertObj> = [];

            message.unreadAlerts.forEach(r => {
                alertArray.push({
                    ...r,
                    isRead: false
                })
            });

            message.readAlerts.forEach(r => {
                alertArray.push({
                    ...r,
                    isRead: true
                })
            });

            context.setNotifications(alertArray);
        }
    }
}

const ShoppingCartCallback: MethodCallback<ShoppingCartsMessage> = {
    onFailure(error: string): void {
        alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: ShoppingCartsMessage, context: any): void {
        // if init gives authenticated as false
        // remove cookie, logout
        if(!message.authenticated) {
            logout();
        } else {
            context.setShoppingCart(message);
        }
    }
}

export const myAuth = {
    isAuthenticated: cookies.get('token') ? true : false,
    authenticate(email: string, password: string, staffVerification: string, pushState: () => void, setFinishLoading: () => void, setIsStaff: (flag: boolean) => void) {
        restClient.loginServer(email, password, staffVerification, LoginCallback, {pushState, setFinishLoading, setIsStaff});
    },
    signout() {
        restClient.logout(LogoutCallback);
        cookies.remove('token', {path: '/'});
        window.location.reload();
    },
    switchAccount(id: string, setHomeContent: (payload: any) => void, setCurrentCustomer: (payload: any) => void, setCurrentPayer: (payload: any) => void, setCurrentUserAccount: (payload: any) => void, setUserAccounts: (payload: any) => void, setCustomers: (payload: any) => void, setPayers: (payload: any) => void, setUserProfile: (payload: any) => void ) {
        restClient.changeToAccountOnServer(id, InitCallbackSwitch, {setHomeContent, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile});

    }
};

const PrivateRoute = (props: RouteProps) => {
    const [condition] = React.useState<boolean>(myAuth.isAuthenticated);
    const {productState, setFinishLoading, setProducts, setBrands, setHighlightedBrands, setCategories, setDeviceVendors, setClearance, setNew, setSpecial, setTopSeller, setFavouriteProductId} = React.useContext(ProductContext);
    const {userState, setPreferences , setHomeContent, setNotifications ,setAddresses, setCurrentCustomer, setReturns, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setRecentActivities, setAccount, setUserProfile, setDatafeeds, setManagedUserAccounts, setManagedUserProfiles} = React.useContext(UserContext);

    const {cartState, setShoppingCart} = React.useContext(CartContext);

    React.useEffect(() => {
        if(condition) {
            restClient.initFromServer(InitCallback, {setHomeContent, setCurrentCustomer, setCurrentPayer, setCurrentUserAccount, setUserAccounts, setCustomers, setPayers, setUserProfile});

            // if there is no product
            if(productState.products.length <= 0) {
                // get product
                restClient.productsFromServer(ProductCallback, {productState, setProducts, setBrands, setHighlightedBrands , setCategories, setDeviceVendors, setClearance, setNew, setSpecial, setTopSeller, setFavouriteProductId, setShoppingCart});
            }
            
            if(userState.recentActivities === undefined) {
                // get recent activities
                restClient.recentActivitiesFromServer(RecentActivity, {setRecentActivities});
            }

            if(userState.notifications === undefined) {
                // get recent activities
                restClient.alertsFromServer(AlertCallback, {setNotifications});
            }

            if(userState.returns === undefined) {
                // get recent activities
                restClient.managedUsersFromServer(ManageUserCallback, {setManagedUserAccounts, setManagedUserProfiles});
            }
            
            if(userState.addresses === undefined) {
                // get recent activities
                restClient.addressesFromServer(AddressCallback, {setAddresses});
            }

            if(userState.dataFeeds === undefined) {
                // get recent activities
                restClient.dataFeedDetailsFromServer(DatafeedCallback, {setDatafeeds});
            }

            if(userState.returns === undefined) {
                // get recent activities
                restClient.returnsFromServer(ReturnCallback, {setReturns});
            }

            if(cartState.shoppingCart === undefined) {
                restClient.shoppingCartsFromServer(ShoppingCartCallback, {setShoppingCart})
            }

            if (userState.account === undefined) {
                // get recent activities
                restClient.accountFromServer(AccountCallback, { setAccount, setFinishLoading });
            }

            if (userState.preferences === undefined) {
                // get preference
                restClient.preferencesFromServer(PreferenceCallback, {setPreferences});
            }
        }
    }, [condition])
    
    return  condition ? (<Route path={props.path} exact={props.exact} component={props.component}/>) : 
        (<Redirect
            to={{
                pathname: "/login",
                state: { from: props.path }
            }}
        />);
};
export default PrivateRoute;