import React from 'react';
import { Row, Col, List, Skeleton, Button} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import {PlusOutlined} from '@ant-design/icons';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import AddNewUser from '../../../components/AddNewUser';
import { UserContext } from '../../../contexts/UserContext';
import { UserAccess, UserAccountExt, UserProfile } from '../../../RESTAPI';
import { Redirect } from 'react-router-dom';
import { UserAccountObj } from '../../../Constants';
import { paginate } from '../../../utils';
import _ from 'lodash';

const pageSize = 5;

const ManageUsers: React.FC = () => {
    const [ initLoading ] = React.useState<boolean>(false);
    const [ visible, setVisible] = React.useState<boolean>(false);
    const [ visibleAddNew, setVisibleAddNew] = React.useState<boolean>(false);
    const [ currentPage, setCurrentPage] = React.useState<number>(1);
    const [ maxPage, setMaxPage] = React.useState<number>(1);
    const [ loading, setLoading ] = React.useState<boolean>(false);
    const [ list, setList] = React.useState<Array<UserAccountObj>>([]);
    const [ users, setUsers ] = React.useState<Array<UserAccountExt>>([]);
    const [ userProfiles, setUserProfiles ] = React.useState<Array<UserProfile>>([]);
    const [ currentUser, setCurrentUser ] = React.useState<UserAccountObj>();
    const [ currentUserProfile, setCurrentUserProfile ] = React.useState<UserProfile>();

    const {userState} = React.useContext(UserContext);

    React.useEffect(() => {
        if(userState.managedUserAccounts) {
            setUsers(userState.managedUserAccounts);
            setUserProfiles(userState.managedUserProfiles);
            setList(paginate(userState.managedUserAccounts, pageSize, 1));
            setMaxPage(Math.ceil(userState.managedUserAccounts.length / 5));
        }

    }, [userState.managedUserAccounts, userState.managedUserProfiles])

    const loadMore =
      !initLoading && !loading && currentPage < maxPage ? (
        <div className='textCenter marginTop40px'>
          <Button onClick={() => onLoadMore()} type='primary'>Load More</Button>
        </div>
      ) : null;

      const onLoadMore = () => {
        const nextPage = currentPage + 1;
        const nextList = paginate(users, pageSize, nextPage);
        setLoading(true);
        setCurrentPage(currentPage + 1);

        const currentList = [...list];
        for (let index = 0; index < nextList.length; index++) {
            currentList.push({
                ...nextList[index],
                loading: true
            }); 
        }
        setList([...currentList]);
        
        setTimeout(() => {
            const current = [...currentList];
            const newList:Array<UserAccountObj> = [];

            current.forEach((value, key) => {
                value.loading = false;
                newList.push(value);
            })

            setList([...newList]);
            setLoading(false);
        }, 50)
    };

    if(userState.currentUserAccount && userState.currentUserAccount.permissions.split('').includes(UserAccess.ADD_NEW_USERS)) {
        return (
            <MainLayout>
                <div className='content-wrapper marginBottom40px marginTop40px'> 
                    <Row gutter={20}>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter'>Manage Users</h2>
                            <MobileAccountMenu site='Manage Users'/>
                            <div className='accountSection fontSize16px mobileMarginBottom20px' style={{maxWidth: '775px', margin: '0 auto'}}>
                                <div className='accountSectionContainer'>  
                                    <List
                                        className="fontSize16px"
                                        loading={initLoading}
                                        itemLayout="horizontal"
                                        loadMore={loadMore}
                                        dataSource={list}
                                        renderItem={item => {
                                            let userProfile = _.find(userProfiles, (u) => {
                                                return u.id === item.userid
                                            })

                                            if(userProfile) {
                                                return (
                                                    <List.Item
                                                        actions={[]}
                                                    >
                                                        <Skeleton avatar title={false} loading={item.loading} active>
                                                        <List.Item.Meta
                                                            title={<p className='noMarginBottom'><strong>{userProfile.firstname + ' ' + userProfile.lastname}</strong>
                                                            <br/>{userProfile.email}</p>}
                                                        />
                                                            <div>
                                                                <Button className='text-secondaryColor' type='link' onClick={() => {setVisible(true); setCurrentUser(item); setCurrentUserProfile(userProfile)}}><strong>Edit</strong></Button>
                                                            </div>
                                                            {/*<div>
                                                                <Button className='text-secondaryColor' type='link'><strong>Remove</strong></Button>
                                                            </div>*/}
                                                        </Skeleton>
                                                    </List.Item>
                                                )
                                            } else {
                                                return null
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            
                            <Row style={{maxWidth: '775px', margin: '20px auto'}}>
                                <Col span={24}>
                                    <Button type="dashed" block className='addNewAddress' onClick={() => {setCurrentUser(undefined); setCurrentUserProfile(undefined); setVisibleAddNew(true)}}>
                                        <PlusOutlined /><br/>
                                        <strong>Add User</strong>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <AddNewUser visible={visible} currentUser={currentUser} currentUserProfile={currentUserProfile} setVisible={(flag) => setVisible(flag)}/>
                <AddNewUser visible={visibleAddNew} setVisible={(flag) => setVisibleAddNew(flag)}/>
            </MainLayout>
        );
    } else {
        return <Redirect
            to={{
                pathname: "/"
            }}
        />
    }
}

export default ManageUsers;
