import React from 'react';
import './App.less';
import Routes from './Routes';
import CartContextProvider from './contexts/CartContext';
import ProductContextProvider from './contexts/ProductContext';
import UserContextProvider from './contexts/UserContext';

function App() {
  return (
    <div className="App">
      <UserContextProvider>
        <CartContextProvider>
          <ProductContextProvider>
            <Routes />
          </ProductContextProvider>
        </CartContextProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;
