import React from 'react';
import FavouriteIcon from '../assets/images/favourites.svg';
import { ProductContext } from '../contexts/ProductContext';
import IconAnimate from '../elements/IconAnimate/IconAnimate';
import { FavouritesMessage, MethodCallback, ServiceMainRESTClient } from '../RESTAPI';

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const FavouriteButton: React.FC<{className?: string, id?: string, favourite: boolean}> = ({className, id, favourite}) => {
    const {addFavouriteProductId, removeFavouriteProductId} = React.useContext(ProductContext);

    const handleSetFavourite = () => {
        if(id) {
            if(favourite) { // get current favourite
                // reverse from true to false
                restClient.deleteFavouriteProductId(id, DeleteFavouriteCallBack, {removeFavouriteProductId});
            } else {
                // reverse from false to true
                restClient.addFavouriteProductId(id, AddFavouriteCallBack, {addFavouriteProductId});
            }
        }
    }

    const AddFavouriteCallBack: MethodCallback<FavouritesMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: FavouritesMessage, context: any): void {
            context.addFavouriteProductId(id);
        }
    }

    const DeleteFavouriteCallBack: MethodCallback<FavouritesMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: FavouritesMessage, context: any): void {
            context.removeFavouriteProductId(id);
        }
    }

    return <IconAnimate custom onClick={handleSetFavourite} imageSrc={FavouriteIcon} className={'leftMinus14px favouriteIcon ' + className + (favourite ? ' active': '')}/>
}

export default FavouriteButton;