import React from 'react';
import { Select, Divider, Image, Tag, Button, Drawer, TreeSelect} from 'antd';
import FilterSelect from '../elements/FilterSelect/FilterSelect';
import { blankFilter, FilterObject } from '../Constants';
import { ProductContext } from '../contexts/ProductContext';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { formatText, getImage } from '../utils';

const { Option } = Select;
const { TreeNode } = TreeSelect;

const FilterBar: React.FC<{handleFilterList: (filterList: FilterObject) => void, handleSorting: (value: string) => void}> = ({handleFilterList, handleSorting}) => {
    const [filterList, setFilterList] = React.useState<FilterObject>(blankFilter);
    const [filterDrawer, setFilterDrawer] = React.useState<boolean>(false);
    const [totalFilter, setTotalFilter] = React.useState<Array<string>>([]);

    const {productState} = React.useContext(ProductContext);
    const {cateId, subId} = useParams<{cateId: string, subId: string, }>();
    const location = useLocation();

    const cateChildren = productState.categories.map((cate, key) => {
        return (
            <TreeNode value={cate.name} title={cate.name} key={cate.name} isLeaf={false}>
                {
                    cate.subList?.map((subCate, k) => {
                        return (
                            <TreeNode value={cate.name + ' · ' + subCate.name} title={subCate.name} key={cate.name + ' · ' + subCate.name} isLeaf/>
                        )
                    })
                }
            </TreeNode>
        )
    });

    const deviceChildren = productState.deviceVendors.map((device, key) => {
        return (
            <TreeNode value={device.name} title={device.name} key={device.name} isLeaf={false}>
                {
                    device.subList?.map((subCate, k) => {
                        return (
                            <TreeNode value={device.name + ' · ' + subCate.name} title={subCate.name} key={device.name + ' · ' + subCate.name} isLeaf/>
                        )
                    })
                }
            </TreeNode>
        )
    });
    

    const brandChildren = productState.brands.map((brand, key) => {
        return (
            <Option className='brandSelect' value={brand.name} key={key}>
                <Image preview={false} src={getImage(brand.name, 'brands', '200x200')}/>
            </Option>
        )
    });

    const filterMap = (type: string) => {
        return filterList[type.toLowerCase() as keyof FilterObject].map((filter, key) => {
            return (
                <Tag closable key={key} onClose={(e) => {e.preventDefault(); handleFilterDelete(filter, type)}}>{filter}</Tag>
            )
        })
    } 

    const handleUpdateFilter = (value: Array<string>, type: string) => {
        let tempList = {...filterList};
        tempList[type.toLowerCase() as keyof FilterObject] = value;

        setFilterList(tempList);
    }

    const handleFilterDelete = (filter: string, type: string) => {
        let tempList = {...filterList};
        let currentArray = filterList[type.toLowerCase() as keyof FilterObject];
        tempList[type.toLowerCase() as keyof FilterObject] = [];
        
        // something happened which made me do this. srs
        currentArray.forEach((value, i) => {
            if(value !== filter) {
                tempList[type.toLowerCase() as keyof FilterObject].push(value);
            }
        })

        setFilterList(tempList);
    }

    const handleOnClose = () => {
        setFilterDrawer(false);
    }

    const onChangeSorting = (value: any) => {
        handleSorting(value);
    }

    React.useEffect(() => {
        if(cateId) {
            if(location.pathname.includes('brands')) {
                const obj = _.find(productState.brands, (o) => {
                    return formatText(o.link) === cateId;
                })

                if(obj) {
                    filterList.categories = [];
                    filterList.brands = [obj.name];
                    filterList.devices = [];
                }
            } else if(location.pathname.includes('categories')) {
                const obj = _.find(productState.categories, (o) => {
                    return formatText(o.link) === cateId;
                })

                if(obj) {
                    if(subId) {
                        const subList = _.find(obj?.subList, (list) => {
                            return formatText(list.link) === subId;
                        })

                        if(subList) {
                            filterList.categories = [obj.name + ' · ' + subList.name];
                        } else {
                            filterList.categories = [obj.name];
                        }
                    }
                    
                    filterList.brands = [];
                    filterList.devices = [];
                }
            } else if(location.pathname.includes('device-vendors')) {
                const obj = _.find(productState.deviceVendors, (o) => {
                    return formatText(o.link) === cateId;
                })

                if(obj) {
                    if(subId) {
                        const subList = _.find(obj?.subList, (list) => {
                            return formatText(list.link) === subId;
                        })

                        if(subList) {
                            filterList.devices = [obj.name + ' · ' + subList.name];
                        } else {
                            filterList.devices = [obj.name];
                        }
                    }

                    filterList.categories = [];
                    filterList.brands = [];
                }
            }
        } else {
            filterList.categories = [];
            filterList.brands = [];
            filterList.devices = [];
        }
        
        setFilterList(filterList);

    }, [cateId, location, productState.brands, productState.categories, productState.deviceVendors])

    React.useEffect(() => {
        handleFilterList(filterList);
        
        let blankArray: Array<string> = [];
        filterList.brands.forEach((value) => {
            blankArray.push(value);
        })

        filterList.categories.forEach((value) => {
            blankArray.push(value);
        })

        filterList.devices.forEach((value) => {
            blankArray.push(value);
        })

        setTotalFilter(blankArray);

    }, [filterList])

    return (
        <div className='filterBar'>
            <div className='filter onlyDesktop'>
                <FilterSelect isTreeSelect={false} selected={filterList.brands} virtual={false} dropdownClassName='brandDropdown' placeholder='Brands' handleUpdateFilter={handleUpdateFilter}>
                    {brandChildren}
                </FilterSelect>

                <FilterSelect isTreeSelect virtual={true} selected={filterList.categories} dropdownClassName='filterDropdown' placeholder='Categories' handleUpdateFilter={handleUpdateFilter}>
                    {cateChildren}
                </FilterSelect>

                <FilterSelect isTreeSelect virtual={true} selected={filterList.devices} dropdownClassName='filterDropdown' placeholder='Devices' handleUpdateFilter={handleUpdateFilter}>
                    {deviceChildren}
                </FilterSelect>
            </div>
            <div className='filter onlyMobile'>
                <FilterSelect isTreeSelect={false} setFilterDrawer={(flag: boolean) => setFilterDrawer(flag)} disableClick selected={totalFilter} virtual={false} dropdownClassName='' placeholder='Filter By' handleUpdateFilter={handleUpdateFilter}/>

                <Drawer
                    title="Filter By"
                    placement="right"
                    closable={true}
                    visible={filterDrawer}
                    className='filterBar filterBarDrawer'
                    onClose={handleOnClose}
                >
                    <div className='filter'>
                        <FilterSelect isTreeSelect={false} selected={filterList.brands} virtual={false} dropdownClassName='brandDropdown' placeholder='Brands' handleUpdateFilter={handleUpdateFilter}>
                                {brandChildren}
                        </FilterSelect>

                        <FilterSelect isTreeSelect virtual={false} selected={filterList.categories} dropdownClassName='filterDropdown' placeholder='Categories' handleUpdateFilter={handleUpdateFilter}>
                            {cateChildren}
                        </FilterSelect>

                        <FilterSelect isTreeSelect virtual={false} selected={filterList.devices} dropdownClassName='filterDropdown' placeholder='Devices' handleUpdateFilter={handleUpdateFilter}>
                            {deviceChildren}
                        </FilterSelect>
                    </div>
                </Drawer>
            </div>
            <div className='filterSort'>
                <Select placeholder='Sorted By' className='filterSelect' onChange={onChangeSorting}>
                    <Option value="price-asc">Price Ascending</Option>
                    <Option value="price-desc">Price Descending</Option>
                    <Option value="mag-asc">Margin Ascending</Option>
                    <Option value="mag-desc">Margin Descending</Option>
                    <Option value="name-asc">Name Ascending</Option>
                    <Option value="name-desc">Name Descending</Option>
                    <Option value="date-asc">Date Ascending</Option>
                    <Option value="date-desc">Date Descending</Option>
                </Select>
            </div>

            <Divider/>

            <div className='filterDashboard'>
                {filterMap('brands')}
                {filterMap('categories')}
                {filterMap('devices')}
                {
                    (filterList.brands.length > 0 || filterList.categories.length > 0 || filterList.devices.length > 0) && <Button onClick={() => setFilterList(blankFilter)} className='clearAllBtn' type="text">
                        Clear All
                    </Button>
                }
            </div>
        </div>
    );
}

export default FilterBar;
