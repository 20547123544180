import React from 'react';
import { Row, Col, Skeleton, List, Button, Divider, Avatar} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { formatCurrency } from '../../../utils';
import { InboxOutlined } from '@ant-design/icons';
import MakePayment from '../../../components/MakePayment';
import { UserContext } from '../../../contexts/UserContext';
import { ClientHelper} from '../../../RESTAPI';

const Payment: React.FC = () => {
    //const [ initLoading ] = React.useState<boolean>(false);
    //const [ loading, setLoading ] = React.useState<boolean>(false);
    //const [ list] = React.useState<Array<any>>(orderList);
    const [visible, setVisible] = React.useState<boolean>(false);

    const [creditLimit, setCreditLimit] = React.useState<number>(0);
    const [totalDue, setTotalDue] = React.useState<number>(0);
    const [balance30Days, setBalance30Days] = React.useState<number>(0);
    const [balance60Days, setBalance60Days] = React.useState<number>(0);
    const [balance90Days, setBalance90Days] = React.useState<number>(0);
    const [balance120Days, setBalance120Days] = React.useState<number>(0);
    const [balanceCurrent, setBalanceCurrent] = React.useState<number>(0);

    const [overdue, setOverdue] = React.useState<number>(0);

    const {userState} = React.useContext(UserContext);

    /*const loadMore =
      !initLoading && !loading ? (
        <div className='textCenter marginTop40px'>
          <Button onClick={() => onLoadMore()} type='primary'>Load More</Button>
        </div>
      ) : null;*/

    /*const onLoadMore = () => {
        setLoading(true);
        const currentList = [...list];
        for (let index = 0; index < orderList.length; index++) {
            currentList.push({
                date: '09/11/2020',
                name: '#300219535',
                loading: true
            }); 
        }
        setList([...currentList]);
        
        setTimeout(() => {
            const current = [...currentList];
            const newList:Array<any> = [];

            current.forEach((value, key) => {
                value.loading = false;
                newList.push(value);
            })

            setList([...newList]);
            setLoading(false);
        }, 500)
    };*/

    React.useEffect(() => {
        if (userState.currentPayer && userState.account && userState.currentCustomer) {
            setCreditLimit(ClientHelper.getCreditLimit(userState.currentCustomer, userState.currentPayer));
            setTotalDue(userState.account.creditSummary.totalDue);
            setBalance30Days(userState.account.creditSummary.balance30Days);
            setBalance60Days(userState.account.creditSummary.balance60Days);
            setBalance90Days(userState.account.creditSummary.balance90Days);
            setBalance120Days(userState.account.creditSummary.balance120Days);
            setBalanceCurrent(userState.account.creditSummary.balanceCurrent);

            setOverdue(userState.account.creditSummary.overdue);
        }
    }, [userState.account, userState.currentPayer, userState.currentCustomer])

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20}>
                    <Col span={24}>
                        <h2 className='headerTitle textCenter'>Payments</h2>
                        <MobileAccountMenu site='Payment'/>
                        <div className='accountSection fontSize16px mobileMarginBottom20px' style={{maxWidth: '775px', margin: '0 auto'}}>
                            <Row gutter={30}>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <p className='noMarginBottom'>Current Balances</p>
                                    <p className='fontSize28px noMarginBottom textAlignRight'><strong>{formatCurrency(balanceCurrent)}</strong></p>
                                    <div className='flexSpaceBetween'>
                                        <p className='noMarginBottom'>30 Days:</p>
                                        <p className='noMarginBottom'>{formatCurrency(balance30Days)}</p>
                                    </div>
                                    <div className='flexSpaceBetween'>
                                        <p className='noMarginBottom'>60 Days:</p>
                                        <p className='noMarginBottom'>{formatCurrency(balance60Days)}</p>
                                    </div>
                                    <div className='flexSpaceBetween'>
                                        <p className='noMarginBottom'>90 Days:</p>
                                        <p className='noMarginBottom'>{formatCurrency(balance90Days)}</p>
                                    </div> 
                                    <div className='flexSpaceBetween'>
                                        <p className='noMarginBottom'>120 Days:</p>
                                        <p className='noMarginBottom'>{formatCurrency(balance120Days)}</p>
                                    </div>
                                    <div className='flexSpaceBetween'>
                                        <p><strong>Total Due:</strong></p>
                                        <p>{formatCurrency(totalDue)}</p>
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={12} xl={12}>
                                    <p className='noMarginBottom'>Current Credit Details</p>
                                    <p className='fontSize28px noMarginBottom textAlignRight'><strong>{formatCurrency(creditLimit - totalDue)}</strong></p>
                                    <div className='flexSpaceBetween'>
                                        <p className='noMarginBottom'>Credit Limit:</p>
                                        <p className='noMarginBottom'>{formatCurrency(creditLimit)}</p>
                                    </div>
                                    <div className='flexSpaceBetween'>
                                        <p>Overdue:</p>
                                        <p>{formatCurrency(overdue)}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={30}>
                                <Col xs={0} sm={0} md={12} xl={12}/>
                                <Col xs={24} sm={24} md={12} xl={12} className='desktopVerticalCenterRight'>
                                    <Button type='primary' onClick={() => setVisible(true)}>Make Payment</Button>
                                </Col>
                            </Row>
                            {/*<div className='accountSectionContainer'>
                                <p><strong>Wed 18 Nov</strong> yesterday</p>
                                <List
                                    className="fontSize16px noBorder"
                                    loading={initLoading}
                                    itemLayout="horizontal"
                                    bordered={false}
                                    //loadMore={loadMore}
                                    dataSource={list}
                                    renderItem={item => (
                                    <List.Item
                                        actions={[]}
                                    >
                                        <Skeleton avatar title={false} loading={item.loading} active>
                                        <List.Item.Meta
                                            avatar={<Avatar icon={<InboxOutlined />} />}
                                            title={<p className='noMarginBottom'><strong>1065804041:</strong> warr0546 110022958</p>}
                                        />
                                            <div>
                                                <strong>{formatCurrency(-127.91)}</strong>
                                            </div>
                                        </Skeleton>
                                    </List.Item>
                                    )}
                                />

                                <Divider/>
                                <p><strong>Wed 8 Nov</strong> 10 days ago</p>
                                <List
                                    className="fontSize16px noBorder"
                                    loading={initLoading}
                                    itemLayout="horizontal"
                                    //loadMore={loadMore}
                                    bordered={false}
                                    dataSource={list}
                                    renderItem={item => (
                                    <List.Item
                                        actions={[]}
                                    >
                                        <Skeleton avatar title={false} loading={item.loading} active>
                                        <List.Item.Meta
                                            avatar={<Avatar icon={<InboxOutlined />} />}
                                            title={<p className='noMarginBottom'><strong>1065804041:</strong> warr0546 110022958</p>}
                                        />
                                            <div>
                                                <strong>{formatCurrency(-592.48)}</strong>
                                            </div>
                                        </Skeleton>
                                    </List.Item>
                                    )}
                                />
                            </div>*/}
                        </div>
                    </Col>
                </Row>
            </div>
            <MakePayment visible={visible} setVisible={(flag) => setVisible(flag)}/>
        </MainLayout>
    );
}

export default Payment;
