import React from 'react';
import { Row, Col, Divider, Image, Popover, Button, message } from 'antd';
import { dateStringFormat, formatCurrency, totalQty, useWindowSize } from '../../../utils';
import { imageUrl, invoiceDownload, orderNoStatus, tabletSize } from '../../../Constants';
import { Link, useParams, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { DownOutlined } from '@ant-design/icons';
import { Invoice, Order, OrderLine, TrackingDataExt, ServiceMainRESTClient, RecentActivityMessage, MethodCallback, CancelBackOrderMessage, AccountMessage, OrderStatusBean } from '../../../RESTAPI';
import { ProductContext } from '../../../contexts/ProductContext';
import _ from 'lodash';
import { UserContext } from '../../../contexts/UserContext';
import AddToCart from '../../../components/AddToCart';
import MainLayout from '../../../layouts/MainLayout'; 
import { logout } from '../../../elements/PrivateRoute/PrivateRoute';

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const SingleBackOrder: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const [order, setOrder] = React.useState<Order>();
    const [orderLine, setOrderline] = React.useState<OrderLine[]>([]);
    const [address, setAddress] = React.useState<string>();
    const [invoice, setInvoice] = React.useState<Invoice>(); // should have invoice donwload here
    const [trackingData, setTrackingData] = React.useState<TrackingDataExt[]>(); // should have invoice donwload here
    const size = useWindowSize();

    const {orderno} = useParams<{orderno: string}>();

    const { productState } = React.useContext(ProductContext);
    const { userState, setAccount, setRecentActivities } = React.useContext(UserContext);

    const [deleteVisible, setDeleteVisible] = React.useState<string>('');
    const [deleteVisibleProduct, setDeleteVisibleProduct] = React.useState<string>('');

    const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void { },
        onSuccess(returnMessage: RecentActivityMessage, context?: any): void {
            if (!returnMessage.authenticated) {
                logout();
            } else {
                setRecentActivities(returnMessage.recentActivities);
            }
        }
    }

    const CancelBackOrderCallback: MethodCallback<CancelBackOrderMessage> = {
        onFailure(error: string): void {
            message.error({ content: error, key: 'updatable', duration: 6 });
        },
        onProgress(loaded: number, total: number): void { },
        onSuccess(returnMessage: CancelBackOrderMessage): void {
            if (!returnMessage.authenticated) {
                logout();
            } else {
                if(returnMessage.error) {
                    message.error({ content: returnMessage.error, key: 'updatable', duration: 6 });
                } else {
                    // call account to update
                    restClient.accountFromServer(AccountCallback);
                }
                
            }
        }
    }

    const AccountCallback: MethodCallback<AccountMessage> = {
        onFailure(error: string): void {
            message.error({ content: error, key: 'updatable', duration: 6 });
        },
        onProgress(loaded: number, total: number): void { },
        onSuccess(returnMessage: AccountMessage): void {
            if (!returnMessage.authenticated) {
                logout();
            } else {
                setAccount(returnMessage)
                message.success({ content: 'Cancelled successfully!', key: 'updatable', duration: 2 });
            }
        }
    }

    React.useEffect(() => {
        if (order) {
            const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {
                onFailure(error: string): void {
                    alert(error);
                },
                onProgress(loaded: number, total: number): void { },
                onSuccess(returnMessage: RecentActivityMessage, context?: any): void {
                    if (!returnMessage.authenticated) {
                        logout();
                    } else {
                        setRecentActivities(returnMessage.recentActivities);
                    }
                }
            }

            if (parseInt(order.lowstatus) >= 22 && parseInt(order.lowstatus) <= 27 ) {
                restClient.addActivityViewBackOrder(order.orderno, RecentActivityCallback);
            } else {
                restClient.addActivityViewOrder(order.orderno, RecentActivityCallback);
            }
            
        }
    }, [order])

    React.useEffect(() => {
        // get order
        if(userState.account) {
            const currentOrder = _.filter(userState.account.orders, (o) => {
                return o.orderno === orderno;
            });

            if(currentOrder.length > 0) {
                const address = userState.account.addressIdToDeliveryAddress[currentOrder[0].deliveryaddressid];
                setOrder(currentOrder[0]);
                setOrderline(userState.account.orderNoToOrderLines[currentOrder[0].orderno]);       

                if(address) {
                    // setting up address
                    let addressLine = '';
                    address?.lines.forEach((line, k) => {
                        if(k === 0) {
                            addressLine += line;
                        } else {
                            addressLine += ' ' + line;
                        }
                    })
                    setAddress(addressLine.trim() + ', ' + (address.companyName ? address.companyName : '') + ', ' + address.suburb + ', ' + address.state + ' ' + address.postcode);
                }

                const invoice = _.find(userState.account.invoices, (i) => {
                    return i.orderno === currentOrder[0].orderno;
                });

                setInvoice(invoice);

                if(userState.account.shipmentNoToTrackingDatas) {
                    if(invoice?.dlvindex) {
                        setTrackingData(userState.account.shipmentNoToTrackingDatas[invoice.dlvindex]);
                    }
                } 
            }  
        }
    }, [userState.account, orderno])

    if(orderno === undefined || orderno === null || orderno === ':orderno') {
        return (<Redirect
            to={{
                pathname: '/'
            }}
        />)
    }

    const handleCancelBackorder = (orderno: string, orderLine: OrderLine[] | undefined) => {
        setDeleteVisible('');
        setDeleteVisibleProduct(''); 
        if(orderLine) {
            const orderLineIds = [] as number[];

            orderLine.forEach(line => {
                orderLineIds.push(line.id);
            });

            message.loading({ content: 'Cancelling order...', key: 'updatable' });
            restClient.cancelBackOrder(orderno, orderLineIds, CancelBackOrderCallback)
        } else {
            alert('Something is wrong with this order. Please try again later.')
        }
        
    }
    

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'>
                <h2 className='headerTitle onlyDesktop'>Order Details: {orderno}</h2>
                <h2 className='headerTitle onlyMobile textCenter'>Order Details: {orderno}</h2>
                <div className='orderContainer' style={{padding: '20px'}}>
                    {
                        order && (
                            <>
                            <p className='noMarginBottom text-secondaryColor'><strong>{orderNoStatus[order.lowstatus]}</strong></p>
                            <Row gutter={20}>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <p>
                                        {dateStringFormat(order.orderdate)} <br/>
                                        <strong>{order.orderno}</strong>
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <p>
                                        Total <br/>
                                        <p><strong>{formatCurrency(order.baseprice)} @ {totalQty(orderLine)}</strong></p>
                                    </p>
                                </Col>
                                <Col xs={0} sm={0} md={16} lg={16} xl={16}>
                                    <div className='flexSpaceBetween'>
                                        <div>
                                            <p>
                                                Shipping <br/>
                                                <strong>{address}</strong>
                                            </p>
                                        </div>
                                        <div style={{minWidth: '145px'}}>
                                            {
                                                invoice && <a href={invoiceDownload + invoice.invoiceno} onClick={() => { restClient.addActivityViewInvoice(invoice.invoiceno.toString(), RecentActivityCallback)}} target='_blank' rel='noreferrer noopener' className='linkUnderline'>See Invoice</a>
                                            }
                                            
                                            {
                                                (parseInt(order.lowstatus) > 20 && parseInt(order.lowstatus) < 30) ? 
                                                <Popover
                                                overlayClassName='zIndex3'
                                                content={
                                                    <div className='flexSpaceBetween'>
                                                        <a href='/' onClick={(e) => {e.preventDefault(); handleCancelBackorder(order.orderno, orderLine) }}>Yes</a>
                                                        <a href='/' onClick={(e) => {e.preventDefault(); setDeleteVisible('') }}>No</a>
                                                    </div>
                                                }
                                                title="Are you sure to cancel this backorder?"
                                                trigger="click"
                                                visible={deleteVisible === order.orderno}
                                                >
                                                    <Link to='/' onClick={(e) => {e.preventDefault();setDeleteVisibleProduct('');setDeleteVisible('');setDeleteVisible(order.orderno)}} className='marginLeft20px linkUnderline floatRight'>Cancel Backorder</Link>
                                                </Popover>
                                                    : 
                                                <Link to='/your-account/new-return' className='marginLeft20px linkUnderline floatRight'>Return</Link>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <DownOutlined className='animation collapseButton onlyMobile marginTop20px' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180: 0} />
                            <Divider className='onlyDesktop'/>
                            <AnimatePresence initial={false}>
                                {(isOpen || size.width >= tabletSize) && (
                                <motion.section
                                    key="content"
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    {
                                        orderLine && orderLine.map((line, key) => {
                                            return (<Row gutter={15} key={key} className='alignItemCenter rowOrderLine'>
                                                <Col xs={5} sm={5} md={2} lg={2} xl={2}>
                                                    <Image preview={false} src={imageUrl + _.find(productState.products, (p) => {return line.itemno === p.product.id})?.product.image}/>
                                                </Col>
                                                <Col xs={16} sm={16} md={14} lg={14} xl={14}>
                                                    <p className='noMarginBottom'><strong>{line.itemname}</strong></p>
                                                    <p className='noMarginBottom'>{line.itemdesc}</p>
                                                    <p className='noMarginBottom text-grayColor'><strong>{line.orderedqty} pc @ {formatCurrency(line.unitprice)}</strong></p>
                                                    <div className='onlyMobile'>
                                                        {
                                                            (parseInt(line.status) > 20 && parseInt(line.status) < 30) ? 
                                                            <Popover
                                                            overlayClassName='zIndex3'
                                                            content={
                                                                <div className='flexSpaceBetween'>
                                                                    <a href='/' onClick={(e) => {e.preventDefault(); handleCancelBackorder(order.orderno, [line]) }}>Yes</a>
                                                                    <a href='/' onClick={(e) => {e.preventDefault(); setDeleteVisibleProduct('') }}>No</a>
                                                                </div>
                                                            }
                                                            title="Are you sure to cancel this backorder product?"
                                                            trigger="click"
                                                            visible={deleteVisibleProduct === line.id.toString()}
                                                            >
                                                                <Button block htmlType="button" style={{ padding: 0, height: 'auto' }} type='link' onClick={(e) => {e.preventDefault();setDeleteVisibleProduct('');setDeleteVisible('');setDeleteVisibleProduct(line.id.toString())}}>Cancel Product</Button>
                                                            </Popover>
                                                            : <AddToCart type='link' title='Reorder' qty={line.orderedqty} product={_.find(productState.products, (p) => {
                                                                return p.product.id === line.itemno;
                                                            })}/>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col xs={3} sm={3} md={8} lg={4} xl={4}>
                                                    {OrderStatusBean.get(line.status).getName()}
                                                </Col>
                                                <Col xs={0} sm={0} md={0} lg={4} xl={4}>
                                                    {
                                                        (parseInt(line.status) > 20 && parseInt(line.status) < 30) ? 
                                                        <Popover
                                                        overlayClassName='zIndex3'
                                                        content={
                                                            <div className='flexSpaceBetween'>
                                                                <a href='/' onClick={(e) => {e.preventDefault(); handleCancelBackorder(order.orderno, [line]) }}>Yes</a>
                                                                <a href='/' onClick={(e) => {e.preventDefault(); setDeleteVisibleProduct('') }}>No</a>
                                                            </div>
                                                        }
                                                        title="Are you sure to cancel this backorder?"
                                                        trigger="click"
                                                        visible={deleteVisibleProduct === line.id.toString()}
                                                        >
                                                            <Button block htmlType="button" type='primary' onClick={(e) => {e.preventDefault();setDeleteVisibleProduct('');setDeleteVisible('');setDeleteVisibleProduct(line.id.toString())}}>Cancel Product</Button>
                                                        </Popover>
                                                        : <AddToCart title='Reorder' qty={line.orderedqty} product={_.find(productState.products, (p) => {
                                                            return p.product.id === line.itemno;
                                                        })}/>
                                                    }
                                                </Col>
                                            </Row>)
                                        })
                                    }
                                    <p>
                                        <strong>Shipping</strong> <br/>
                                        {address}
                                    </p>
                                    {
                                        trackingData && (
                                            <>
                                            <strong>Tracking Data</strong> <br/>
                                            {
                                                trackingData.map((value, key) => {
                                                    return <p key={key}>
                                                        <a href={trackingData[0].url} target='_blank' rel='noreferrer'>{value.trackingData.carrier} - {value.trackingData.connote}</a>
                                                    </p>
                                                })
                                            }
                                            
                                            </>
                                        )
                                    }
                                    
                                    <div className='onlyMobile'>
                                        {
                                            invoice && <a href={invoiceDownload + invoice.invoiceno} onClick={() => { restClient.addActivityViewInvoice(invoice.invoiceno.toString(), RecentActivityCallback)}} target='_blank' rel='noreferrer noopener' className='linkUnderline marginRight20px'>See Invoice</a>
                                        }
                                        {
                                            (parseInt(order.lowstatus) > 20 && parseInt(order.lowstatus) < 30) ? 
                                            <Popover
                                            overlayClassName='zIndex3'
                                            content={
                                                <div className='flexSpaceBetween'>
                                                    <a href='/' onClick={(e) => {e.preventDefault(); handleCancelBackorder(order.orderno, orderLine) }}>Yes</a>
                                                    <a href='/' onClick={(e) => {e.preventDefault(); setDeleteVisible('') }}>No</a>
                                                </div>
                                            }
                                            title="Are you sure to cancel this backorder?"
                                            trigger="click"
                                            visible={deleteVisible === order.orderno}
                                            >
                                                <Button block htmlType="button" type='primary' onClick={(e) => {e.preventDefault();setDeleteVisibleProduct('');setDeleteVisible('');setDeleteVisible(order.orderno)}}>Cancel Backorder</Button>
                                            </Popover>
                                            : <Link to='/your-account/new-return' className='linkUnderline'>Return</Link>
                                        }
                                    </div>
                                </motion.section>)}
                            </AnimatePresence>
                            <Divider className='onlyMobile'/>
                            </>
                        )
                    }
                </div>
            </div>
        </MainLayout>
    );
}

export default SingleBackOrder;
