import React from 'react';
import {Layout, Row, Col, Divider} from 'antd';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const { Footer } = Layout;

const footer = [
    {
        name: 'About Cellnet',
        childMenu: [
            {
                name: 'FAQs',
                link: '/faqs',
                permission: ''
            },
            {
                name: 'Cellnet Express',
                link: '/your-account/addresses',
                permission: 'P'
            },
            {
                name: 'Our Brands',
                link: '/shop/brands',
                permission: ''
            },
            {
                name: 'Products',
                link: '/shop',
                permission: ''
            },
        ]
    },
    {
        name: 'Account',
        childMenu: [
            {
                name: 'Your Orders',
                link: '/your-account/your-order',
                permission: 'H'
            },
            {
                name: 'Invoices & Statements',
                link: '/your-account/your-invoices',
                permission: 'I'
            },
            {
                name: 'Order History',
                link: '/your-account/your-order',
                permission: 'H'
            }
        ]
    },
    {
        name: 'Let us help you',
        childMenu: [
            {
                name: 'Returns, Refunds & Repairs Policy',
                link: '/returns-policy',
                permission: ''
            },
            {
                name: 'Contact Us',
                link: '/contact-us',
                permission: ''
            }
        ]
    }
]

const extraFooter = [
    {
        name: 'Terms & Conditions',
        link: '/terms-conditions',
    },
    {
        name: 'Privacy Policy',
        link: '/privacy-policy',
    },
    /*{
        name: 'Cookies',
        link: '/cookies',
    },*/
    {
        name: 'Disclaimer & Order Policy',
        link: '/disclaimer',
    }
]

const copyright = '© 2020 Cellnet Group Ltd';

const MainFooter: React.FC = () => {
    const { userState} = React.useContext(UserContext);

    return (
    <Footer className='myFooter'>
        <div className='content-wrapper'>
            <Row gutter={30}>
            {
                footer.map((value, key) => {
                    return (
                        <Col xs={24} sm={24} md={6} lg={5} xl={4} key={key}>
                            <h3><strong>{value.name}</strong></h3>
                            {
                                value.childMenu.map((menu, k) => {
                                    const checkPermission = menu.permission.split('');
                                    for (let index = 0; index < checkPermission.length; index++) {
                                        const element = checkPermission[index];
                                        
                                        // permission = ABCDER
                                        if(userState?.currentUserAccount?.permissions?.includes(element)) {
                                            if(menu.name === 'Data Feed') {
                                                if( userState?.currentUserAccount?.extrapermissions?.includes(element) ) {
                                                    return null;
                                                } else {
                                                    return (
                                                        <div key={k}>
                                                            <Link to={menu.link ? menu.link : ''}>{menu.name}</Link>
                                                        </div>
                                                    )
                                                }
                                            } else {
                                                return (
                                                    <div key={k}>
                                                        <Link to={menu.link ? menu.link : ''}>{menu.name}</Link>
                                                    </div>
                                                )
                                            }
                                            
                                        } else {
                                            return null
                                        }
                                    }
                                    
                                    return (
                                        <div key={k}>
                                            <Link to={menu.link ? menu.link : ''}>{menu.name}</Link>
                                        </div>
                                    )                                    
                                })
                            }
                        </Col>
                    )
                })
            }
            </Row>

            <Divider />

            <Row gutter={30}>
                <Col span={24}>
                    {
                        extraFooter.map((value, key) => {
                            return (
                                <div className='marginRight20px displayInlineBlock' key={key}><Link to={value.name ? value.link : ''} key={key}>{value.name}</Link></div>
                            )
                        })
                    }
                </Col>
            </Row>

            <Row gutter={30}>
                <Col span={24}>
                    <p className='copyright'>{copyright}</p>
                </Col>
            </Row>
        </div>
    </Footer>)
} 

export default MainFooter