import React from 'react';
import { Image, Skeleton } from 'antd';
import { formatCurrency, formatText } from '../../utils';
import { Link } from 'react-router-dom';
import FavouriteButton from '../../components/FavouriteButton';
import AddToCart from '../../components/AddToCart';
import { ProductExt } from '../../RESTAPI';
import { imageUrl } from '../../Constants';
import { ProductContext } from '../../contexts/ProductContext';

const ProductCard: React.FC<{product: ProductExt, loading?: boolean}> = ({product, loading = false}) => {
    const {productState} = React.useContext(ProductContext);
    return (
        product !== undefined ? 
            <div className='productCard'>
                <Skeleton loading={loading} active avatar title paragraph={{rows: 8}}>
                    <div>
                        <Link to={'/shop/' + formatText(product.product ? product.product.name : '')}>
                        <Image className='paddingTop20p' preview={false} src={imageUrl + (product.product ? product.product.image : '')}/>
                        </Link>
                    </div>
                    <div className='flexColumn'>
                        <FavouriteButton id={product.product.id} favourite={productState.favourites.includes(product.product.id)} className='topMinus18px'/>
                        <Link to={'/shop/' + formatText(product.product ? product.product.name : '')}>
                        <h3 className='truncateText1Line noMarginBottom'>{product.product ? product.product.name : ''}</h3>
                        <p className='truncateText'>{product.product ? product.product.shortName : ''}</p>
                        </Link>
                        <p className='noMarginBottom fontSize14px'>RRP {formatCurrency(product.retailPrice ? product.retailPrice : 0)}</p>
                        <p className='bold fontSize21px text-primaryColor'>{formatCurrency(product.priceBreakInfo ? product.priceBreakInfo.priceBreaks[0].price : 0)}</p>
                        <AddToCart qty={1} product={product}/>
                    </div>
                </Skeleton>
            </div>
        : null
    );
}

export default ProductCard;
