import React from 'react';
import { Row, Col, Typography } from 'antd';
import MainLayout from '../../layouts/MainLayout';

const { Paragraph, Title } = Typography;

const Disclaimer: React.FC = () => {
  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '500px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <h2 className='headerTitle textCenter  marginTop20px'>Disclaimer, order and shipping policy</h2>
                <Paragraph>The information on this website is subject to change and whilst every effort is made to ensure that the content is current no guarantee or warranty as to its currency is given. Regrettably inaccuracies, omissions and inconsistencies may occur. In particular, product information provided within the site may have been taken from a vendor's web page. If this is the case it is in your best interest to confirm our information by visiting the appropriate vendor's homepage.</Paragraph>
                
                <Paragraph>Pricing information (listed in AUD) on this website is updated daily. Be aware price and product availability changes do occur on a daily basis. Cellnet Group Limited ('Cellnet') is liable to pay GST on most goods and services we supply. For validity of pricing please contact Cellnet.</Paragraph>
                
                <Paragraph>Use of or access to information provided on this website does not create any legal or binding relationship between you and Cellnet.</Paragraph>
                
                <Paragraph>Cellnet does not make any warranty or guarantee in relation to that the content of this website whether implied by custom, law or statute save for operation of the Trade Practices Act (Cth) (1974) and State Consumer Protection Legislation from which the obligations and liability of Cellnet can not be excluded.</Paragraph>
                
                <Paragraph>Cellnet is not liable for any loss or damage suffered by you or anyone else in connection with the use of this website or any of its content.</Paragraph>
                
                <Paragraph>Prior to using any download Cellnet recommends the user carry out an appropriate virus check as Cellnet does not accept any liability for any computer viruses transmitted in connection with the use of this website.</Paragraph>
                
                <Paragraph>The website may contain links to other sites, but no representation or warranty is given as to the accuracy or any other aspect of the information on those linked sites.</Paragraph>
                
                <Paragraph>No link to another website should be construed as an endorsement or warranty by Cellnet of any information, goods or services referred to therein.</Paragraph>
                
                <Paragraph>All trademarks displayed on this website belong to their respective owners. Unless otherwise indicated.</Paragraph>
                
                <Paragraph>Visitors to this website are under an obligation to take all reasonable steps to minimise the extent of loss suffered as result.</Paragraph>
                
                <Paragraph>Cellnet owns the copyright in the content on this website and reserves its full rights for any unauthorised use by you.</Paragraph>

                <Title level={5}>Shipping and freight policy</Title>
                <Paragraph>Freight costs are calculated during check out and will be displayed prior to submitting any order.</Paragraph>
                <Paragraph>All orders are processed within 2-3 business days. Orders are not shipped or delivered on weekends or holidays.</Paragraph>
                <Paragraph>If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. If there will be a significant delay in shipment of your order, we will contact you via email or telephone.</Paragraph>
                <Paragraph>Cellnet Group uses various transport carriers in order to deliver shipments and tracking links will be accessible through the account area where available.</Paragraph>
            </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default Disclaimer;
