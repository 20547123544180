import React from 'react';
import { Row, Col, Typography } from 'antd';
import MainLayout from '../../layouts/MainLayout';

const { Paragraph, Text } = Typography;

const CookiesPolicy: React.FC = () => {
  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '500px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <h2 className='headerTitle textCenter'>Cookies</h2>
                <Paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque vehicula leo, vel tristique lorem varius at. Sed sit amet lectus in nibh vehicula interdum. Praesent eleifend vestibulum malesuada. Donec eget nibh pretium, accumsan massa in, condimentum libero. In hac habitasse platea dictumst. Donec fermentum cursus nunc non tristique. Suspendisse nisi tellus, tincidunt nec accumsan quis, pharetra eget nunc. Nunc quam risus, ultrices eget dignissim quis, malesuada eget libero.</Paragraph>

                <Text>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed iaculis purus eget facilisis semper. Phasellus dapibus interdum libero, ut iaculis lorem auctor in. Suspendisse consequat mollis imperdiet. Ut nec augue vel velit sollicitudin euismod. Proin ac metus sit amet elit blandit pharetra at in ligula. Sed sit amet velit ut nisl fringilla tristique. Donec ut odio turpis. Aliquam erat volutpat. Donec pulvinar et est at porta. Quisque aliquet molestie metus, eu tempor risus vulputate id. Integer vel orci eros. Etiam ultrices turpis et massa sodales volutpat. Pellentesque cursus vestibulum ornare. Nam non interdum nibh, vitae scelerisque odio. In vulputate congue egestas.
                </Text>
            </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default CookiesPolicy;
