import React from 'react';
import { Row, Col, Divider, Image } from 'antd';
import { calcBalance, dateStringFormat, formatCurrency, getBreakPrice, totalQty, useWindowSize } from '../../../utils';
import { imageUrl, invoiceDownload, orderNoStatus, tabletSize } from '../../../Constants';
import { Link, useParams, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { DownOutlined } from '@ant-design/icons';
import { Invoice, Order, OrderLine, TrackingDataExt, ServiceMainRESTClient, RecentActivityMessage, MethodCallback, InvoiceLine } from '../../../RESTAPI';
import { ProductContext } from '../../../contexts/ProductContext';
import _ from 'lodash';
import { UserContext } from '../../../contexts/UserContext';
import AddToCart from '../../../components/AddToCart';
import MainLayout from '../../../layouts/MainLayout'; 
import { logout } from '../../../elements/PrivateRoute/PrivateRoute';

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const SingleInvoice: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const [order, setOrder] = React.useState<Order>();
    const [orderLine, setOrderline] = React.useState<OrderLine[]>([]);
    const [address, setAddress] = React.useState<string>();
    const [invoice, setInvoice] = React.useState<Invoice>(); // should have invoice donwload here
    const [invoiceLine, setInvoiceLine] = React.useState<InvoiceLine[]>([]); // should have invoice donwload here
    const [trackingData, setTrackingData] = React.useState<TrackingDataExt[]>(); // should have invoice donwload here
    const size = useWindowSize();

    const {invoiceno} = useParams<{invoiceno: string}>();

    const { productState } = React.useContext(ProductContext);
    const { userState, setRecentActivities } = React.useContext(UserContext);

    const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void { },
        onSuccess(returnMessage: RecentActivityMessage, context?: any): void {
            if (!returnMessage.authenticated) {
                logout();
            } else {
                setRecentActivities(returnMessage.recentActivities);
            }
        }
    }

    React.useEffect(() => {
        if (order) {
            const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {
                onFailure(error: string): void {
                    alert(error);
                },
                onProgress(loaded: number, total: number): void { },
                onSuccess(returnMessage: RecentActivityMessage, context?: any): void {
                    if (!returnMessage.authenticated) {
                        logout();
                    } else {
                        setRecentActivities(returnMessage.recentActivities);
                    }
                }
            }

            if (parseInt(order.lowstatus) >= 22 && parseInt(order.lowstatus) <= 27 ) {
                restClient.addActivityViewBackOrder(order.orderno, RecentActivityCallback);
            } else {
                restClient.addActivityViewOrder(order.orderno, RecentActivityCallback);
            }
            
        }
    }, [order])

    React.useEffect(() => {
        // get order
        if(userState.account) {
            const currentInvoice = _.filter(userState.account.invoices , (o) => {
                return o.invoiceno.toString() === invoiceno.toString();
            });

            if(currentInvoice.length > 0) {
                const currentInv = currentInvoice[0];
                setInvoice(currentInv);
                setInvoiceLine(userState.account.invoiceNoToInvoiceLines[currentInv.invoiceno]); 

                setAddress(currentInv.custdeliveryaddress1 + ', ' + currentInv.custdeliveryaddress2 + ', ' + (currentInv.custcompanyname ? currentInv.custcompanyname : '') + ', ' + currentInv.custdeliverysuburb + ', ' + currentInv.custdeliverytwocharstate + ' ' + currentInv.custdeliverypostcode);
            }  
        }
    }, [userState.account, invoiceno])

    if(invoiceno === undefined || invoiceno === null || invoiceno === ':invoiceno') {
        return (<Redirect
            to={{
                pathname: '/'
            }}
        />)
    }

    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'>
                <h2 className='headerTitle onlyDesktop'>Invoice Details: {invoiceno}</h2>
                <h2 className='headerTitle onlyMobile textCenter'>Invoice Details: {invoiceno}</h2>
                <div className='orderContainer' style={{padding: '20px'}}>
                    {
                        invoice && (
                            <>
                            <Row gutter={20}>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <p>
                                        {dateStringFormat(invoice.duedate)} <br/>
                                        <strong>{invoice.orderno}</strong>
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <p>
                                        Total <br/>
                                        <p><strong>{formatCurrency(invoice.amount)}</strong></p>
                                    </p>
                                    <p>
                                        Balance <br/>
                                        <p><strong>{formatCurrency(calcBalance(invoice, userState))}</strong></p>
                                    </p>
                                </Col>
                                <Col xs={0} sm={0} md={16} lg={16} xl={16}>
                                    <div className='flexSpaceBetween'>
                                        <div>
                                            <p>
                                                Shipping <br/>
                                                <strong>{address}</strong>
                                            </p>
                                        </div>
                                        <div style={{minWidth: '145px'}}>
                                            {
                                                invoice && <a href={invoiceDownload + invoice.invoiceno} onClick={() => { restClient.addActivityViewInvoice(invoice.invoiceno.toString(), RecentActivityCallback)}} target='_blank' rel='noreferrer noopener' className='linkUnderline'>Download Invoice</a>
                                            }
                                        
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <DownOutlined className='animation collapseButton onlyMobile marginTop20px' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180: 0} />
                            <Divider className='onlyDesktop'/>
                            <AnimatePresence initial={false}>
                                {(isOpen || size.width >= tabletSize) && (
                                <motion.section
                                    key="content"
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                >
                                    {
                                        invoiceLine && invoiceLine.map((line, key) => {
                                            const product = _.find(productState.products, (p) => {return line.itemno === p.product.id});

                                            if(product) {
                                                const currentPrice = getBreakPrice(product.priceBreakInfo.priceBreaks, line.qty)
                                                return (<Row gutter={15} key={key} className='alignItemCenter rowOrderLine'>
                                                    <Col xs={5} sm={5} md={2} lg={2} xl={2}>
                                                        <Image preview={false} src={imageUrl + product.product.image}/>
                                                    </Col>
                                                    <Col xs={19} sm={19} md={18} lg={18} xl={18}>
                                                        <p className='noMarginBottom'><strong>{product.product.name}</strong></p>
                                                        <p className='noMarginBottom'>{product.product.description}</p>
                                                        <p className='noMarginBottom text-grayColor'><strong>{line.qty} pc @ {formatCurrency(currentPrice)}</strong></p>
                                                    </Col>
                                                </Row>)
                                            } else {
                                                return null
                                            }

                                            
                                        })
                                    }
                                    <p>
                                        <strong>Shipping</strong> <br/>
                                        {address}
                                    </p>
                                    
                                    <div className='onlyMobile'>
                                        {
                                            invoice && <a href={invoiceDownload + invoice.invoiceno} onClick={() => { restClient.addActivityViewInvoice(invoice.invoiceno.toString(), RecentActivityCallback)}} target='_blank' rel='noreferrer noopener' className='linkUnderline marginRight20px'>Download Invoice</a>
                                        }
                                    </div>
                                </motion.section>)}
                            </AnimatePresence>
                            <Divider className='onlyMobile'/>
                            </>
                        )
                    }
                </div>
            </div>
        </MainLayout>
    );
}

export default SingleInvoice;
