import React from 'react';
import {Upload, message  } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import {InboxOutlined} from '@ant-design/icons';
import { orderByCSV } from '../../Constants';
import Cookies from 'universal-cookie';
import { DraggerProps } from 'antd/lib/upload';

const { Dragger } = Upload;

const cookies = new Cookies();

const OrderByCSV: React.FC = () => {
  const props: DraggerProps = {
    name: 'csvFile',
    action: orderByCSV,
    multiple: false,
    method: 'post',
    showUploadList: true,
    headers: {
      'Accept': 'application/json',
      'token-X': cookies.get('token')
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        //console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <MainLayout>
      <div className='content-wrapper marginBottom40px marginTop20px'>
        <div className='textCenter'>
            <h2 className='headerTitle'>ORDER BY CSV</h2>
        </div>
        <div className='whiteBg padding20px uploadContainer' style={{maxWidth: '484px', margin: '0 auto'}}>
          <Dragger {...props} listType='picture'>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Upload file or drag & drop</p>
          </Dragger>
        </div>
      </div>
    </MainLayout>
  );
}

export default OrderByCSV;
