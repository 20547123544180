import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const accountMenu = [
    {
        name: 'Recent Activities',
        image: '',
        link: '/your-account',
        permission: 'C'
    },
    {
        name: 'Your Orders',
        image: 'orders-small.svg',
        link: '/your-account/your-order',
        permission: 'H'
    },
    {
        name: 'Back Orders',
        image: 'orders-small.svg',
        link: '/your-account/back-order',
        permission: 'H'
    },
    {
        name: 'Login & Security',
        image: 'security-small.svg',
        link: '/your-account/login-detail',
        permission: ''
    },
    {
        name: 'Invoices',
        image: 'invoices-small.svg',
        link: '/your-account/your-invoices',
        permission: 'I'
    },
    {
        name: 'Returns',
        image: 'return-small.svg',
        link: '/your-account/returns',
        permission: 'L'
    },
    {
        name: 'Addresses',
        image: 'address-small.svg',
        link: '/your-account/addresses',
        permission: 'P'
    },
    /*{
        name: 'Payments',
        image: 'payment-small.svg',
        link: '/your-account/payment',
        permission: 'C'
    },*/
    {
        name: 'Manage Users',
        image: 'manage-users-small.svg',
        link: '/your-account/manage-users',
        permission: 'A'
    },
    {
        name: 'Download Price Lists',
        image: 'price-list-small.svg',
        link: '/your-account/pricelist',
        permission: 'DR'
    },
    {
        name: 'Download Files',
        image: 'price-list-small.svg',
        link: '/your-account/files',
        permission: ''
    }
];

const MobileAccountMenu: React.FC<{site?: string}> = ({site = ''}) => {
    const { userState} = React.useContext(UserContext);

    const Mobile = <Menu mode="horizontal" defaultSelectedKeys={[site]}>
        {
        accountMenu.map((menu, key) => {
            const checkPermission = menu.permission.split('');
            for (let index = 0; index < checkPermission.length; index++) {
                const element = checkPermission[index];
                
                // permission = ABCDER
                if(userState?.currentUserAccount?.permissions?.includes(element)) {
                    if(menu.name === 'Data Feed') {
                        if( userState?.currentUserAccount?.extrapermissions?.includes(element) ) {
                            return null;
                        } else {
                            return (
                                <Menu.Item key={menu.name}><Link to={menu.link}>{menu.name}</Link></Menu.Item>
                            )
                        }
                    } else {
                        return (
                            <Menu.Item key={menu.name}><Link to={menu.link}>{menu.name}</Link></Menu.Item>
                        )
                    }
                    
                } else {
                    return null
                }
            }
            
            return (
                <Menu.Item key={menu.name}><Link to={menu.link}>{menu.name}</Link></Menu.Item>
            )
        })
        }
    </Menu>

    return (
        <div className='onlyMobile'>
        {Mobile}
        </div>
    );
}

export default MobileAccountMenu;
