import React from 'react';
import {Row, Col, Typography} from 'antd';
import WizardForm from '../components/WizardForm';
import {changePasswordStep, Location} from '../Constants';
import {myAuth} from '../elements/PrivateRoute/PrivateRoute';
import PageLink from '../elements/PageLink/PageLink';
import LoginFooter from '../components/LoginFooter';
import CellnetLogo from '../elements/Logo/Logo';
import HomeBg from '../components/HomeBg';
import { motion } from "framer-motion";
import { pageVariants } from '../Constants';
import { Redirect, useLocation, useParams  } from 'react-router-dom';
import { LoginMessage, MethodCallback, ServiceMainRESTClient } from '../RESTAPI';

const { Title } = Typography;

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

function ResetPassword() {
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [direction, setDirection] = React.useState<number>(1);

  const changePasswordCallback:MethodCallback<LoginMessage> = {
    onFailure(error: string): void {
      alert(error);
    },
    onProgress(loaded: number, total: number): void {},
    onSuccess(message: LoginMessage, context: any): void {
      if(message.error) {
        alert(message.error)
      } else {
        setCurrentStep(currentStep + direction);
      }
    }
  }

  const handleSetCurrentStep = (direction: number, values: any) => {
    const nextStep = currentStep + direction;
    setDirection(direction);

    if(nextStep >= changePasswordStep.length) {
      restClient.updatePassword(hash, values.password, changePasswordCallback);
    }
  }

  let prevProp = '/';
  let location = useLocation<Location>();
  if(location.state) {
    prevProp = location.state.from;
  }

  const {hash} = useParams<{hash: string}>(); 

  // stop user from accessing login page again
  if (myAuth.isAuthenticated) {
    return (
        <Redirect
            to={{
                pathname: prevProp
            }}
        />
    )
  }

  return (
    <Row justify='center' align='middle' className='fullHeight overflowHidden'>
        <HomeBg isForgot/>
        <Col xs={24} sm={24} md={8} lg={8} xl={13} className='overflowHidden padding30px fullHeight verticalCenter flexColumn whiteBg'>
            <motion.div className='marginTopAuto' initial="initial" animate="in" exit="out" variants={pageVariants}>
                <CellnetLogo to='/' className="textCenter marginBottom40px"/>
                <Title level={5} className='textCenter marginBottom25px'>Change Password</Title>
                <WizardForm currentValues={{}} className='maxWidth300px' finalButton='Done' title='Forgotten Password' steps={changePasswordStep} currentStep={currentStep} direction={direction} setStep={(direction: number, values: any) => handleSetCurrentStep(direction, values)}/>
                
                {
                    currentStep !== changePasswordStep.length && (
                        <div className='textCenter marginTop25px'>
                            <strong>Remembered?</strong>
                            <PageLink to="/" bold className='marginLeft10px text-secondaryColor'>Sign In</PageLink>
                        </div>
                    )
                }
                
            </motion.div>

            <LoginFooter className='marginTopAuto'/>
        </Col>
    </Row>
  );
}

export default ResetPassword;
