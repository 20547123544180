import React from 'react';
import { Modal, Row, Col, Image, Button } from 'antd';
import { CartContext } from '../contexts/CartContext';
import { formatCurrency, getBreakPrice, useWindowSize } from '../utils';
import { imageUrl, tabletSize } from '../Constants';
import OrderSummaryInfo from './OrderSummaryInfo';
import { ProductContext } from '../contexts/ProductContext';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

const CartModal = () => {
  const { cartState, hideCartModal } = React.useContext(CartContext);
  const { productState } = React.useContext(ProductContext);
  const history = useHistory();
  const size = useWindowSize();
  return (
      <>
      <Modal
        title="Successfully added to your cart"
        centered
        visible={(cartState.displayModal && size.width >= tabletSize) ? cartState.displayModal : false}
        className='cartModal'
        onCancel={hideCartModal}
      >
          <Row gutter={60}>
              <Col span={14} className='borderRightGray'>
                  {
                      (productState.products.length > 0 && cartState.cartItems) && 
                        cartState.cartItems.map((item, key) => {
                            const productid = item.productid;
                            const product = _.find(productState.products, (p) => {
                                return parseInt(p.product.id) === parseInt(productid);
                            })

                            if(product) {
                                const currentPrice = getBreakPrice(product.priceBreakInfo.priceBreaks,item.quantity)
                                return (
                                    <Row gutter={[30, 30]} key={key}>
                                        <Col span={5}>
                                            {
                                                product.product.image && product.product.image !== '' ?  <Image preview={false} src={imageUrl + (product.product ? product.product.image : '')}/> : ''
                                            }
                                        </Col>
                                        <Col span={19}>
                                            <h3 className='fontSize18px noMarginBottom'><strong>{product.product.name}</strong></h3>
                                            <p className='noMarginBottom fontSize16px'>{product.product.shortName}</p>
                                            <p className='fontSize16px'><strong>{item.quantity}pc @ {formatCurrency(currentPrice)}</strong></p>
                                        </Col>
    
                                    </Row>
                                )
                            } else {
                                return null
                            }
                        })
                  }
              </Col>
              <Col span={10}>
                  <h2 className='headerTitle displayInlineBlock'>YOUR CART</h2><span className='fontSize16px marginLeft20px'>{cartState.itemCount} item(s)</span>
                  <OrderSummaryInfo shippingPrice={0} adminFee={0}/>

                  <Button htmlType='button' block type="primary" className='marginBottom20px' onClick={hideCartModal}>CONTINUE SHOPPING</Button>
                  <Button htmlType='button' block onClick={() => {hideCartModal(); history.push('/cart')}}>GO TO CART</Button>
              </Col>
          </Row>
      </Modal>
      <Modal
        title="Successfully added to your cart"
        visible={(cartState.displayModal && size.width < tabletSize) ? cartState.displayModal : false}
        className='cartModal cartModalMobile'
        onCancel={hideCartModal}
      >
          <Row>
              <Col span={24}>
                  <Button htmlType='button' block type="primary" className='marginBottom20px' onClick={hideCartModal}>CONTINUE SHOPPING</Button>
                  <Button htmlType='button' block onClick={() => {hideCartModal(); history.push('/cart')}}>GO TO CART</Button>
              </Col>
          </Row>
      </Modal>
      </>

  );
};

export default CartModal;