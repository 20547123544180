import { Button, Drawer, Row, Col, Form, Input, Radio, DatePicker, Checkbox, message} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RadioChangeEvent } from 'antd/lib/radio';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { PasswordLength, UserAccountObj } from '../Constants';
import { UserContext } from '../contexts/UserContext';
import { MethodCallback, ServiceMainRESTClient, UserProfile, UsersMessage } from '../RESTAPI';
import _ from 'lodash';
import { logout } from '../elements/PrivateRoute/PrivateRoute';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from "moment";

const plainOptions = [
    {label: 'ADD NEW USERS', value: 'A'},
    {label: 'VIEW BACKORDERS', value: 'B'},
    {label: 'VIEW CREDIT DATA', value: 'C'},
    {label: 'VIEW DEALER PRICES', value: 'D'},
    {label: 'REQUIRE ORDER APPROVAL', value: 'E'},
    {label: 'APPROVE ORDERS', value: 'F'},
    {label: 'VIEW ORDER HISTORY', value: 'H'},
    {label: 'VIEW INVOICES', value: 'I'},
    {label: 'LODGE RETURN REQUESTS', value: 'L'},
    {label: 'CANCEL BACKORDER ITEMS', value: 'O'},
    {label: 'PLACE ORDERS', value: 'P'},
    {label: 'PRODUCT CATALOG', value: 'Q'},
    {label: 'VIEW RETAIL PRICES', value: 'R'},
    {label: 'STOCK ROTATIONS', value: 'S'},
    {label: 'SHOPPING CART', value: 'T'},
    //{label: 'VERSION5', value: 'V'},
    //{label: 'CUSTOMER SPECIAL ACCESS', value: 'X'},
];

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const AddNewUser: React.FC<{visible: boolean, currentUser?: UserAccountObj, currentUserProfile?: UserProfile, setVisible: (visible: boolean) => void}> = ({visible = false, currentUser, currentUserProfile, setVisible}) => {
    const [valueDate, setDate] = React.useState<string>('');
    const [valueTime, setTime] = React.useState<string>('');
    const [valueRadioDate, setValueRadioDate] = React.useState(1);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);
    const [checkedList, setCheckedList] = React.useState<Array<any>>([]);

    const {userState} = React.useContext(UserContext);

    const [visiblePassword, setVisiblePassword] = React.useState<boolean>(false);

    const {setManagedUserProfiles, setManagedUserAccounts} = React.useContext(UserContext);

    React.useEffect(() => {
        if(currentUser) {
            setCheckedList(currentUser.permissions.split(''));
            setCheckAll(false);
            setIndeterminate(false);
            setValueRadioDate(1);
            setTime('');
            setDate('');
        } else {
            setCheckedList([]);
            setCheckAll(false);
            setIndeterminate(false);
            setValueRadioDate(1);
            setTime('');
            setDate('');    
        }
        
    }, [currentUser])

    const onChange = (e: RadioChangeEvent) => {
        setValueRadioDate(e.target.value);
    }

    const onChangeCheckList = (list: CheckboxValueType[]) => {
        const prevChecked = [...checkedList];
        if(prevChecked.includes('E')) {
            if(list.includes('F')) {
                _.remove(list, function(o) {
                    return o === 'E'
                })
            }
        } else if(prevChecked.includes('F')) {
            if(list.includes('E')) {
                _.remove(list, function(o) {
                    return o === 'F'
                })
            }
        }

        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    }

    const onCheckAllChange = (e:CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? _.map(_.filter(plainOptions, function(o) { return o.value !== 'E'; }), 'value') : []);
        setIndeterminate(true);
        setCheckAll(e.target.checked);
    };

    const handleDate = (value: any, dateString: string) => {
        setDate(dateString.replaceAll('-', ''));
    }

    const handleTime = (value: any, dateString: string) => {
        setTime(dateString.replaceAll(':', ''));
    }

    const updateUserProfile:MethodCallback<UsersMessage> = {
        onFailure(error: string): void {
            alert(error);
          },
          onProgress(loaded: number, total: number): void {},
          onSuccess(returnMessage: UsersMessage, context?: any): void {
              if(!returnMessage.authenticated) {
                  logout();
              } else {
      
                  message.loading({ content: 'Update user profile...', key: 'updatable' });
      
                  if(returnMessage.error) {
                      setTimeout(() => {
                          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                      }, 200);
                  } else {
                      setTimeout(() => {
                          message.success({ content: 'Updated user profile!', key: 'updatable', duration: 2 });
                          context.setManagedUserProfiles(returnMessage.managedUserProfiles);
                          setVisible(false);
                      }, 200);
                  }
              }
          }
    }

    const updateUserAccount:MethodCallback<UsersMessage> = {
        onFailure(error: string): void {
            alert(error);
          },
          onProgress(loaded: number, total: number): void {},
          onSuccess(returnMessage: UsersMessage, context?: any): void {
              if(!returnMessage.authenticated) {
                  logout();
              } else {
      
                  message.loading({ content: 'Update user account...', key: 'updatable' });
      
                  if(returnMessage.error) {
                      setTimeout(() => {
                          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                      }, 200);
                  } else {
                      setTimeout(() => {
                          message.success({ content: 'Updated user account!', key: 'updatable', duration: 2 });
                          context.setManagedUserAccounts(returnMessage.managedUserAccounts);
                          setVisible(false);
                      }, 200);
                  }
              }
          }
    }

    const addUserAccount:MethodCallback<UsersMessage> = {
        onFailure(error: string): void {
            alert(error);
          },
          onProgress(loaded: number, total: number): void {},
          onSuccess(returnMessage: UsersMessage, context?: any): void {
              if(!returnMessage.authenticated) {
                  logout();
              } else {
      
                  message.loading({ content: 'Adding...', key: 'updatable' });
      
                  if(returnMessage.error) {
                      setTimeout(() => {
                          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                      }, 200);
                  } else {
                      setTimeout(() => {
                          message.success({ content: 'Added user!', key: 'updatable', duration: 2 });
                          context.setManagedUserAccounts(returnMessage.managedUserAccounts);
                          context.setManagedUserProfiles(returnMessage.managedUserProfiles);
                          setVisible(false);
                      }, 200);
                  }
              }
          }
    }

    const handleSubmit = (values: any) => {
        // valueRadioDate = 0 -> deactivate
        // valueRadioDate = 1 -> active now
        // valueRadioDate = 2 -> active at specific date
        // register time: valueDate + valueTime
        if(currentUserProfile) {
            if(valueRadioDate === 0) {
                // deactivate user
                if(currentUser) {
                    restClient.deactivateUserProfile(currentUser.userid, updateUserAccount, {setManagedUserAccounts})    
                }
            } else {
                restClient.updateUserProfile(currentUserProfile.id, values.firstname, values.lastname, values.email, currentUserProfile.contactphone, (values.password ? values.password : ''), updateUserProfile, {setManagedUserProfiles});

                if(currentUser) {
                    restClient.updateUserAccount(currentUser.userid, currentUser.accountid, checkedList.join(''), currentUser.managerid, updateUserAccount, {setManagedUserAccounts});
                }
            }
            
        } else {
            if(valueRadioDate === 1) {
                // activate now for new user
                const d = new Date();
                let today = d.getFullYear() + ("0"+(d.getMonth()+1)).slice(-2) + ("0" + d.getDate()).slice(-2) + ("0" + d.getHours()).slice(-2) + ("0" + d.getMinutes()).slice(-2) + ("0" + d.getSeconds()).slice(-2);

                restClient.addUser(values.firstname, values.lastname, values.email, values.phone, values.password, userState.currentUserAccount.accountid, today, checkedList.join(''), userState.currentUserAccount.userid, addUserAccount, {setManagedUserProfiles, setManagedUserAccounts});
            } else {
                restClient.addUser(values.firstname, values.lastname, values.email, values.phone, values.password, userState.currentUserAccount.accountid, valueDate + '' + valueTime, checkedList.join(''), userState.currentUserAccount.userid, addUserAccount, {setManagedUserProfiles, setManagedUserAccounts});
            }
        }
        
    }
    return (
        <Drawer
          title={currentUserProfile ? "Edit Account": "Add New Account"}
          width='100%'
          height='100vh'
          placement='bottom'
          onClose={() => setVisible(false)}
          visible={visible}
          className='gradientBorderBackground bottom170px'
        >
          <Form layout="vertical" hideRequiredMark onFinish={(values) => handleSubmit(values)}
          initialValues={
            {
                'firstname': currentUserProfile?.firstname,
                'lastname': currentUserProfile?.lastname,
                'email': currentUserProfile?.email,
                'phone': currentUserProfile?.contactphone,
            }
          }>
              <div style={{overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100vh - 231px)'}}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='firstname' label='' rules={[{required: true, message: 'Please input your first name!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='First Name' suffix={<label className="floating-label" htmlFor="name">First Name</label>}/> 
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='lastname' label='' rules={[{required: true, message: 'Please input your last name!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Last Name' suffix={<label className="floating-label" htmlFor="name">Last Name</label>}/> 
                </Form.Item> 
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item hasFeedback name='phone' label='' rules={[{required: true, message: 'Please input your phone!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Phone' suffix={<label className="floating-label" htmlFor="name">Phone</label>}/> 
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item className="group-floating-label" hasFeedback name='password' label='' rules={[({ getFieldValue }) => ({
                    validator(rule, value) {
                        if(!value || value.length === 0) {
                            return Promise.resolve();    
                        } else {
                            if (value.length >= PasswordLength) {
                                return Promise.resolve();
                            }
                        }
                        return Promise.reject('Please input a valid password!');
                    },
                })]}>
                    <Input type={visiblePassword ? "text" : "password"} size="large" className="has-float-label" prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisiblePassword(!visiblePassword)} > {visiblePassword ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">Password</label>}/>
                </Form.Item>
                <Form.Item className="group-floating-label" dependencies={['password']} hasFeedback name='confirm_password' label='' rules={[({ getFieldValue }) => ({
                    validator(rule, value) {
                        if(!getFieldValue('password') || getFieldValue('password').length === 0) {
                            return Promise.resolve();
                        } else {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                        }
                      
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                })]}>
                    <Input type={visiblePassword ? "text" : "password"} size="large" className="has-float-label" prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisiblePassword(!visiblePassword)} > {visiblePassword ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">Confirm Password</label>}/>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item hasFeedback name='email' label='' rules={[ {type: 'email', message: 'The input is not a valid email'}, {required: true, message: 'Please input your email!' }]}> 
                    <Input size="large" className="has-float-label" placeholder='Email Address' suffix={<label className="floating-label" htmlFor="name">Email Address</label>}/> 
                </Form.Item>

                <p className='fontSize18px'><strong>Active</strong></p>
                <Radio.Group onChange={onChange} value={valueRadioDate}>
                    {
                        currentUserProfile &&
                        <Radio value={0}>Deactivate</Radio>
                    }
                    <Radio value={1}>Immediately</Radio>
                    <Radio value={2}>From Date</Radio>
                </Radio.Group>
                <AnimatePresence exitBeforeEnter>
                    {
                        valueRadioDate === 2 && (
                            <motion.div key='open' initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    collapsed: { opacity: 0, height: 0 }
                                }}
                                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}>
                                    <div className='datePicker'>
                                        <DatePicker placeholder='Date' defaultValue={moment(currentUserProfile?.activated.substring(0, 8))} picker='date' className="has-float-label" onChange={handleDate}/>
                                        <DatePicker picker='time' placeholder='Time' className="has-float-label" onChange={handleTime}/>
                                    </div>
                            </motion.div>
                        )
                    }
                </AnimatePresence>
                <div className='accessCheckBox'>
                    <p className='fontSize18px marginTop20px'><strong>Access</strong></p>
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                        All
                    </Checkbox>
                    <Checkbox.Group className='flexColumn' options={plainOptions} value={checkedList} onChange={onChangeCheckList} />
                </div>
              </Col>
            </Row>
            </div>
            <div className='footerBtnDrawer form'>
                <Button htmlType='submit' type="primary">
                    Done
                </Button>
                <Button onClick={() => setVisible(false)} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
            </div>
          </Form>
        </Drawer>
    );
}

export default AddNewUser;
