import React from 'react';
import { Row, Col, Layout, Divider, InputNumber, Button} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import { productPDFpublic, productPDFstandard, productPDFenhance, productStatus } from '../../Constants';
import MainLayout from '../../layouts/MainLayout';
import { formatCurrency, formatText, calcMargin } from '../../utils';
import ProductListSlider from '../../components/ProductListSlider';
import ProductImageSlider from '../../components/ProductImageSlider';
import AddToCart from '../../components/AddToCart';
import FavouriteButton from '../../components/FavouriteButton';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { ProductContext } from '../../contexts/ProductContext';
import { MethodCallback, ProductExt, RecentActivityMessage, ServiceMainRESTClient } from '../../RESTAPI';
import _ from 'lodash';
import { logout } from '../../elements/PrivateRoute/PrivateRoute';
import { UserContext } from '../../contexts/UserContext';
import InnerHTML from 'dangerously-set-html-content'

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const ProductPage: React.FC = () => {
  const [qty, setQty] = React.useState<number>(1);
  const [currentProduct, setCurrentProduct] = React.useState<ProductExt>();
  const [relatedProduct, setRelatedProduct] = React.useState<ProductExt[]>([]);
  const {productId} = useParams<{productId: string}>();
  const { productState } = React.useContext(ProductContext);
  const { setRecentActivities } = React.useContext(UserContext);
  
  const { Sider, Content } = Layout;
  const location = useLocation();

  React.useEffect(() => {
    if(productId && productId.length > 0 && productState.products.length > 0) {
        const currentProduct = _.find(productState.products, (productExt) => {
            return formatText(productExt.product.name).toLowerCase() === productId.toLowerCase()
        });
        setCurrentProduct(currentProduct);

        const related = _.filter(productState.products, (product) => {
            return product.product.procurementGroupDesc === currentProduct?.product.procurementGroupDesc
        })
      
      setRelatedProduct(related);
    }
  }, [productId, productState.products])

  React.useEffect(() => {
    if(currentProduct) {
        const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {
            onFailure(error: string): void {
                alert(error);
            },
            onProgress(loaded: number, total: number): void { },
            onSuccess(returnMessage: RecentActivityMessage, context?: any): void {
                if (!returnMessage.authenticated) {
                    logout();
                } else {
                    setRecentActivities(returnMessage.recentActivities);
                }
            }
        }
        restClient.addActivityViewProduct(currentProduct.product.id, RecentActivityCallback)
    }
  }, [currentProduct])


  
  const handleChange = (value: string | number | undefined) => {
    if(value) {
      setQty(+value);
    }
  }

  if(location.pathname.includes(':productId')) {
    return  <Redirect
        to={{
            pathname: "/shop"
        }}
    />;
  }

  const productInfo = currentProduct?.attributes?.map((v, k) => {
      return (
            <div key={k}>
                <h2 className='headerTitle'>{v.name}</h2>
                <p className='productText'>
                  <InnerHTML html={v.value.replace(/(?:\r\n|\r|\n)/g, '<br />')} />
                </p>
            </div>
        )
  })

  return (
    <MainLayout>
      <Layout className='overFlowHidden'>
        <Content>
          <Row>
              <Col xs={24} sm={24} md={24} lg={16} xl={16} className='productImageSliderWrapper'>
                <div className='onlyMobile productDetails productDetailsMobile'>
                  <h2 className='productTitle'>{currentProduct?.product.name}</h2>
                  <p className='productDesc'>{currentProduct?.product.shortName}</p>
                  <FavouriteButton id={currentProduct?.product.id} favourite={productState.favourites.includes(currentProduct?.product.id ? currentProduct?.product.id : '')} className='top10px topMinus15px'/>
                </div>
                <ProductImageSlider images={[currentProduct?.product.image, currentProduct?.product.image2, currentProduct?.product.image3]}/>
              </Col>
          </Row>
          <div className='content-wrapper marginBottom40px marginTop60px'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={0} xl={0}>
                  <div className='productMain'>                    
                    <p className='productAttributeTitle'>Product Status</p>
                    <p className='productAttribute'><strong>{currentProduct && productStatus[currentProduct.product.webStatus]}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Cellnet ID</p>
                    <p className='productAttribute'><strong>{currentProduct?.product.id}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Barcode</p>
                    <p className='productAttribute'><strong>{currentProduct?.product.ean}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Availablity</p>
                    <p className='productAttribute'><strong>{currentProduct ? (parseInt(currentProduct.available) > 0 ? currentProduct.available : 'None') : 'None'}</strong></p>

                    <Divider/>
                    <p className='productAttributeTitle'>Margin</p>
                    <p className='productAttribute'><strong>{currentProduct ? calcMargin(currentProduct.retailPrice, currentProduct.priceBreakInfo.priceBreaks[0].price) + '%' : 'None'}</strong></p>
                    {
                      currentProduct && (<><Button className='marginTop25px maxWidth240 noPadding' size='small' href={productPDFpublic + currentProduct?.product.id} target='_blank' type="link" icon={<DownloadOutlined />}>
                        Download Public PDF
                      </Button>
                      <Button className='marginTop10px maxWidth240 noPadding' size='small' href={productPDFstandard + currentProduct?.product.id} target='_blank' type="link" icon={<DownloadOutlined />}>
                        Download Standard PDF
                      </Button>
                      <Button className='marginTop10px maxWidth240 noPadding' size='small' href={productPDFenhance + currentProduct?.product.id} target='_blank' type="link" icon={<DownloadOutlined />}>
                        Download Enhanced PDF
                      </Button></>)
                    }
                    
                  </div>
                  <div className='onlyMobile'>
                  <Row gutter={20} className='addToCartWrapperMobile'>
                    <Col xs={8} sm={8} md={8} lg={0} xl={0}>
                      <strong className='priceMobile'>{formatCurrency(currentProduct?.priceBreakInfo.priceBreaks[0].price)}</strong><br/>
                      <span className='rrpriceMobile'>RRP {formatCurrency(currentProduct?.retailPrice)}</span>
                    </Col>
                    <Col xs={16} sm={16} md={16} lg={0} xl={0}>
                      <Row gutter={10}>
                        <Col span={7}>
                          <InputNumber onChange={handleChange} className='qty' size="large" min={1} max={100000} defaultValue={qty} />
                        </Col>
                        <Col span={17}>
                          {
                            currentProduct ? 
                              <AddToCart qty={qty} product={currentProduct}/>
                            : null
                          }
                        </Col>
                      </Row> 
                    </Col>
                  </Row>
                  </div>
                </Col>

                <Col xs={0} sm={0} md={0} lg={16} xl={16}>
                    {
                        (currentProduct && currentProduct.attributes) && (
                            <div className='productMain'>
                                {
                                    productInfo
                                }
                            </div>
                        )  
                    }
                  
                </Col>
            </Row>
          </div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} className='similarProductSlide'>
              <ProductListSlider bottomPosition textCenter slideToShow={4} productList={relatedProduct} title='Similar Items'/>
            </Col>
          </Row>
        </Content>
        <Sider collapsedWidth={0} className='productDetails onlyDesktop'>
          <h2 className='productTitle'>{currentProduct?.product.name}</h2>
          <p className='productDesc'>{currentProduct?.product.shortName}</p>
          <FavouriteButton id={currentProduct?.product.id} favourite={productState.favourites.includes(currentProduct?.product.id ? currentProduct?.product.id : '')} className='top10px topMinus15px'/>
          <p className='productPriceWrapper'><span className='productPrice'>{formatCurrency(currentProduct?.priceBreakInfo.priceBreaks[0].price)}</span> RRP {formatCurrency(currentProduct?.retailPrice)}</p>
          
          <p className='productAttributeTitle'>Product Status</p>
          <p className='productAttribute'><strong>{currentProduct && productStatus[currentProduct.product.webStatus]}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Cellnet ID</p>
          <p className='productAttribute'><strong>{currentProduct?.product.id}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Barcode</p>
          <p className='productAttribute'><strong>{currentProduct?.product.ean}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Availablity</p>
          <p className='productAttribute'><strong>{currentProduct ? (parseInt(currentProduct.available) > 0 ? currentProduct.available : 'None') : 'None'}</strong></p>

          <Divider/>
          <p className='productAttributeTitle'>Margin</p>
          <p className='productAttribute'><strong>{currentProduct ? calcMargin(currentProduct.retailPrice, currentProduct.priceBreakInfo.priceBreaks[0].price) + '%' : 'None'}</strong></p>
          {
            currentProduct && <><Button className='marginTop25px maxWidth240 overflowInitial' size='small' href={productPDFpublic + currentProduct?.product.id} target='_blank' type="primary" icon={<DownloadOutlined />}>
              Download Public PDF
            </Button><Button className='marginTop10px maxWidth240 overflowInitial' size='small' href={productPDFstandard + currentProduct?.product.id} target='_blank' type="primary" icon={<DownloadOutlined />}>
              Download Standard PDF
            </Button><Button className='marginTop10px maxWidth240 overflowInitial' size='small' href={productPDFenhance + currentProduct?.product.id} target='_blank' type="primary" icon={<DownloadOutlined />}>
              Download Enhanced PDF
            </Button><br/><br/></>
          }

          <Row className='addToCartWrapper' gutter={20}>
              <Col span={7}>
                <InputNumber onChange={handleChange} className='qty' size="large" min={1} max={100000} defaultValue={qty} />
              </Col>
              <Col span={17}>
                {
                  currentProduct ? 
                    <AddToCart qty={qty} product={currentProduct}/>
                  : null
                }
              </Col>
          </Row>
        </Sider>
      </Layout>
    </MainLayout>
  );
}

export default ProductPage;