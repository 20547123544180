import React from 'react';
import { Row, Col } from 'antd';
import HeroSlider from '../../components/HeroSlider';
import MainLayout from '../../layouts/MainLayout';
import ProductListSlider from '../../components/ProductListSlider';
import OrderStatusCard from '../../components/OrderStatusCard';
import LogoList from '../../components/LogoList';
import CateList from '../../components/CateList';
import { ProductContext } from '../../contexts/ProductContext';
import { UserContext } from '../../contexts/UserContext';
import { Order, OrderLine } from '../../RESTAPI';
import InnerHTML from 'dangerously-set-html-content'

const Home: React.FC = () => {
  const {productState} = React.useContext(ProductContext);
  const {userState} = React.useContext(UserContext);

  const [orders, setOrders] = React.useState<Array<Order>>([]);
  const [orderLines, setOrderLines] = React.useState<{ [index: string]: OrderLine[]; }>();

  React.useEffect(() => {
    if(userState.account) {
      setOrders(userState.account.orders);
      setOrderLines(userState.account.orderNoToOrderLines);
    }
  }, [userState.account])

  const homeContent = userState.homeContent ? userState.homeContent : '';

  return (
    <MainLayout>
      <InnerHTML html={homeContent} />
      <HeroSlider productList={productState.special}/>

      <div className='content-wrapper marginBottom40px'>
        <Row gutter={30}>
          <Col xs={0} sm={0} md={16} lg={14} xl={18} >
            <ProductListSlider productList={productState.special} title='Recommended'/>
          </Col>
          <Col xs={24} sm={24} md={8} lg={10} xl={6}>
            {
              (userState?.currentUserAccount?.permissions?.includes('H')) && <OrderStatusCard orders={orders} title='Recent Orders' orderLines={orderLines}/>
            }
          </Col>
        </Row>

        <Row gutter={30} className='marginTop80px'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <LogoList list={productState.brands} title='Brands'/>
          </Col>
        </Row>

        <Row gutter={30} className='marginTop80px'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <CateList list={productState.categories} title='Categories'/>
          </Col>
        </Row>

        <Row gutter={30} className='marginTop80px'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <CateList list={productState.deviceVendors} title='Device Vendors'/>
          </Col>
        </Row>
      </div>

      <Row>
        <Col xs={24} sm={24} md={0} lg={0} xl={0} >
          {/*<ProductListSlider mobile productList={recommendedProduct} title='Recommended'/>*/}
        </Col>
      </Row>
    </MainLayout>
  );
}

export default Home;
