import React from 'react';
import { Row, Col} from 'antd';
import { PhoneOutlined, SendOutlined, ArrowRightOutlined } from '@ant-design/icons';

const ContactUsPage: React.FC = () => {

    return (
        <Row justify='center' align='middle' className='fullHeight overflowHidden'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='overflowHidden padding30px fullHeight flexColumn whiteBg'>
                <div>
                    <h1 className='fontSize48px noMarginBottom'><strong>AUSTRALIA</strong></h1>
                    <p className='noMarginBottom fontSize21px'>
                        E1, 5 Grevillea Place, Brisbane Airport<br/>QLD 4008
                    </p>
                    <p className='fontSize18px marginTop20px'>
                        <PhoneOutlined className='marginRight10px'/> <strong>1300 235 563</strong><br/>
                        <SendOutlined className='marginRight10px'/> <strong>info@cellnet.com.au</strong>
                    </p>
                    <p className='marginTop20px fontSize18px'>OR</p>

                    <h3 className='fontSize16px'><strong>New Zealand</strong> <ArrowRightOutlined className='marginLeft10px'/></h3>

                </div>
            </Col>
        </Row>
    );
}

export default ContactUsPage;