import { AccountMessage, Address, Alert, Customer, DataFeedMessage, Invoice, PreferencesMessage, ProductExt, RecentActivity, ReturnsMessage, ShoppingCartline, ShoppingCartsMessage, UserAccountExt, UserProfile} from "./RESTAPI";

export const PasswordLength = 5;
export const tabletSize = 899;
export const productPageSize = 24;
export const orderPageSize = 5;
export const envi = 'sandbox';

const mainUrl = 'https://test.connect.cellnet.com.au/';
const damUrl = 'https://dam.cellnet.com.au/Connect/';
export const imageUrl = mainUrl + 'ImageServlet/';
export const creditCheck = mainUrl + 'CreditCardServlet';
export const fileDownload = mainUrl + 'FileServlet/';
export const priceListDownload = mainUrl + 'PriceListServlet';
export const invoiceDownload = mainUrl + 'InvoiceServlet/';
export const orderByCSV = mainUrl + 'CSVCartUploadServlet';
export const productPDFpublic = mainUrl + 'ProductPDFServlet?templateId=PDF_PUBLIC&productIds=';
export const productPDFstandard = mainUrl + 'ProductPDFServlet?templateId=PDF_STANDARD&productIds=';
export const productPDFenhance = mainUrl + 'ProductPDFServlet?templateId=PDF_ENHANCED&productIds=';

export const brandsDam = damUrl + 'brands/';
export const deviceVendorsDam = damUrl + 'deviceVendors/';
export const catesDam = damUrl + 'categories/';

export const productStatus: {[key: string]: string} = {
    '20': 'Active',
    '30': 'Special Order',
    '40': 'Clearance',
    '50': 'Discontinued'
}

export const orderNoStatus: {[key: string]: string} = {
  '00': 'Pending',
  '01': 'Processing Error',
  '02': 'Unknown',
  '05': 'Quotation',
  '10': 'Preliminary',
  '20': 'Registered',
  '22': 'Stock is on backorder',
  '23': 'On backorder, partially received',
  '24': 'On backorder, partially received',
  '26': 'On backorder, partially fulfilled & shipped',
  '27': 'On backorder, partially fulfilled & shipped',
  '33': 'Stock has been allocated, but not picked',
  '37': 'Order has been partially fulfilled & shipped',
  '44': 'Pick in progress',
  '47': 'Order has been partially fulfilled & shipped',
  '66': 'Stock has been picked,but not shipped',
  '77': 'Order has been shipped',
  '79': 'Order has been partially shipped & cancelled',
  '90': 'Deleted',
  '99': 'Cancelled',
  'XX': 'In Progress'
}

export const returnStatus: {[key: string]: string} = {
  'AP': 'Approved',
  'TP': 'Approved',
  'UP': 'Approved',
  'CL': 'Closed',
  'IP': 'In Progress',
  'IN': 'Incomplete',
  'PE': 'Pending',
  'RJ': 'Rejected',
  'EX': 'Expired',
  'UA': 'Unassigned',
  'OP': 'Order Pending'
}

export const doneStatus = [
    'AP',
    'TP',
    'UP',
    'CL',
    'RJ',
    'EX'
]

export const pageVariants = {
  initial: {
    opacity: 0,
    transition: { duration: .6 },
  },
  in: {
    opacity: 1,
    transition: { duration: .6 },
  },
  out: {
    opacity: 0,
    transition: { duration: .6 },
  },
}

export const regoSteps = [
    {
      step: 1,
      isSuccess: false,
      description: '',
      isSuccessLogo: '',
      formInputs: [
        {
          inputType: 'text',
          label: 'First Name',
          name: 'firstname',
          isValidate: true,
          placeholder: 'First Name'
        },
        {
          inputType: 'text',
          label: 'Last Name',
          name: 'lastname',
          isValidate: true,
          placeholder: 'Last Name'
        },
        {
          inputType: 'email',
          label: 'Email',
          name: 'email',
          isValidate: true,
          placeholder: 'Email'
        }
      ]
    },
    {
      step: 2,
      isSuccess: false,
      description: '',
      isSuccessLogo: '',
      formInputs: [
        {
          inputType: 'number',
          label: 'Phone Number',
          name: 'phone',
          isValidate: true,
          placeholder: 'Phone Number'
        },
        {
          inputType: 'password',
          label: 'Password',
          name: 'password',
          isValidate: true,
          placeholder: 'Password (+6 characters)'
        },
        {
          inputType: 'confirm_password',
          label: 'Confirm Password',
          name: 'confirm_password',
          isValidate: true,
          placeholder: 'Confirm Password'
        }
      ]
    },
    {
      step: 3,
      isSuccess: true,
      description: 'We have sent an email, please confirm your address you register with.',
      isSuccessLogo: 'success',
      formInputs: []
    }
];

export const returnSteps = [
  {
    step: 1,
    isSuccess: false,
    description: '',
    isSuccessLogo: '',
    formInputs: [
      {
        inputType: 'text',
        label: 'Customer Reference',
        name: 'customerRef',
        isValidate: true,
        placeholder: 'Customer Reference'
      },
      {
        inputType: 'text',
        label: 'First Name',
        name: 'firstname',
        isValidate: true,
        placeholder: 'First Name'
      },
      {
        inputType: 'text',
        label: 'Last Name',
        name: 'lastname',
        isValidate: true,
        placeholder: 'Last Name'
      },
      {
        inputType: 'email',
        label: 'Email',
        name: 'email',
        isValidate: true,
        placeholder: 'Email'
      },
      {
        inputType: 'text',
        label: 'Phone',
        name: 'phone',
        isValidate: true,
        placeholder: 'Phone'
      },
      {
        inputType: 'reasons',
        label: 'Reasons',
        name: 'reason',
        isValidate: true,
        placeholder: 'Reasons'
      }
    ]
  },
  {
    step: 2,
    isSuccess: true,
    description: 'You have lodge a new return request.',
    isSuccessLogo: 'success',
    formInputs: []
  }
];

export const loginSteps = [
  {
    step: 1,
    isSuccess: false,
    description: '',
    isSuccessLogo: '',
    formInputs: [
      {
        inputType: 'text',
        label: 'Username',
        name: 'email',
        isValidate: true,
        placeholder: 'Username'
      },
      {
        inputType: 'password',
        label: 'Password',
        name: 'password',
        isValidate: true,
        placeholder: 'Password'
      },
      {
        inputType: 'text-staff',
        label: 'Staff Verification',
        name: 'staff',
        isValidate: true,
        placeholder: 'Staff Verification'
      }
    ]
  }
]

export const forgotPasswordStep = [
  {
    step: 1,
    isSuccess: false,
    description: '',
    isSuccessLogo: '',
    formInputs: [
      {
        inputType: 'text',
        label: 'Account Name / Email Address',
        name: 'accountName',
        isValidate: true,
        placeholder: 'Account Name / Email Address'
      }
    ]
  },
  {
    step: 2,
    isSuccess: true,
    description: 'We have sent an email, follow the instructions to retrieve your account.',
    isSuccessLogo: 'success',
    formInputs: []
  }
]

export const changePasswordStep = [
  {
    step: 1,
    isSuccess: false,
    description: '',
    isSuccessLogo: '',
    formInputs: [
      {
        inputType: 'password',
        label: 'Password',
        name: 'password',
        isValidate: true,
        placeholder: 'Password (+6 characters)'
      },
      {
        inputType: 'confirm_password',
        label: 'Confirm Password',
        name: 'confirm_password',
        isValidate: true,
        placeholder: 'Confirm Password'
      }
    ]
  },
  {
    step: 2,
    isSuccess: true,
    description: '',
    isSuccessLogo: 'success-pw',
    formInputs: []
  }
]

export const blankFilter:FilterObject = {
  brands: [],
  categories: [],
  devices: []
}

export type FormStep = {
  step: number;
  formInputs: Array<FormInput>;
  isSuccess: boolean;
  description: string;
  isSuccessLogo?: string;
}

export type FormInput = {
  inputType: string;
  name: string;
  label: string;
  placeholder: string;
  isValidate: boolean;
  subInput?: Array<FormInput>;
}

export type Location = {
  from: string
}

export type ProductObject = {
  id: string;
  name: string;
  value: string;
  image: string;
  bigImage: string;
  logo: string;
  link: string;
  price: number;
  rrprice: number;
}

export interface CartProductObject extends ProductExt {
  qty: number;
}

export type ProductDetailObject = {
  status: string;
  cellentId: string;
  delivery: string;
  qty: number;
  overview: string;
  imageList: Array<string>;
}

export type MenuGroup = {
  name: string;
  image?: string;
  imageMobile?: string;
  menuItems: Array<MenuObject>;
  logo?: string;
  link: string;
}

export type MenuObject = {
  name: string;
  link?: string;
  hasChild: boolean;
  childMenu?: Array<MenuGroup>;
}

export type OrderLine = {
  value: string;
  name: string;
  qty: number;
  price: number;
  image: string;
  link: string;
}

export type OrderObject = {
  name: string;
  total: number;
  status: string;
  orderDate: string;
  orderLines: Array<OrderLine>;
}

export type BrandDeviceCateObject = {
  name: string;  
  link: string;
  image: string;
  logo: string;
  subList?: Array<SubObject>;
}

export type SubObject = {
  name: string;
  link: string;
  products: Array<ProductExt>
}

export type FilterObject = {
  brands: Array<string>;
  categories: Array<string>;
  devices: Array<string>;
}

export type CartState = {
  cartItems: Array<ShoppingCartline>,
  itemCount: number,
  shoppingCart?: ShoppingCartsMessage,
  displayModal?: boolean,
  approvalCartId?: string,
}

export type ProductState = {
  brands: Array<BrandDeviceCateObject>,
  categories: Array<BrandDeviceCateObject>,
  deviceVendors: Array<BrandDeviceCateObject>,
  clearance: Array<CartProductObject>,
  new: Array<CartProductObject>,
  special: Array<CartProductObject>, 
  topSeller: Array<CartProductObject>, 
  products: Array<CartProductObject>,
  favourites: Array<string>,
  highlightedBrands: Array<string>,
  loading: boolean
}

export type UserState = {
  currentCustomer: Customer,
  currentPayer: Customer,
  currentUserAccount: UserAccountExt,
  userAccounts: UserAccountExt[],
  customers: Customer[],
  payers: Customer[],
  managedUserAccounts: UserAccountExt[],
  managedUserProfiles: UserProfile[],
  account: AccountMessage,
  returns: ReturnsMessage,
  recentActivities: RecentActivity[],
  notifications: Array<AlertObj>,
  userProfile: MyUserProfile,
  addresses: Array<Address>,
  homeContent: string,
  dataFeeds: DataFeedMessage,
  preferences: PreferencesMessage
}

export interface InvoiceObj extends Invoice {
  loading?: boolean,
  balance?: number
}

export interface MyUserProfile extends UserProfile {
  businessGroup: string
}

export interface UserAccountObj extends UserAccountExt {
  loading?: boolean
}

export interface AlertObj extends Alert {
  isRead?: boolean
}