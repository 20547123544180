import React from 'react';
import { Row, Col, Affix } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import ProductList from '../../components/ProductList';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import FilterBar from '../../components/FilterBar';
import { blankFilter, BrandDeviceCateObject, FilterObject, tabletSize } from '../../Constants';
import { formatText, useWindowSize, calcMargin } from '../../utils';
import { ProductContext } from '../../contexts/ProductContext';
import { ProductExt } from '../../RESTAPI';
import _ from 'lodash';

const topOffsetDefault = 158;
const topOffsetMobileDefault = 64;

const CategoryPage: React.FC = () => {
    const [sortingOrder, setSortingOrder] = React.useState<null | 'price-asc' | 'price-desc' | 'mag-asc' | 'mag-desc' | 'name-asc' | 'name-desc' | 'date-asc' | 'date-desc'>(null);
    const [currentProductList, setCurrentProductList] = React.useState<Array<ProductExt>>([]);
    const {productState} = React.useContext(ProductContext);

    const {cateId, subId} = useParams<{cateId: string, subId: string}>();
    const location = useLocation();

    const [filterList, setFilterList] = React.useState<FilterObject>(blankFilter);
    let title = '';

    React.useEffect(() => {
        if(productState.products.length > 0) {
            let tempBrandProducts = [] as Array<ProductExt>;
            let tempCateProducts = [] as Array<ProductExt>;
            let tempDeviceProducts = [] as Array<ProductExt>;
            let arrayOfArrays:Array<Array<ProductExt>> = [];
            let tempList = [] as Array<ProductExt>;
            
            let masterList:Array<BrandDeviceCateObject> = [];
            let cateList:BrandDeviceCateObject | undefined;

            // with brand products
            if(location.pathname.includes('brands')) {
                masterList = productState.brands;
            } else if(location.pathname.includes('categories')) {
                masterList = productState.categories;
            } else if(location.pathname.includes('device-vendors')) {
                masterList = productState.deviceVendors;
            }

            if(masterList.length > 0) {
                masterList.forEach(list => {
                    list.subList?.forEach(subList => {
                        tempList = [...new Set([...tempList, ...subList.products])];
                    });
                });
            } else {
                if(location.pathname.includes('top-sellers')) {
                    tempList = productState.topSeller;
                } else if(location.pathname.includes('clearance')) {
                    tempList = productState.clearance;
                } else if(location.pathname.includes('new')) {
                    tempList = productState.new;
                } else if(location.pathname.includes('special')) {
                    tempList = productState.special;
                } else {
                    tempList = productState.products;
                }
            }
            
            if(cateId) {
                // reset tempList
                tempList = [];
                // get list for cateId
                cateList = _.find(masterList, (list) => {
                    return formatText(list.link) === cateId;
                })
                if(cateList) {
                    cateList.subList?.forEach(list => {
                        tempList = [...new Set([...tempList, ...list.products])];
                    });
                }
            }

            if(subId) {
                // reset tempList
                tempList = [];

                // get list for sublist
                let subList = _.find(cateList?.subList, (list) => {
                    return formatText(list.link) === subId;
                })

                if(subList) {
                    tempList = [...new Set([...tempList, ...subList.products])];
                }
            }
            
            if(filterList !== blankFilter) {
                if(filterList.brands.length === 0 && filterList.categories.length === 0 && filterList.devices.length === 0) {
                    tempList = [...tempList]; // nothing change
                } else {
                    let brandList:Array<BrandDeviceCateObject>;
                    let cateList:Array<BrandDeviceCateObject>;
                    let deviceList:Array<BrandDeviceCateObject>;
                    if(filterList.brands.length > 0) {
                        brandList = productState.brands.filter(value => 
                            filterList.brands.includes(value.name)
                        )

                        brandList.forEach(brand => {
                            brand.subList?.forEach(subBrand => {
                                tempBrandProducts = [...new Set([...tempBrandProducts, ...subBrand.products])];
                            })
                        });

                        //if(tempBrandProducts.length > 0) {
                            arrayOfArrays.push(tempBrandProducts);
                        //}
                    }
                    
                    if(filterList.categories.length > 0) {
                        cateList = productState.categories.filter(value => 
                            {
                                // check if the subcate name is inside the filter
                                if(value.subList) {
                                    const temSub = value.subList.filter(sub => 
                                        filterList.categories.includes(value.name + ' · ' + sub.name)
                                    )
                                    
                                    if(temSub.length > 0) {
                                        return true
                                    } else {
                                        if(filterList.categories.includes(value.name)) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    }
                                    
                                } else {
                                    return filterList.categories.includes(value.name)
                                }
                            }
                        )
                        cateList.forEach(cate => {
                            cate.subList?.forEach(subCate => {
                                if(filterList.categories.includes(cate.name + ' · ' + subCate.name)) {
                                    tempCateProducts = [...new Set([...tempCateProducts, ...subCate.products])];
                                } else {
                                    if(filterList.categories.includes(cate.name)) {
                                        tempCateProducts = [...new Set([...tempCateProducts, ...subCate.products])];
                                    }
                                }
                            })
                        });

                        //if(tempCateProducts.length > 0) {
                            arrayOfArrays.push(tempCateProducts);
                        //}
                    }
                    
                    if(filterList.devices.length > 0) {
                        deviceList = productState.deviceVendors.filter(value => 
                            {
                                // check if the subcate name is inside the filter
                                if(value.subList) {
                                    const temSub = value.subList.filter(sub => 
                                        filterList.devices.includes(value.name + ' · ' + sub.name)
                                    )
                                    if(temSub.length > 0) {
                                        return true
                                    } else {
                                        if(filterList.devices.includes(value.name)) {
                                            return true
                                        } else {
                                            return false
                                        }
                                    }
                                    
                                } else {
                                    return filterList.devices.includes(value.name)
                                }
                            }
                        )
                        deviceList.forEach(device => {
                            device.subList?.forEach(subDevice => {
                                if(filterList.devices.includes(device.name + ' · ' + subDevice.name)) {
                                    tempDeviceProducts = [...new Set([...tempDeviceProducts, ...subDevice.products])];
                                } else {
                                    if(filterList.devices.includes(device.name)) {
                                        tempDeviceProducts = [...new Set([...tempDeviceProducts, ...subDevice.products])];
                                    }
                                }
                            })
                        });

                        //if(tempDeviceProducts.length > 0) {
                            arrayOfArrays.push(tempDeviceProducts);
                        //}
                    }

                    // get products that satisfy all filter criteria
                    arrayOfArrays.push(tempList);

                    if(arrayOfArrays.length > 1) {
                        let temp = _.intersectionWith(...arrayOfArrays, _.isEqual);
                        tempList = temp;
                    }
                    
                }   
            }

            if(sortingOrder !== null) {
                let sorting = sortingOrder.split('-');
                let ordering:boolean | 'asc' | 'desc' = 'asc';

                if(sorting.length > 1) {
                    switch (sorting[1]) {
                        case 'asc':
                            ordering = 'asc';
                            break;

                        case 'desc':
                            ordering = 'desc';
                            break;
                    
                        default:
                            ordering = 'asc';
                            break;
                    }

                    if(sorting.includes('mag')) {
                        tempList = _.orderBy(tempList, item => calcMargin(item.retailPrice, item.priceBreakInfo.priceBreaks[0].price),[ordering]);
                    } else if(sorting.includes('name')) {
                        tempList = _.orderBy(tempList, ['product.name'],[ordering]);
                    } else if(sorting.includes('date')) {
                        tempList = _.orderBy(tempList, ['product.registeredDate'],[ordering]);
                    } else {
                        tempList = _.orderBy(tempList, ['priceBreakInfo.priceBreaks[0].price'],[ordering]);
                    }
                }
            }
            
            setCurrentProductList(tempList);
        }
    }, [productState.products, productState.brands, productState.categories, productState.deviceVendors, productState.clearance, productState.new, productState.special, location, filterList, sortingOrder, cateId, subId])
    
    const size = useWindowSize();

    const handleFilterList = (filterList: FilterObject) => {
        setFilterList(filterList);
    }

    const handleSortingOrder = (value: any) => {
        setSortingOrder(value);
    }

    if(location.pathname.includes('top-sellers')) {
        title = 'Shop Top Sellers';
    } else if(location.pathname.includes('clearance')) {
        title = 'Shop Clearance';
    } else if(location.pathname.includes('new')) {
        title = 'Shop New';
    } else if(location.pathname.includes('special')) {
        title = 'Shop Special';
    } else {
        title = 'Shop All';
    }
    let filterTitle = '';

    if(filterList.brands.length > 0 || filterList.categories.length > 0 || filterList.devices.length > 0 ) {
        filterTitle = '';
        filterList.brands.forEach((value) => {
            if(filterTitle === '') {
                filterTitle += value;
            } else {
                filterTitle += ' · ' + value;
            }
        })

        filterList.categories.forEach((value) => {
            if(filterTitle === '') {
                filterTitle += value;
            } else {
                filterTitle += ' · ' + value;
            }
        })

        filterList.devices.forEach((value) => {
            if(filterTitle === '') {
                filterTitle += value;
            } else {
                filterTitle += ' + ' + value;
            }
        })
    }

    title = title + (filterTitle.length > 0 ? (' - ' + filterTitle) : '');
    if(location.pathname.includes(':cateId')) {
        return  <Redirect
            to={{
                pathname: "/shop"
            }}
        />;
    } else {
        return (
            <MainLayout>
                <div className='content-wrapper marginBottom40px'>
                    <Row gutter={30}>
                        <Col span={24}>
                            <h2 className='headerTitle textCenter marginTop80px'>{title}</h2>
                            <Affix offsetTop={size.width > tabletSize ? topOffsetDefault : topOffsetMobileDefault} className='changeBackgroundGray'>
                                <FilterBar handleFilterList={handleFilterList} handleSorting={handleSortingOrder}/>
                            </Affix>
                            <ProductList loading={productState.loading} filter productList={currentProductList} title=''/>
                        </Col>
                    </Row>
                </div>
            </MainLayout>
        );
    }
    
}

export default CategoryPage;
