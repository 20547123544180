import React from 'react';
import { Carousel, Image, Button } from 'antd';
import { ProductExt } from '../RESTAPI';
import { imageUrl } from '../Constants';
import { getImage } from '../utils';
import { useHistory } from 'react-router-dom';

const HeroSlider: React.FC<{productList: Array<ProductExt>}> = ({productList = []}) => {
    const history = useHistory();
    return (
        <Carousel autoplay swipeToSlide={true} touchMove={true} accessibility={true} draggable={true} className='heroSlider'>
            {
                productList.map((product, key) => {
                    if(key < 5) {
                        return (
                            <div key={key} className='content-wrapper'>
                                <div className='slideItem'>
                                    <div className='flex1 marginLeftRigh22px'>
                                        <Image preview={false} src={imageUrl + product.product.image}/>
                                    </div>
                                    <div className='flex1 marginLeftRigh22px'>
                                        <Image className='productLogo' preview={false} src={getImage(product.product.vendorName, 'brands', '160x160')}/>
                                        <h2>{product.product.name}</h2>
                                        <p>{product.product.shortName}</p>
                                        <Button htmlType="submit" type="primary" className='minWidth230px' onClick={() => history.push('/shop/' + product.product.name)}>Shop Now</Button>
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return null;
                    }
                })
            }
        </Carousel>
    );
}

export default HeroSlider;
