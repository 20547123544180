import React from 'react';
import { Row, Col, Typography } from 'antd';
import MainLayout from '../../layouts/MainLayout';

const { Paragraph, Title } = Typography;

const DeliveryRates: React.FC = () => {
  return (
    <MainLayout>
        <div className='content-wrapper marginBottom40px' style={{maxWidth: '500px'}}>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <h2 className='headerTitle textCenter marginTop20px'>Returns, Refunds and Repairs Policy (ACL)</h2>
                <Paragraph>
                          Our goods come with guarantees that cannot be excluded under the Australian Consumer Law (ACL). You are entitled to a replacement or refund for a major failure and for compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the goods repaired or replaced if the goods fail to be of acceptable quality and the failure does not amount to a major failure. In the unlikely event your good is defective you may choose to make a claim under the ACL or, as applicable, the manufacturer’s warranty. The manufacturer’s warranty operates alongside and in addition to the ACL.
                </Paragraph>
                <Paragraph>
                          Under the ACL, the remedy you are entitled to if a good fails to meet a consumer guarantee depends on whether the failure to comply with the guarantee is a major or minor failure. Major failures cannot ordinarily be fixed or resolved easily. Minor failures can normally be fixed or resolved in a reasonable period of time; a seller can choose to offer you a refund, replacement or repair (or resupply, in respect of services). If a seller does not resolve the failure or takes too long, you are entitled to engage a third party to fix the problem and recover the costs from the seller depending on the circumstances.
                </Paragraph>

                <Title level={5}>Returns & Refunds</Title>
                <Paragraph>Cellnet Group Ltd can refund or replace a returned good if the item:
                <ul>
                    <li>has a major failure;</li>
                    <li>is unsafe;</li>
                    <li>is significantly different from the description, or sample;</li>
                    <li>has a problem that would have stopped you from buying the item if you had known about it;</li>
                    <li>does not do what we said it would, or what you asked for and cannot be easily fixed.<br /> </li>
                </ul>
                </Paragraph>
                <Paragraph>A remedy may not be available (other than at the customer&rsquo;s expense and/or at the discretion of Cellnet Group Limited) where a good has been damaged through misuse, abnormal or negligent use; where a customer changes their mind or discovers the goods are cheaper elsewhere; and where damage or loss occurs independent of Cellnet Group Limited&rsquo;s business.</Paragraph>

                <Title level={5}>Repairs</Title>
                <Paragraph>
                    If a good fails to be of acceptable quality and the failure does not amount to a major failure, Cellnet Group Limited can repair or replace it within a reasonable time. Refurbished parts may be used in the repair. Sometimes, instead of repair, Cellnet Group Limited may replace your faulty item with refurbished goods of the same type. Personal data should always be backed up.
                </Paragraph>

                <Title level={5}>Proof of Purchase</Title>
                <Paragraph>
                    Proof of purchase is required for returned goods; not limited to a tax invoice or receipt. Faulty goods should be returned directly to Cellnet Group Limited (see contact us information).
                </Paragraph>
                <Paragraph>
                    For more information on consumer guarantees, please visit: <a href='https://www.consumerlaw.gov.au' target='_blank' rel='noreferrer'>www.consumerlaw.gov.au</a>
                </Paragraph>

                <Title level={5}>Terms and Conditions – Damaged and Faulty Product.</Title>
                <Paragraph>Only stock supplied by Cellnet can be processed. Items returned, but not supplied by Cellnet, may not be returned to the reseller.</Paragraph>
                <Paragraph>Return Authority must be actioned &amp; returned to the Cellnet Service &amp; Returns facility within 14 days of approval or the Return Authority expires.</Paragraph>
                <Paragraph>You must clearly display the Return Authorisation (RA) form on the outside of each carton, or the Return Authority will be rejected.</Paragraph>
                <Paragraph>The issuing of a Return Authorisation number (RA#) is not a guarantee that stock will be approved for credit. Cellnet is not liable for damage or loss in transit.</Paragraph>
                <Paragraph>By accepting the RA number, you agree to the above terms and conditions.</Paragraph>

                <Title level={5}>Terms and Conditions – Aged Stock Rotation</Title>
                <Paragraph>Only stock supplied by Cellnet can be processed. Items returned, but not supplied by Cellnet, may not be returned to the reseller.</Paragraph>
                <Paragraph>Rotation requests apply only to OEM Branded cases, screen guards and form specific stock and all &ldquo;3SixT Designed For Life&rdquo; branded product that is more than 30 days old and less than 90 days old fromreseller purchase date. Customers are only eligible for a maximum of 1 rotation per month.</Paragraph>
                <Paragraph>A replacement order of greater value (150% of Return value) is required to be placed within 48 hours before your Return Authorisation request will be assessed. OEM genuine brands, including but not limited to; Apple Accessories (incl AirBuds), Beats, Google, Huawei, Logitech (UE), Oppo, Samsung</Paragraph>
                <Paragraph>(incl Buds) and Sennheiser will all be firm sale/non-rotatable. These brands cannot be used on the replacement order.</Paragraph>
                <Paragraph>Return Authority must be actioned &amp; returned to the Cellnet Service &amp; Returns facility within 14 days of approval or the Return Authority expires.</Paragraph>
                <Paragraph>You must clearly display the Return Authorisation (RA) form on the outside of each carton, or the Return Authority will be rejected.</Paragraph>
                <Paragraph>Goods returned must be in resaleable condition on arrival with all Manufacturer&rsquo;s labels intact and properly packed to avoid damage. Note: Soiled product i.e. packaging with reseller stickers, labels attached, damaged and/or tampered packaging is deemed unsaleable to qualify for a credit.</Paragraph>
                <Paragraph>Freight must be pre-paid and organised by the reseller or the return will be rejected by Cellnet on arrival.</Paragraph>
                <Paragraph>The issuing of a Return Authorisation number (RA#) is not a guarantee that stock will be approved for credit. Cellnet is not liable for damage or loss in transit.</Paragraph>
                <Paragraph>By accepting the RA number, you agree to the above terms and conditions.</Paragraph>
            </Col>
        </Row>
      </div>
    </MainLayout>
  );
}

export default DeliveryRates;
