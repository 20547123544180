import React from 'react';
import { Row, Col, Divider, Image, message, Popover } from 'antd';
import { dateStringFormat, formatCurrency, useWindowSize, totalReturnQty, getBreakPrice } from '../../../utils';
import { imageUrl, returnStatus, tabletSize } from '../../../Constants';
import { Link, useParams, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { DownOutlined } from '@ant-design/icons';
import { ServiceMainRESTClient, ReturnsMessage, ReturnRequest, ReturnRequestLine, MethodCallback, DeleteReturnMessage, RecentActivityMessage} from '../../../RESTAPI';
import { ProductContext } from '../../../contexts/ProductContext';
import _ from 'lodash';
import { UserContext } from '../../../contexts/UserContext';
import MainLayout from '../../../layouts/MainLayout';
import { logout } from '../../../elements/PrivateRoute/PrivateRoute';

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const SingleReturn: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState<boolean>();
    const [myReturn, setReturn] = React.useState<ReturnRequest>();
    const [returnLine, setReturnLine] = React.useState<ReturnRequestLine[]>();
    const [address, setAddress] = React.useState<string>();
    const [deleteVisible, setDeleteVisible] = React.useState<number>(0);
    const size = useWindowSize();

    const { returnno } = useParams<{ returnno: string }>();

    const { productState } = React.useContext(ProductContext);
    const { userState, setReturns, setRecentActivities } = React.useContext(UserContext);

    React.useEffect(() => {
        // get order
        if (userState.returns) {
            const currentReturn = _.filter(userState.returns.returns, (r) => {
                return r.requestno === parseInt(returnno);
            });

            if (currentReturn.length > 0) {
                const returnRequest = currentReturn[0];
                setReturn(returnRequest);

                if (userState.returns.lines) {
                    setReturnLine(userState.returns.lines[returnRequest.requestno]);
                }
                
                if (returnRequest.address1.length > 0) {
                    // setting up address
                    setAddress(returnRequest.address1 + ' ' + returnRequest.address2 + ' ' + returnRequest.address3 + ', ' + returnRequest.suburb + ', ' + returnRequest.state + ' ' + returnRequest.postcode);
                }
            }

        }
    }, [userState.returns, returnno])

    React.useEffect(() => {
        if(myReturn) {
            const RecentActivityCallback: MethodCallback<RecentActivityMessage> = {
                onFailure(error: string): void {
                    alert(error);
                },
                onProgress(loaded: number, total: number): void { },
                onSuccess(returnMessage: RecentActivityMessage, context?: any): void {
                    if (!returnMessage.authenticated) {
                        logout();
                    } else {
                        setRecentActivities(returnMessage.recentActivities);
                    }
                }
            }

            restClient.addActivityViewReturn(myReturn.requestno, RecentActivityCallback);
        }
    }, [myReturn])

    const handleDeleteVisible = () => {
        if (myReturn) {
            if (deleteVisible === myReturn.requestno) {
                setDeleteVisible(0);
            }
        }
    }

    const handleDeleteReturn = (requestno: number) => {
        restClient.deleteIncompleteReturnRequest(requestno, deleteReturnCallback)
    }

    const ReturnCallback: MethodCallback<ReturnsMessage> = {

        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void { },
        onSuccess(message: ReturnsMessage, context: any): void {
            // if init gives authenticated as false
            // remove cookie, logout
            if (!message.authenticated) {
                logout();
            } else {
                // save something
                setReturns(message);
            }
        }
    }

    const deleteReturnCallback: MethodCallback<DeleteReturnMessage> = {
        onFailure(error: string, context: any): void {
        },

        onProgress(loaded: number, total: number): void { },

        onSuccess(returnMessage: DeleteReturnMessage, context: any): void {
            message.loading({ content: 'Deleting incomplete request...', key: 'updatable' });
            if (returnMessage.authenticated) {
                if (returnMessage.error === null || returnMessage.error === '') {
                    restClient.returnsFromServer(ReturnCallback);
                    setTimeout(() => {
                        message.success({ content: 'Deleted request!', key: 'updatable', duration: 2 });
                        setDeleteVisible(0);
                    }, 200);

                } else {
                    setTimeout(() => {
                        message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                }
            } else {
                logout()
            }
        }
    }

    if (returnno === undefined || returnno === null || returnno === ':returnno') {
        return (<Redirect
            to={{
                pathname: '/'
            }}
        />)
    }


    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'>
                <h2 className='headerTitle onlyDesktop'>Return Details: {returnno}</h2>
                <h2 className='headerTitle onlyMobile textCenter'>Return Details: {returnno}</h2>
                <div className='orderContainer' style={{padding: '20px'}}>
                    {
                        myReturn && (
                            <>
                                <div className='orderContainer'>
                                    <p className='noMarginBottom text-secondaryColor'><strong>{returnStatus[myReturn.status]}</strong></p>
                                    <Row gutter={20}>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <p>
                                                {dateStringFormat(myReturn.registeredtime)} <br />
                                                <strong>{myReturn.requestno}</strong>
                                            </p>
                                        </Col>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <p>
                                                Total Return <br />
                                                <p><strong>{totalReturnQty(returnLine)}</strong></p>
                                            </p>
                                        </Col>
                                        <Col xs={0} sm={0} md={16} lg={16} xl={16}>
                                            <div className='flexSpaceBetween'>
                                                <div>
                                                    <p>
                                                        Shipping <br />
                                                        <strong>{address}</strong>
                                                    </p>
                                                </div>
                                                <div style={{ minWidth: '145px' }}>
                                                    <p>
                                                        Reason <br />
                                                        <strong>{userState.returns.allReasonCodeToReason[myReturn.reason].name}</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <DownOutlined className='animation collapseButton onlyMobile marginTop20px' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180 : 0} />
                                    <Divider className='onlyDesktop' />
                                    <AnimatePresence initial={false}>
                                        {(isOpen || size.width >= tabletSize) && (
                                            <motion.section
                                                key="content"
                                                initial="collapsed"
                                                animate="open"
                                                exit="collapsed"
                                                variants={{
                                                    open: { opacity: 1, height: "auto" },
                                                    collapsed: { opacity: 0, height: 0 }
                                                }}
                                                transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                                            >
                                                {
                                                    returnLine && returnLine.map((line, key) => {
                                                        const product = _.find(productState.products, (p) => { return line.itemno === p.product.id });

                                                        if (product) {
                                                            const currentPrice = getBreakPrice(product.priceBreakInfo.priceBreaks, line.qty);

                                                            return (<Row gutter={15} key={key} className='alignItemCenter rowOrderLine'>
                                                                <Col xs={5} sm={5} md={2} lg={2} xl={2}>
                                                                    <Image preview={false} src={imageUrl + product.product.image} />
                                                                </Col>
                                                                <Col xs={19} sm={19} md={18} lg={18} xl={18}>
                                                                    <p className='noMarginBottom'><strong>{product.product.name}</strong></p>
                                                                    <p className='noMarginBottom'>{product.product.shortName}</p>
                                                                    <p className='noMarginBottom text-grayColor'><strong>{line.qty} pc @ {formatCurrency(currentPrice)}</strong></p>
                                                                </Col>
                                                            </Row>)
                                                        } else {
                                                            return null
                                                        }

                                                    })
                                                }
                                                <p>
                                                    <strong>Shipping</strong> <br />
                                                    {address}
                                                </p>
                                                {
                                                    myReturn.status === 'IN' && (<div className='flexSpaceBetween'><Link to={'/your-account/update-return/' + myReturn.requestno}>
                                                        Edit Returns
                                                        </Link>
                                                        <Popover
                                                            content={
                                                                <div>
                                                                    <a href='/' onClick={(e) => { e.preventDefault(); handleDeleteReturn(myReturn.requestno) }}>Delete</a>
                                                                </div>
                                                            }
                                                            title="Are you sure to delete this request?"
                                                            trigger="click"
                                                            visible={deleteVisible === myReturn.requestno}
                                                            onVisibleChange={handleDeleteVisible}
                                                        >
                                                            <Link to='/' onClick={(e) => { e.preventDefault(); setDeleteVisible(myReturn.requestno) }}>Delete</Link>
                                                        </Popover></div>)
                                                }

                                            </motion.section>)}
                                    </AnimatePresence>
                                    <div className='onlyMobile'>
                                        <p>
                                            Reason <br />
                                            <strong>{userState.returns.allReasonCodeToReason[myReturn.reason].name}</strong>
                                        </p>
                                    </div>
                                    <Divider className='onlyMobile' />
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </MainLayout>
    );

}

export default SingleReturn;
