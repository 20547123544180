import { CartState } from "../Constants"
import { ShoppingCartline, ShoppingCartsMessage } from "../RESTAPI";

type Action = {
    payload: any,
    type: string
}

export const sumItems = (cartItems: Array<ShoppingCartline>) => {
    let itemCount = 0;
    if(cartItems) {
        itemCount = cartItems.reduce((total, product) => total + product.quantity, 0);
    }
    
    return { itemCount }
}

export const CartReducer = (state: CartState, action: Action) => {
    switch (action.type) {
        case "ADD_ITEM":
            const updatedCart = [...state.cartItems];

            const updatedItemIndex = updatedCart.findIndex(
                item => item.productid === action.payload.product.id
            );

            if (updatedItemIndex < 0) {
                updatedCart.push({ ...action.payload, quantity: action.payload.qty });
            } else {
                const updatedItem = {
                  ...updatedCart[updatedItemIndex]
                };
                updatedItem.quantity += action.payload.qty;
                updatedCart[updatedItemIndex] = updatedItem;
            }

            return {
                ...state,
                ...sumItems(updatedCart),
                cartItems: updatedCart
            }
        case "SET_SHOPPING_CART":
            const message:ShoppingCartsMessage = action.payload;
            const currentShoppingCartId = message.currentShoppingCartId;
            const shoppingCartLines = message.shoppingCartIdToLines;
            
            let currentCart = [] as ShoppingCartline[];
            if(shoppingCartLines) {
                currentCart = shoppingCartLines[currentShoppingCartId];
            }
            
            return {
                ...state,
                ...sumItems(currentCart),
                cartItems: currentCart,
                shoppingCart: action.payload,
                approvalCartId: ''
            }
        case "LOAD_CURRENT_CART":
            const currentMessage:ShoppingCartsMessage = action.payload;
            const changeCart = currentMessage.shoppingCartIdToLines[currentMessage.currentShoppingCartId];
            
            return {
                ...state,
                ...sumItems(changeCart),
                cartItems: changeCart,
                approvalCartId: ''
            }
        case "LOAD_APPROVING_CART":
            const currentApproveMessage:ShoppingCartsMessage | undefined = state.shoppingCart;

            if(currentApproveMessage) {
                const approvingCart = currentApproveMessage.shoppingCartIdToLines[action.payload];
                return {
                    ...state,
                    ...sumItems(approvingCart),
                    cartItems: approvingCart,
                    approvalCartId: action.payload
                }
            }
            
            return {
                ...state
            }
            
        case "REMOVE_ITEM":
            return {
                ...state,
                ...sumItems(state.cartItems.filter(item => item.productid !== action.payload.product.id)),
                cartItems: [...state.cartItems.filter(item => item.productid !== action.payload.product.id)]
            }
        case "CLEAR":
                return {
                    cartItems: [],
                    ...sumItems([]),
                }
        case "DISPLAY CART MODAL":
            return {
                ...state,
                displayModal: true
            }
        case "HIDE CART MODAL":
            return {
                ...state,
                displayModal: false
            }
        default:
            return {...state}

    }
}