import React from 'react';
import { Breadcrumb  } from 'antd';
import { RightOutlined, ExportOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BackIcon } from '../CustomIcons/CustomIcons';
import { formatText } from '../../utils';
import { ProductContext } from '../../contexts/ProductContext';
import { BrandDeviceCateObject } from '../../Constants';
import _ from 'lodash';

const extraFunctions = [
    {
        name: 'Order by CSV',
        icon: <ExportOutlined />,
        link: '/order-by-csv'
    },
    {
        name: 'Invoices',
        icon: null,
        link: '/your-account/your-invoices'
    },
    {
        name: 'Returns',
        icon: null,
        link: '/your-account/returns'
    },
    {
        name: 'Help',
        icon: null,
        link: '/help'
    }
]

const checkBread = [
    {
        name: 'Shop',
        link: '/shop'
    },
    {
        name: 'Your Account',
        link: '/your-account'
    },
    {
        name: 'Brands',
        link: '/shop/brands'
    },
    {
        name: 'Categories',
        link: '/shop/categories'
    },
    {
        name: 'Device Vendors',
        link: '/shop/device-vendors'
    }
]

const BreadCrumbBar = () => {
    const [layerLink, setLayerLink] = React.useState<Array<{name: string, link: string}>>([]);
    const location = useLocation();
    const history = useHistory();
    const {productState} = React.useContext(ProductContext);

    React.useEffect(() => {
        if(location) {
            let masterList:Array<BrandDeviceCateObject> = [];
            let link = '';

            if(location.pathname.includes('brands')) {
                masterList = productState.brands;
                link = '/brands';
            } else if(location.pathname.includes('categories')) {
                masterList = productState.categories;
                link = '/categories';
            } else if(location.pathname.includes('device-vendors')) {
                masterList = productState.deviceVendors;
                link = '/device-vendors';
            }
            
            const setupArray:Array<{name: string, link: string}> = [];
            const layerArray = _.sortedUniq(location.pathname.split('/'));
            
            layerArray.forEach(e => {
                if(e === '') {
                    setupArray.push({
                        name: 'Home',
                        link: '/'
                    })
                } else {
                    // check bread for special
                    let checkTest = _.find(checkBread, (value) => {
                        return formatText(value.name) === e;
                    })

                    if(checkTest) {
                        setupArray.push({
                            name: checkTest.name,
                            link: checkTest.link
                        })
                    } else {
                        if(masterList.length > 0) {
                            let check = _.find(masterList, (value) => {
                                return formatText(value.link) === e
                            })

                            if(check) {
                                setupArray.push({
                                    name: check.name,
                                    link: '/shop' + link + '/' + check.link
                                })
                            } else {
                                let productExt = _.find(productState.products, (p) => {
                                    return formatText(p.product.name) === e
                                })
                                if(productExt) {
                                    setupArray.push({
                                        name: productExt.product.shortName,
                                        link: ''
                                    })
                                } else {
                                    setupArray.push({
                                        name: e.toLocaleUpperCase(),
                                        link: ''
                                    })
                                }
                            }
                        } else {
                            let productExt = _.find(productState.products, (p) => {
                                return formatText(p.product.name) === e
                            })
                            if(productExt) {
                                setupArray.push({
                                    name: productExt.product.shortName,
                                    link: ''
                                })
                            } else {
                                setupArray.push({
                                    name: e.replaceAll('-', ' '),
                                    link: ''
                                })
                            }
                        }
                    }
                }
            });
            setLayerLink(setupArray);
        }
    }, [location, productState.products, productState.brands, productState.categories, productState.deviceVendors])
    
    return (
        <div className='breadcrumb-bar'>
            <div className='content-wrapper flexSpaceBetween'>
                <div className='flexCenter'>
                    {
                        layerLink.length > 1 && <>
                        <div className='backButton' onClick={() => {history.goBack()}}>
                            <BackIcon/>
                            Back
                        </div>
                        <Breadcrumb separator={<RightOutlined/>}>
                            {
                                layerLink.map((v, k) => {
                                    if(v.link === '' || k === layerLink.length - 1) {
                                        return <Breadcrumb.Item key={k} className='capitalize'>{v.name}</Breadcrumb.Item>
                                    } else {
                                        return  <Breadcrumb.Item key={k} className='capitalize'>
                                                    <Link to={v.link}>{v.name}</Link>
                                                </Breadcrumb.Item>
                                    }
                                })
                            }
                        </Breadcrumb>
                        </>
                    }
                    
                </div>
                <div className='otherFunctions'>
                    {
                        extraFunctions.map((value, key) => {
                            return (
                                <span key={key}><Link to={value.link}>{value.icon}{value.name}</Link></span>
                            )
                        })
                    }
                </div>
            </div>
        </div>
  );
}

export default BreadCrumbBar;

    