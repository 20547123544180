import React from 'react';
import { Row, Col, Image, Button, InputNumber, Divider, Popover, Input, message, Modal, Select } from 'antd';
import MainLayout from '../../layouts/MainLayout';
import ProductListSlider from '../../components/ProductListSlider';
import { CartContext } from '../../contexts/CartContext';
import { formatCurrency, getBreakPrice } from '../../utils';
import OrderSummaryInfo from '../../components/OrderSummaryInfo';
import { Link, useHistory } from 'react-router-dom';
import { AmexIcon, MasterCardIcon, PaypalIcon, VisaIcon } from '../../elements/CustomIcons/CustomIcons';
import { ProductContext } from '../../contexts/ProductContext';
import { CheckoutMessage, FavouritesMessage, MethodCallback, ServiceMainRESTClient, ShoppingCart, ShoppingCartsMessage, UserAccess } from '../../RESTAPI';
import { imageUrl } from '../../Constants';
import _ from 'lodash';
import { logout } from '../../elements/PrivateRoute/PrivateRoute';
import { UserContext } from '../../contexts/UserContext';

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const MyShoppingCart: React.FC = () => {
    const { cartState, setShoppingCart, loadApprovingCart } = React.useContext(CartContext);
    const { userState } = React.useContext(UserContext);
    const {productState, addFavouriteProductId} = React.useContext(ProductContext);

    const [savedCarts, setSavedCarts] = React.useState<Array<ShoppingCart>>([]);
    const [approvingCarts, setApprovingCarts] = React.useState<Array<ShoppingCart>>([]);
    const [visible, setVisible] = React.useState<boolean>(false);
    const [loadVisible, setLoadVisible] = React.useState<boolean>(false);
    const [rejectVisible, setRejectVisible] = React.useState<string>('');
    const [rejectCurrentVisible, setRejectCurrentVisible] = React.useState<string>('');
    const [clearVisible, setClearVisible] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>('');

    const [approverId, setApproverId] = React.useState<string>('');
    const [approvingCartName, setApprovingCartName] = React.useState<string>('');
    const [approvingVisible, setApprovingVisible] = React.useState<boolean>(false);
    const [approverList, setApproverList] = React.useState<Array<any>>([]);

    const history = useHistory();

    React.useEffect(() => {
        if(cartState.shoppingCart?.shoppingCartIdToShoppingCart) {
            const currentSavedCarts = [] as Array<ShoppingCart>;
            const currentApprovingCarts = [] as Array<ShoppingCart>;
            const approvers = [] as Array<any>;
            Object.keys(cartState.shoppingCart.shoppingCartIdToShoppingCart).forEach(key => {
                let checkOrder = cartState.shoppingCart?.shoppingCartIdToShoppingCart[key];
                if(checkOrder) {
                    if(checkOrder.id !== cartState.shoppingCart?.currentShoppingCartId && checkOrder.status !== 'WA') {
                        currentSavedCarts.push(checkOrder)
                    } else {
                        if(checkOrder.type === 'T') {
                            currentApprovingCarts.push(checkOrder)
                        }
                    }
                }
            })

            userState.account?.orderApprovers?.forEach(approver => {
                approvers.push({
                    value: approver.id,
                    label: approver.firstname + ' ' + approver.lastname
                })
            });
    
            setSavedCarts(currentSavedCarts);
            setApprovingCarts(currentApprovingCarts);
            setApproverList(approvers);
        }
    }, [cartState.shoppingCart, userState.account])

    const handleVisibleChange = () => {
        setVisible(!visible);
    }

    const handleClearVisibleChange = () => {
        setClearVisible(!clearVisible);
    }

    const handleRejectVisibleChange = (visible: any) => {
        if(visible === false) {
            setRejectVisible('');  
        }
    }

    const handleRejectCurrentVisibleChange = (visible: any) => {
        if(visible === false) {
            setRejectCurrentVisible('');  
        }
    }

    const handleTitleChange = (e: any) => {
        const {value} = e.currentTarget;
        setTitle(value);
    };

    const handleCartNameChange = (e: any) => {
        const {value} = e.currentTarget;
        setApprovingCartName(value);
    };

    const handleEnterKey = (e: any) => {
        if (e.keyCode === 13) {
            // Cancel the default action, if needed
            e.preventDefault();
            // Trigger the button element with a click
            handleSaveCurrentCart(title);
        }
    }

    const handleClearCurrentCart = () => {
        restClient.clearCurrentShoppingCart(updateShoppingCallback);
        setClearVisible(false);
    }

    const handleRejectCart = (id: string) => {
        restClient.rejectApprovalShoppingCart(id, RejectCartCallback);
        setRejectVisible('');
    }

    const handleSetFavourite = (e:React.MouseEvent) => {
        const id = e.currentTarget.getAttribute('data-id');
        if(id) {
            // reverse from false to true
            restClient.addFavouriteProductId(id, AddFavouriteCallBack, {addFavouriteProductId, id});
        }
    }

    const handleLoadCurrentCart = () => {
        restClient.shoppingCartsFromServer(ShoppingCartCallback);
        setLoadVisible(false);
    }

    const RejectCartCallback: MethodCallback<CheckoutMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: CheckoutMessage, context: any): void {
            // set shopping cart again
            if(message.authenticated) {
                restClient.shoppingCartsFromServer(ShoppingCartCallback)
            } else {
                logout()
            }
        }
    }

    const ShoppingCartCallback: MethodCallback<ShoppingCartsMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ShoppingCartsMessage, context: any): void {
            if(message.authenticated) {
                setShoppingCart(message);
            } else {
                logout()
            }
        }
    }
    

    const AddFavouriteCallBack: MethodCallback<FavouritesMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: FavouritesMessage, context: any): void {
            context.addFavouriteProductId(context.id);
        }
    }

    const updateShoppingCallback: MethodCallback<ShoppingCartsMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(message: ShoppingCartsMessage, context: any): void {
            if(message.authenticated) {
                setShoppingCart(message);
            } else {
                logout()
            }
        }
    }

    const handleChange = (value:any, id:string) => {
        if(!isNaN(value) && value !== '' && value !== null && value > 0) {
            restClient.updateShoppingCartProductQuantity(id, value, updateShoppingCallback)
        }
    }

    const handleDelete = (id:string) => {
        restClient.updateShoppingCartProductQuantity(id, 0, updateShoppingCallback)
    }

    const handleLoadCart = (id:string) => {
        restClient.loadSavedShoppingCart(id, updateShoppingCallback);
    }

    const handleLoadCurrentVisible = (visible: any) => {
        if(visible === false) {
            setLoadVisible(false);
        }
    }

    const handleSaveCurrentCart = (name: string) => {
        let title = name;
        if(title === '') {
            title = 'Current Cart';    
        }
        restClient.saveCurrentShoppingCart(title, updateShoppingCallback);
        setVisible(false);
    }

    const handleLoadApprovingCart = (id:string) => {
        loadApprovingCart(id);
    }

    const handleGoToCheckout = () => {
        history.push('/checkout' + (cartState.approvalCartId ? '/' + cartState.approvalCartId : ''))
    }

    const setApproverCallback: MethodCallback<ShoppingCartsMessage> = {
        onFailure(error: string, context: any): void {
        },
    
        onProgress(loaded: number, total: number): void {},
    
        onSuccess(returnMessage: ShoppingCartsMessage, context: any): void {
            if(returnMessage.authenticated) {
                // return to shop
                message.loading({ content: 'Submit order for approval...', key: 'updatable' });
                if(returnMessage.error == null) {
                    if(cartState.shoppingCart && cartState.shoppingCart.currentShoppingCartId) {
                        message.success({ content: 'Submitted order!', key: 'updatable', duration: 2 });
                        setApprovingVisible(false);

                        handleLoadCurrentCart();
                        history.push('/cart-submitted/' + cartState.shoppingCart?.currentShoppingCartId)
                    } else {
                        // do nothing
                    }
                } else {
                    setTimeout(() => {
                          message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                }
                
                
            } else {
                logout()
            }
        }
    }

    const handleSubmitApproval = () => {
        if(approverId !== '' && approvingCartName !== '') { 
            restClient.setApproverForCurrentShoppingCart(approverId, approvingCartName, setApproverCallback)
        } else {
            alert('Please fill in approver name and cart name');
        }
        
    }
    
    return (
        <MainLayout>
        <div className='content-wrapper marginBottom40px marginTop60px'>
            <Row>
                <Col span={24} className='textCenter'>
                    <h2 className='headerTitle displayInlineBlock'>YOUR CART</h2> <span className='marginLeft15px'>{cartState.itemCount} item(s)</span>
                </Col>
            </Row>
            
            <Row gutter={30}>
                <Col xs={24} sm={24} md={14} lg={14} xl={14} >
                    <div className='orderDetails'>
                        {
                            (productState.products && productState.products.length > 0 && productState.favourites) &&
                                cartState.cartItems?.map((item, key) => {
                                    const productid = item.productid;
                                    const product = _.find(productState.products, (p) => {
                                        return parseInt(p.product.id) === parseInt(productid);
                                    })
                                    
                                    if(product) {
                                        const currentPrice = getBreakPrice(product.priceBreakInfo.priceBreaks, item.quantity);
                                        return (
                                            <Row gutter={[30, 30]} key={key}>
                                                <Col xs={6} sm={6} md={6} lg={4} xl={4}>
                                                        {
                                                            product.product.image && product.product.image !== '' ?  <Link to={'/shop/' + product.product.name}><Image fallback={'https://via.placeholder.com/FFFFFF/000000/200x200.png?text=' + product.product.name} preview={false} src={imageUrl + (product.product ? product.product.image : '')}/></Link> : ''
                                                        }
                                                </Col>
                                                <Col xs={18} sm={18} md={18} lg={20} xl={20}>
                                                    <Row gutter={20}>
                                                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                                            <h3 className='fontSize18px noMarginBottom'><Link to={'/shop/' + product.product.name}><strong>{product.product.name}</strong></Link></h3>
                                                            <p className='fontSize16px'>{product.product.shortName}</p>
                                                            <Link to='' onClick={(e) => {e.preventDefault(); handleDelete(product.product.id)}} className='fontSize16px linkUnderline'>Delete</Link>
                                                            {/*<Link to='' className='fontSize16px linkUnderline marginLeft20px'>Save for later</Link>*/}
                                                            {
                                                                !productState.favourites.includes(product.product.id) &&
                                                                <Link to='' data-id={product.product.id} onClick={(e) => {e.preventDefault(); handleSetFavourite(e)}} className='fontSize16px linkUnderline marginLeft20px'>Add to Favourites</Link>
                                                            }
                                                            
                                                        </Col>
                                                        <Col xs={0} sm={0} md={5} lg={5} xl={5}>
                                                            <InputNumber onChange={(value) => {handleChange(value, product.product.id)}} className='qty height50px' size="large" min={1} max={100000} value={item.quantity} />
                                                        </Col>
                                                        <Col xs={0} sm={0} md={5} lg={5} xl={5} className='textAlignRight'>
                                                            <p className='fontSize16px text-grayColor'>
                                                                {formatCurrency(currentPrice)} each<br/>
                                                                <strong>{formatCurrency(currentPrice * item.quantity)}</strong>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )
                                    } else {
                                        return null
                                    }
                                    
                                })
                        }
                        <div className='textAlignRight'>
                            {
                                cartState.approvalCartId ? (<><Popover
                                    content={
                                        <div>
                                            <a href='/' onClick={(e) => {e.preventDefault();handleLoadCurrentCart()}}>Load</a>
                                        </div>
                                    }
                                    title="Are you sure to load your current cart?"
                                    trigger="click"
                                    visible={loadVisible}
                                    onVisibleChange={handleLoadCurrentVisible}
                                >
                                    <Link to='/' onClick={(e) => {e.preventDefault();setLoadVisible(true)}} className='fontSize16px linkUnderline marginRight20px'>Load Current Cart</Link>
                                </Popover>
                                <Popover
                                    content={
                                        <div>
                                            <a href='/' onClick={(e) => {e.preventDefault();handleRejectCart(cartState.approvalCartId ? cartState.approvalCartId : '')}}>Reject</a>
                                        </div>
                                    }
                                    title="Are you sure to reject this cart?"
                                    trigger="click"
                                    visible={rejectCurrentVisible === cartState.approvalCartId}
                                    onVisibleChange={handleRejectCurrentVisibleChange}
                                >
                                    <Link to='' onClick={(e) => {e.preventDefault(); setRejectCurrentVisible(cartState.approvalCartId? cartState.approvalCartId : '')}} className='fontSize16px linkUnderline'>Reject Cart</Link>
                                </Popover>

                                </>):(<><Popover
                                    content={
                                        <div>
                                            <a href='/' onClick={(e) => {e.preventDefault();handleClearCurrentCart()}}>Clear</a>
                                        </div>
                                    }
                                    title="Are you sure to clear the current cart?"
                                    trigger="click"
                                    visible={clearVisible}
                                    onVisibleChange={handleClearVisibleChange}
                                >
                                    <Link to='/' onClick={(e) => {e.preventDefault();setClearVisible(true)}} className='fontSize16px linkUnderline marginRight20px'>Clear Current Cart</Link>
                                </Popover>

                                <Popover
                                    content={
                                        <div>
                                            <Input placeholder='Name Current Cart for Save' value={title} onChange={handleTitleChange} onKeyDown={handleEnterKey}/>
                                            <Divider/>
                                            <a href='/' onClick={(e) => {e.preventDefault();handleSaveCurrentCart(title)}}>Save</a>
                                        </div>
                                    }
                                    title="Shopping Cart Name"
                                    trigger="click"
                                    visible={visible}
                                    onVisibleChange={handleVisibleChange}
                                >
                                    <Link to='/' onClick={(e) => {e.preventDefault();setVisible(true)}} className='fontSize16px linkUnderline'>Save Current Cart</Link>
                                </Popover></>)
                            }
                            
                        </div>
                        <Divider/>
                        <h2 className='displayInlineBlock'><strong>SAVED CART</strong></h2>
                        {
                            savedCarts.map((c, k) => (
                                <Row gutter={[30, 30]} key={k}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <h3><strong>{c.name}</strong></h3>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Link to='' onClick={(e) => {e.preventDefault(); handleLoadCart(c.id)}} className='fontSize16px linkUnderline'>Load Cart</Link>
                                    </Col>
                                </Row>
                            ))
                        }
                        {
                            userState.currentUserAccount && userState.currentUserAccount.permissions.includes(UserAccess.APPROVE_ORDERS) && (
                                <>
                                <Divider/>
                                <h2 className='displayInlineBlock'><strong>APPROVING CARTS</strong></h2>
                                {
                                    approvingCarts.map((c, k) => (
                                        <Row gutter={[30, 30]} key={k}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <h3><strong>{c.name}</strong></h3>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className='flexSpaceBetween'>
                                                <Link to='' onClick={(e) => {e.preventDefault(); handleLoadApprovingCart(c.id)}} className='fontSize16px linkUnderline'>Checkout Cart</Link>
                                                <Popover
                                                    content={
                                                        <div>
                                                            <a href='/' onClick={(e) => {e.preventDefault();handleRejectCart(c.id)}}>Reject</a>
                                                        </div>
                                                    }
                                                    title="Are you sure to reject this cart?"
                                                    trigger="click"
                                                    visible={rejectVisible === c.id}
                                                    onVisibleChange={handleRejectVisibleChange}
                                                >
                                                    <Link to='' onClick={(e) => {e.preventDefault(); setRejectVisible(c.id)}} className='fontSize16px linkUnderline'>Reject Cart</Link>
                                                </Popover>
                                            </Col>
                                        </Row>
                                    ))
                                }
                                </>
                            )
                        }
                        
                    </div>
                </Col>
                <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <div className='orderSummary'>
                        <h2 className='headerTitle'>ORDER SUMMARY</h2>
                        <OrderSummaryInfo shippingPrice={0} adminFee={0}/>
                        {
                            (cartState.cartItems && cartState.cartItems.length > 0) && (
                                userState.currentUserAccount && userState.currentUserAccount.permissions.includes(UserAccess.REQUIRE_ORDER_APPROVAL) ? 
                                <>
                                    <Button htmlType='button' block type="primary" className='marginBottom20px' onClick={() => setApprovingVisible(true)}>SUBMIT FOR APPROVAL</Button>
                                    <Modal title="Selecting Approver" centered visible={approvingVisible} okText='Submit' onOk={handleSubmitApproval} onCancel={() => setApprovingVisible(false)}>
                                        <p><strong>Approver</strong></p>
                                        <Select onSelect={(option: string) => setApproverId(option)} className='fullWidth' showSearch={true} dropdownMatchSelectWidth={false} size='large' allowClear placeholder="Please select an approver" options={approverList}/>
                                        <p></p>
                                        <p><strong>Cart Name</strong></p>
                                        <Input size='large' placeholder='Name Current Cart for Approval' value={approvingCartName} onChange={handleCartNameChange}/>
                                    </Modal>
                                </>
                                : 
                                <Button htmlType='button' block type="primary" className='marginBottom20px' onClick={handleGoToCheckout}>CHECKOUT</Button>
                            )
                            
                        }
                        
                        <p className='fontSize16px'>We accept</p>
                        
                        <div className='displayInlineBlock'>
                            <PaypalIcon/>
                        </div>

                        <div className='displayInlineBlock marginLeft20px'>
                            <MasterCardIcon/>
                        </div>

                        <div className='displayInlineBlock marginLeft20px'>
                            <VisaIcon/>
                        </div>

                        <div className='displayInlineBlock marginLeft20px'>
                            <AmexIcon/>
                        </div>
                    </div>
                    
                    <div className='padding20px '>
                        <h3 className='headerTitle fontSize18px'>NEED HELP?</h3>
                        <Link to='/faqs' className='fontSize18px linkUnderline marginBottom15px'>FAQs</Link><br/>
                        <Link to='/returns-policy' className='fontSize18px linkUnderline marginBottom15px'>Returns, Refunds & Repairs Policy</Link><br/>
                        <Link to='/contact-us' className='fontSize18px linkUnderline'>Contact us</Link>
                    </div>
                    
                </Col>
            </Row>
        </div>

        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='marginBottom40px'>
                {<ProductListSlider mobile productList={productState.special} title='Featured Products'/>}
            </Col>
        </Row>
        </MainLayout>
    );
}

export default MyShoppingCart;
