import React from 'react';
import { Row, Col, Image } from 'antd';
import { dateStringFormat, formatCurrency, totalQty } from '../../utils';
import { motion, AnimatePresence } from "framer-motion";
import { DownOutlined } from '@ant-design/icons';
import { Order, OrderLine } from '../../RESTAPI';
import { imageUrl, orderNoStatus } from '../../Constants';
import { ProductContext } from '../../contexts/ProductContext';
import _ from 'lodash';

const OrderStatusObject: React.FC<{order: Order, orderLine: Array<OrderLine> | undefined}> = ({order, orderLine}) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const {productState} = React.useContext(ProductContext);

    return (
        <div className='order'>
            <p className='noMarginBottom text-secondaryColor'><strong>{orderNoStatus[order.lowstatus]}</strong></p>
            <Row gutter={15}>
                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                    {dateStringFormat(order.orderdate)}
                    <p><strong>{order.orderno}</strong></p>
                </Col>
                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                    Total
                    <p><strong>{formatCurrency(order.baseprice)} - {totalQty(orderLine)}</strong></p>
                </Col>
            </Row>
            <DownOutlined className='animation collapseButton' onClick={() => setIsOpen(!isOpen)} rotate={isOpen ? 180: 0} />
            <AnimatePresence initial={false}>
                {isOpen && (
                <motion.section
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                    {
                        orderLine !== undefined && orderLine.map((line, key) => {
                            return (
                                <Row gutter={15} key={key} className='marginBottom10px'>
                                    <Col span={6}>
                                        <Image preview={false} src={imageUrl + _.find(productState.products, (p) => {return line.itemno === p.product.id})?.product.image}/>
                                    </Col>
                                    <Col span={18}>
                                        <p className='noMarginBottom'><strong>{line.itemname}</strong></p>
                                        <p className='noMarginBottom'>{line.itemdesc}</p>
                                        <p className='noMarginBottom text-grayColor'><strong>{line.orderedqty} pc @ {formatCurrency(line.unitprice)}</strong></p>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </motion.section>)}
            </AnimatePresence>
        </div>
    );
}

export default OrderStatusObject;
