import React from 'react';
import { Row, Col, Tabs, Select, Button, message} from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import { Link } from 'react-router-dom';
import MobileAccountMenu from '../../../components/MobileAccountMenu';
import { logout, myAuth } from '../../../elements/PrivateRoute/PrivateRoute';
import { UserContext } from '../../../contexts/UserContext';
import { MethodCallback, Order, PreferencesMessage, ServiceMainRESTClient } from '../../../RESTAPI';
import _ from 'lodash';
import OrderList from '../../../components/OrderList';

const { TabPane } = Tabs;
const { Option } = Select;

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();

const frequencyData = [
    {
        label: 'None',
        value: 'N'
    },
    {
        label: 'Monthly',
        value: 'M'
    },
    {
        label: 'Fortnightly',
        value: 'F'
    },
    {
        label: 'Weekly',
        value: 'W'
    },
    {
        label: 'Daily',
        value: 'D'
    }
];
const dayMonthData = [
    {
        label: 'First day of the Month',
        value: 'F'
    },
    {
        label: 'Last day of the Month',
        value: 'L'
    }
];

for (let index = 2; index <= 31; index++){
    dayMonthData.push({
        label: index.toString(),
        value: index.toString()
    })
}

const dayWeekData = [
    {
        label: '0',
        value: 'Sunday'
    },
    {
        label: '1',
        value: 'Monday'
    },
    {
        label: '2',
        value: 'Tuesday'
    },
    {
        label: '3',
        value: 'Wednesday'
    },
    {
        label: '4',
        value: 'Thursday'
    },
    {
        label: '5',
        value: 'Friday'
    },
    {
        label: '6',
        value: 'Saturday'
    }
];

const BackOrder: React.FC = () => {
    const { userState, setPreferences } = React.useContext(UserContext);

    const [backOrders, setBackOrders] = React.useState<Array<Order>>([]);

    const [backorderFrequency, setBackorderFrequency] = React.useState<string>('');
    const [backorderDay, setBackorderDay] = React.useState<string>('');

    const [listDay, setListDay] = React.useState<Array<{label: string, value: string}>>([]);

    React.useEffect(() => {
        if(userState.account) {
            setBackOrders(_.filter(userState.account.orders, (o) => {
                return parseInt(o.lowstatus) > 20 && parseInt(o.lowstatus) < 30;
            }));
        }
    }, [userState.account])

    React.useEffect(() => {
        if(userState.preferences) {
            const selectedFrequency = userState.preferences.preferenceNameToPreference['backorder_frequency']?.value;
            const selectedDay = userState.preferences.preferenceNameToPreference['backorder_day']?.value;
            setBackorderFrequency(selectedFrequency);
            setBackorderDay(selectedDay);
        }
    }, [userState.preferences])

    React.useEffect(() => {
        switch (backorderFrequency) {
            case 'N':
            case 'D':
                console.log('haha');
                setListDay([]);
                setBackorderDay('');
                break;
        
            case 'M':
                setListDay(dayMonthData);
                break;
        
            case 'F':
            case 'W':
                setListDay(dayWeekData);
                break;
            
            default:
                break;
        }
    }, [backorderFrequency])

    const handleFrequencyChange = (value: string) => {
        setBackorderFrequency(value);
    }

    const handleDayChange = (value: string) => {
        setBackorderDay(value);
    } 

    const BackorderCallback: MethodCallback<PreferencesMessage> = {
        onFailure(error: string): void {
          alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(returnMessage: PreferencesMessage, context?: any): void {
            if(!returnMessage.authenticated) {
                logout();
            } else {
                message.loading({ content: 'Updating preference...', key: 'updatable' });
    
                if(returnMessage.error) {
                    message.error({ content: returnMessage.error, key: 'updatable', duration: 6 });
                } else {
                    restClient.updatePreference('backorder_day', backorderDay, BackorderDayCallback)
                }
            }
        }
    }

    const BackorderDayCallback: MethodCallback<PreferencesMessage> = {
        onFailure(error: string): void {
          alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(returnMessage: PreferencesMessage, context?: any): void {
            if(!returnMessage.authenticated) {
                logout();
            } else {
                if(returnMessage.error) {
                    message.error({ content: returnMessage.error, key: 'updatable', duration: 6 });
                } else {
                    message.success({ content: 'Updated successfully!', key: 'updatable' });
                    setPreferences(returnMessage);
                }
            }
        }
    }

    const handleSavePreference = () => {
        if(backorderFrequency) {
            restClient.updatePreference('backorder_frequency', backorderFrequency, BackorderCallback)
        } else {
            alert('Please select a valid frequency rate!');
        }
        
    }

    const backorderEmailSet = () => {
        return <Row gutter={[20, 20]} align='middle' className='marginTop20px'>
            <Col xs={24} md={4} lg={4}>
                <h3><strong>Backorder Email Frequency</strong></h3>
            </Col>
            <Col xs={12} md={6} lg={6}>
                <div className="group-floating-label">
                    <Select style={{ width: '100%' }} size='large' onChange={handleFrequencyChange} value={backorderFrequency} placeholder="Frequency">
                        {frequencyData.map(fre => (
                            <Option key={fre.value} value={fre.value}>{fre.label}</Option>
                        ))}
                    </Select>
                </div>
            </Col>

            <Col xs={12} md={6} lg={6}>
                <div className="group-floating-label">
                    <Select style={{ width: '100%' }} disabled={listDay.length <= 0} value={backorderDay} size='large' onChange={handleDayChange} placeholder="Frequency Day">
                        {listDay.map(day => (
                            <Option key={day.value} value={day.value}>{day.label}</Option>
                        ))}
                    </Select>
                </div>
            </Col>

            <Col xs={24} md={6} lg={6}>
                <Button type='primary' block htmlType="button" onClick={handleSavePreference}>Save Setting</Button>
            </Col>
        </Row>
    }
    
    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20} className='marginBottom40px'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <h2 className='headerTitle onlyDesktop'>Your Backorders</h2>
                        <h2 className='headerTitle onlyMobile textCenter'>Your Backorders</h2>
                        <MobileAccountMenu site='Back Orders'/>
                        {/*backorder_frequency ('N' - None, 'M' - Monthly, 'F' - Fortnightly, 'W' - Weekly, 'D' - Daily) */}
                        {/*backorder_day ('F' - first day of month, 'L' - last day of month, '0' - Sunday, '1' - Monday, '2' - 2nd day of month/Tuesday etc..)*/}
                        <div className='orderSection onlyDesktop'>
                            {backorderEmailSet()}
                            <OrderList orders={backOrders} isBackOrder={true}/>
                        </div>

                        <div className='accountSection onlyMobile'>
                            <div className='accountSectionContainer'>
                                {backorderEmailSet()}
                                <OrderList orders={backOrders} isBackOrder={true}/>
                            </div>
                            <div className='signoutMobile onlyMobile'>
                                <Link to='/' onClick={(e: React.MouseEvent) => {e.preventDefault(); myAuth.signout()}}><strong>Sign out</strong></Link>
                            </div>
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
}

export default BackOrder;
