import React from 'react';
import { Row, Col, Button} from 'antd';
import BlankLayout from '../../layouts/BlankLayout';
import { Link, Redirect, useParams } from 'react-router-dom';
import { BackColorIcon } from '../../elements/CustomIcons/CustomIcons';

const OrderSuccess: React.FC = () => {
    const {orderno} = useParams<{orderno: string}>();
    
    if(orderno === undefined || orderno === null || orderno === ':orderno') {
        return (<Redirect
            to={{
                pathname: '/'
            }}
        />)
    }

    return (
        <BlankLayout>
            <div className='content-wrapper marginBottom40px marginTop40px fontSize18px fontBeau' style={{maxWidth: '508px'}}> 
                <Row>
                    <Col span={24}>
                        <h2 className='headerTitle textCenter'>Thank You, Your order {orderno} has been placed.</h2>
                        <div>
                            <p>We have received your order and it is in process.</p>
                            <p>
                                <strong>Order Confirmation Email</strong><br/>
                                We will send a confirmation email within the next five minutes.
                            </p>
                            <p>
                                <strong>What happens next?</strong><br/>
                                We will prepare your order for shipping, you can track the process by using the order reference number.
                            </p>
                            <p>
                                <strong>Still have questions?</strong><br/>
                                See our <Link className='linkUnderline' to='/faqs'>FAQs</Link> page or <Link className='linkUnderline' to='/contact-us'>contact us</Link>.
                            </p>
                        </div>
                        <div className='textCenter marginTop40px'>
                            <Button type='primary' className='minWidth140px' href='/your-account/your-order'>View Orders</Button>
                            <Link to='/'><div className='backHome'>
                                <BackColorIcon/>Home
                            </div>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </BlankLayout>
    );    
}

export default OrderSuccess;
