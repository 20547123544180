import React from 'react';
import { Row, Col, Typography, Divider, Form, Input, Button, message } from 'antd';
import MainLayout from '../../../layouts/MainLayout';
import { AnimatePresence, motion } from 'framer-motion';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { PasswordLength } from '../../../Constants';
import { UserContext } from '../../../contexts/UserContext';
import { MethodCallback, ServiceMainRESTClient, UserProfile, UsersMessage } from '../../../RESTAPI';
import Cookies from 'universal-cookie';
import {LogoutCallback} from '../../../elements/PrivateRoute/PrivateRoute';

const { Paragraph } = Typography;

const restClient: ServiceMainRESTClient = new ServiceMainRESTClient();
const cookies = new Cookies();

const SecurityPage: React.FC = () => {
    const [openPassword, setOpenPassword] = React.useState<boolean>(false)
    const [visible, setVisible] = React.useState<boolean>(false);

    const [name, setUserName] = React.useState<string>('');
    const [email, setUserEmail] = React.useState<string>('');
    const [phone, setUserPhone] = React.useState<string>('');

    const [profile, setProfile] = React.useState<UserProfile>();

    const handleOpenPasswordForm = () => {
        setOpenPassword(!openPassword);
    }

    const { userState, setName, setEmail, setPhone } = React.useContext(UserContext);

    React.useEffect(() => {
        if(userState.userProfile) {
            const profile = userState.userProfile;
            setUserName(profile.firstname + ' ' + profile.lastname);
            setUserEmail(profile.email);
            setUserPhone(profile.contactphone);

            setProfile(profile);
        }
    }, [userState.userProfile])

    React.useEffect(() => {
        if (profile) {
            //restClient.updateUserProfile(profile.id, profile.firstname, profile.lastname, profile.email, profile.contactphone, values.password, UserCallback);
            console.log('haha');
        }
    }, [name, email, phone])

    const UserCallback: MethodCallback<UsersMessage> = {
        onFailure(error: string): void {
            alert(error);
        },
        onProgress(loaded: number, total: number): void {},
        onSuccess(returnMessage: UsersMessage): void {
            if(!returnMessage.authenticated) {
                restClient.logout(LogoutCallback);
                cookies.remove('token', {path: '/'});
                window.location.reload();
            } else {
                message.loading({ content: 'Saving profile...', key: 'updatable' });

                if(returnMessage.error) {
                    setTimeout(() => {
                        message.error({ content: returnMessage.error, key: 'updatable', duration: 4 });
                    }, 200);
                } else {
                    setTimeout(() => {
                        message.success({ content: 'Saved profile!', key: 'updatable', duration: 2 });
                        setOpenPassword(false);
                    }, 200);
                }
            }
        }
    }

    const handleUpdateName = (value: string) => {
        setUserName(value);
        const nameArray = value.split(' ');
        const firstName = nameArray[0] ? nameArray[0] : '';
        const lastName = nameArray[1] ? nameArray[0] : '';

        // error: "User U000011856 not manageable" ???
        if(profile) {
            restClient.updateUserProfile(profile.id, firstName, lastName, profile.email, profile.contactphone, '', UserCallback); // need a way to not setting password here
        }  
    }

    const handleUpdateEmail = (value: string) => {
        setUserEmail(value);

        if(profile) {
            restClient.updateUserProfile(profile.id, profile.firstname, profile.lastname, value, profile.contactphone, '', UserCallback);
        }  
    }

    const handleUpdatePhone = (value: string) => {
        setUserPhone(value);

        if(profile) {
            restClient.updateUserProfile(profile.id, profile.firstname, profile.lastname, profile.email, value, '', UserCallback);
        } 
    }

    const handleSavePassword = (values: any) => {
        if(profile) {
            restClient.updateUserProfile(profile.id, profile.firstname, profile.lastname, profile.email, profile.contactphone, values.password, UserCallback);
        }
    }


    return (
        <MainLayout>
            <div className='content-wrapper marginBottom40px marginTop40px'> 
                <Row gutter={20}>
                    <Col span={24}>
                        <h2 className='headerTitle textCenter'>Login & Security</h2>

                        <div className='accountSection' style={{maxWidth: '775px', margin: '0 auto'}}>
                            <p className='noMarginBottom text-grayColor'>Name</p>
                            <Paragraph editable={{onChange: handleUpdateName}} strong>{name}</Paragraph>
                            <Divider/>

                            <p className='noMarginBottom text-grayColor'>Email</p>
                            <Paragraph editable={{onChange: handleUpdateEmail}} strong>{email}</Paragraph>
                            <Divider/>

                            <p className='noMarginBottom text-grayColor'>Mobile Number</p>
                            <Paragraph editable={{onChange: handleUpdatePhone}} strong>{phone}</Paragraph>
                            <Divider/>

                            <p className='noMarginBottom text-grayColor'>Password</p>
                            <AnimatePresence exitBeforeEnter>
                                {
                                    openPassword ? 
                                    <motion.div key='open' initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0, height: 0 }
                                    }}
                                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}>
                                        <Form className='marginTop20px mobileMarginBottom20px' onFinish={(values) => {handleSavePassword(values)}}>
                                            <Form.Item className="group-floating-label" hasFeedback name='password' label='' rules={[({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                if (value.length >= PasswordLength) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Please input a valid password!');
                                                },
                                            })]}>
                                                <Input type={visible ? "text" : "password"} size="large" className="has-float-label" placeholder='Password' prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisible(!visible)} > {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">Password</label>}/>
                                            </Form.Item>

                                            <Form.Item className="group-floating-label" dependencies={['password']} hasFeedback name='confirm_password' label='' rules={[{  required: true, message: 'Please confirm your password!' }, ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('The two passwords that you entered do not match!');
                                                },
                                            })]}>
                                                <Input type={visible ? "text" : "password"} size="large" className="has-float-label" placeholder='Confirm Password' prefix={<div style={{cursor: 'pointer'}} onClick={() => setVisible(!visible)} > {visible ? <EyeTwoTone /> : <EyeInvisibleOutlined/>}</div>} suffix={<label className="floating-label" htmlFor="name">Confirm Password</label>}/>
                                            </Form.Item>
                                            <Button type='primary' htmlType='submit'>Save Password</Button>
                                        </Form>
                                    </motion.div>
                                    : <motion.div key='close' initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: { opacity: 1, height: "auto" },
                                        collapsed: { opacity: 0}
                                    }}
                                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}>
                                        <Paragraph className='noMarginBottom' editable={{
                                            onStart: handleOpenPasswordForm
                                        }} strong>••••••••••</Paragraph>
                                    </motion.div>
                                }
                                
                            </AnimatePresence>
                        </div>
                    </Col>
                </Row>
            </div>
        </MainLayout>
    );
}

export default SecurityPage;
